import React from "react"
import './EmbedSection.css'
import CustomInput from "../../../../../../CommonComponents/CustomForm/CustomInputField"

const EmbedSection = ({ handleEmbedUrlUpload, siteUrl, preventScroll, errorState }) => {
    return (
        <>
            <div className="video-input-main">
                <div>
                    <CustomInput
                        placeholder={"Embed URL"}
                        value={siteUrl}
                        name={'siteUrl'}
                        onChange={(e) => handleEmbedUrlUpload(e, 'embed')}
                        required={true}
                        type={'text'}
                        error={errorState?.embedErr}
                        autoFocus={true}
                    />
                </div>
                <div className="video-top-para mt-2">
                    <p>
                        <em>
                            Copy and paste the full URL here (including https://)
                        </em>
                    </p>
                </div>
                <div>
                    <div className="toggle-container">
                        <label>Prevent scrolling</label>
                        <input
                            type="checkbox"
                            id="toggle3"
                            className="toggle-input"
                            name="no_scroll"
                            checked={preventScroll}
                            value={preventScroll}
                            onChange={(e) => handleEmbedUrlUpload(e, 'embed')}
                        />
                        <label htmlFor="toggle3" className="toggle-btn"></label>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmbedSection