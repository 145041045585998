import React, { useEffect, useState } from "react";
import { Resizable } from "re-resizable";
import resize_right from "../.././../../../../assets/images/arrows_in-outward.svg";

function ResizableComponent({
  element,
  setResizeDimensions,
  dimensions,
  children,
  resizableDimensions,
  resizeDimensions,
  keepProportions,
  setShowExtendModal,
  isSaved,
  setIsSaved
}) {
  const [internalDimensions, setInternalDimensions] = useState({
    width: 0,
    height: 0,
  });

  const updateDimensions = (width, height) => {
    setResizeDimensions({
      ...resizeDimensions,
      [element.id]: {
        ...resizeDimensions[element.id],
        width,
        height,
      },
    });
  };

  useEffect(() => {
    setInternalDimensions({
      width: dimensions.width,
      height: dimensions.height,
    });
    updateDimensions(dimensions.width, dimensions.height);
  }, [dimensions.width, dimensions.height, element.id]);

  useEffect(() => {
    if (isSaved) {
      setInternalDimensions({
        width: dimensions.width,
        height: dimensions.height,
      });
      setIsSaved(false);
    }
  }, [isSaved]);

  return (
    <Resizable
      size={{
        width: resizableDimensions?.width,
        height: resizableDimensions?.height,
      }}
      onResizeStart={(e) => {
        e.stopPropagation();
      }}
      onResizeStop={(e, direction, ref, d) => {
        e.stopPropagation();
        setInternalDimensions({
          width: internalDimensions.width + d.width,
          height: internalDimensions.height + d.height,
        })
      }}
      lockAspectRatio={keepProportions}
      onResize={(e, direction, ref, d) => {
        e.stopPropagation();
        setShowExtendModal(true);
        updateDimensions(
          internalDimensions.width + d.width,
          internalDimensions.height + d.height
        );
      }}
      enable={{
        right: true,
        bottom: true,
        ...(keepProportions && { bottomRight: true }),
      }}
      style={{
        border: "1px solid #FF0000",
      }}
      handleStyles={{
        bottomRight: {
          // width: "15px", // Change the size of the div wrapping the img
          // height: "15px",
          right: "-15px",
          bottom: "-15px",
        },
      }}
      handleComponent={{
        bottomRight: (
          <img
            src={resize_right}
            alt="resize icon"
            draggable={false}
            onDrag={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onClick={(e) => e.stopPropagation()}
            style={{
              width: 25,
              height: 25,
              zIndex: 9999,
              position: "absolute",
              top: "0",
              left: "0",
            }}
          />
        ),
      }}
    >
      <div
        style={{
          width: `${resizableDimensions?.width - 2}px`,
          height: `${resizableDimensions?.height - 2}px`,
          overflow: "hidden",
          display: "flex",
          justifyContent:"center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </Resizable>
  );
}

export default ResizableComponent;
