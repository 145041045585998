import React, { useState, useEffect } from "react";
import ElementModal from "../../Pages/Kdf/EditKdf/elementModal/ElementModal";
import Button from "../../Elements/Button";
import FormFieldsSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/elementFormSection/FormFieldsSection";
import DisplayLanguagesSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/elementFormSection/DisplayLanguagesSection";
import useEditorHook from "../../Pages/Kdf/EditKdf/editorHook/useEditorHook";
import CustomForm from "../../CommonComponents/CustomForm/CustomForm";

const AddElementModal = ({ openAddElementModal, closeModal, docData, docFiles, totalPages, zoom, applyDesiredAction }) => {
    const {
        tool,
        toolType,
        editElement,
        ToolSection,
        handleCloseModal,
        handleSubmitElement,
        handleChange,
        setTool,
        initialTool,
        initialEditElement,
        isChanged, setIsChanged,
        errorState
    } = useEditorHook(closeModal, docData, totalPages, zoom, applyDesiredAction);

    const languages = docFiles?.filter((elem) => elem?.id !== docData?.id);

    return (
        <ElementModal
            openModal={openAddElementModal}
            heading={ToolSection()?.header}
            handleCloseModal={handleCloseModal}
        >
            <div className="image-popup">
                <CustomForm className="popup-content">
                    {toolType !== 'Clone' && ToolSection()?.component}
                    {toolType !== 'LightboxesToolbox' && (
                        <>
                            <FormFieldsSection
                                handleChange={handleChange}
                                tool={tool}
                                toolType={toolType}
                                docData={docData}
                                editElement={editElement}
                                setTool={setTool}
                                totalPages={totalPages}
                                errorState={errorState}
                                zoom={zoom}
                            />
                            {languages?.length > 0 && (
                                <DisplayLanguagesSection
                                    languages={languages}
                                    toolType={toolType}
                                />
                            )}
                            {/* {toolType === 'Clone' && (
                                <em className="px-2">
                                    Note: Before duplicating the element please change at least position (width, height, top, left) and/or page to be shown, otherwise the element will be placed above the original one.
                                </em>
                            )} */}
                        </>
                    )}
                    <div className="text-end m-2">
                        <Button
                            classList={'success'}
                            onClick={(e) => handleSubmitElement(e, tool?.element_type_name == 'Image' ? editElement?.id : null, toolType == 'LightboxesToolbox' ? 'image' : '')}
                            text={toolType == 'Clone' ? 'Clone' : 'Save'}
                            type='submit'
                            disabled={!isChanged}
                        />
                    </div>
                    {(toolType !== 'Clone' && (toolType === 'ImageToolbox' || editElement?.element_type === 4)) && (
                        <div className="image-save-button">
                            <Button
                                classList={'success'}
                                onClick={(e) => handleSubmitElement(e, tool?.element_type_name == 'Image' ? editElement?.id : null, toolType == 'LightboxesToolbox' ? 'image' : '')}
                                text={toolType == 'Clone' ? 'Clone' : 'Save'}
                                type='submit'
                                disabled={!isChanged}
                            />
                        </div>
                    )}
                </CustomForm>
            </div>
        </ElementModal>
    );
};

export default AddElementModal;
