import React, { useContext, useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  calculatePageHeightScale,
  calculatePageWidthScale,
} from "../../../../../hooks/utils";
import { dispatchEvent, scrollPage } from "../../../../../utis/helper";
import usePdfSearch from "../../../../../utis/usePdfSearch";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import Footer from "../../../../Layout/Footer/Footer";
import ElementOrder from "../elementOrder/ElementOrder";
import TagEditorSection from "../TagEditorSection";
import PdfCanvas from "./PdfCanvas";
import PdfElementsList from "./PdfElementsList";

const PdfViewer = ({
  pdfFile,
  zoom,
  docFileId,
  docId,
  editortype,
  pageNum,
  docFiles,
  timerOnDoc,
  onLoadSuccess,
  showToolbar,
  setZoomIndex,
  statHook,
}) => {
  const { editEnabled, setRectangles, setPageDimensions, editElement } =
    useContext(EditorInfoContext);
  const { sendScrollInfo, elemClick, setVideoData, actionCableVideoMontior } = statHook || {};
  const { searchInPdf } = usePdfSearch();
  const hashParams = new URLSearchParams(window.location.hash.replace("#", ""));
  const page = hashParams?.get("page");
  const containerRef = useRef(null);
  const canvasRefs = useRef([]);
  const prevZoomRef = useRef(zoom.value);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [renderedPages, setRenderedPages] = useState(0);
  const [hoveredId, setHoveredId] = useState(null);
  const [jumpPageNum, setJumpPageNum] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setRenderedPages(0);
    setRectangles(new Array(numPages).fill([]));
  };

  const onPageRenderSuccess = () => {
    setRenderedPages((prev) => prev + 1);
    setTimeout(() => {
      let input = document.getElementById("pageNumber");
      if (!input) {
        return;
      }
      if (prevZoomRef.current === zoom.value || isFirstRender) {
        if (page !== null) {
          input.value = page;
        }
        scrollPage(input.value, "instant");
      }
    }, 100);
    setIsFirstRender(false);
  };

  const onScroll = () => {
    const wrapper = containerRef.current;
    const pageHeight = wrapper.scrollHeight / numPages;
    const scrollPosition = wrapper.scrollTop + wrapper.clientHeight / 2;
    const newPage = Math.ceil(scrollPosition / pageHeight);
    const input = document.getElementById("pageNumber");
    if (newPage > 0 && newPage <= numPages) {
      if (newPage != input.value && editortype == 'laserlink') {
        sendScrollInfo(newPage);
      }
      dispatchEvent("change", "pageNumber");
      input.value = newPage;
    }
  };

  const updateDimensions = (page, index) => {
    requestAnimationFrame(() => {
      const page = document.querySelector(
        `.react-pdf__Page.page[data-page-number="${index + 1}"]`
      );

      if (page) {
        const rect = page.getBoundingClientRect();
        setPageDimensions({
          width: rect.width,
          height: rect.height,
        });
      }
    });
  };

  const onPageLoadSuccess = (page, index) => {
    updateDimensions(page, index);
    let canvas = canvasRefs.current[index];
    if (canvas && page) {
      const viewport = page.getViewport({ scale: 1 });
      const newScale =
        zoom?.index == "page-height"
          ? calculatePageHeightScale(page)
          : calculatePageWidthScale(page);
      if (zoom?.index == "page-height") {
        if (!window.objInit.zoom_lvl) window.objInit.zoom_lvl = newScale;
        setZoomIndex({ index: "page-height", value: newScale });
      } else if (zoom?.index === "page-width") {
        if (!window.objInit.zoom_lvl) window.objInit.zoom_lvl = newScale;
        setZoomIndex({ index: "page-width", value: newScale });
      }
      canvas.width = viewport.width * newScale;
      canvas.height = viewport.height * newScale;
    }
  };

  useEffect(() => {
    if (renderedPages === numPages && onLoadSuccess) {
      onLoadSuccess();
    }
  }, [renderedPages, numPages]);

  useEffect(() => {
    const wrapper = containerRef.current;
    if (wrapper && numPages > 1) {
      wrapper.addEventListener("scroll", onScroll);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("scroll", onScroll);
      }
    };
  }, [numPages]);

  useEffect(() => {
    searchInPdf();
  }, []);

  useEffect(() => {
    jumpPageNum !== null && setJumpPageNum(null);
  }, [jumpPageNum]);

  const renderPage = (index) => (
    <>
      {editortype === "editor" && (
        <TagEditorSection
          docFileId={docFileId}
          numPages={numPages}
          docId={docId}
          docFiles={docFiles}
          currentPage={index + 1}
        />
      )}
      <div style={{ cursor: editEnabled ? "crosshair" : "default" }}>
        <Page
          id={`pageContainer${index + 1}`}
          className="page"
          pageNumber={index + 1}
          scale={parseFloat(zoom?.value)}
          onRenderSuccess={onPageRenderSuccess}
          onLoadSuccess={(page) => onPageLoadSuccess(page, index)}
          inputRef={(ref) => {
            if (
              ref &&
              (pageNum === index + 1 || jumpPageNum === index + 1 || index + 1 === page) &&
              !editEnabled &&
              !editElement?.id
            ) {
              if (prevZoomRef.current !== zoom.value) {
                prevZoomRef.current = zoom.value;
              } else {
                setTimeout(() => {
                  ref.scrollIntoView({ behavior: "instant" });
                }, 50);
              }
            }
          }}
        />
        <PdfCanvas
          zoom={zoom}
          canvasRefs={canvasRefs}
          index={index}
          editortype={editortype}
        />
      </div>
      <PdfElementsList
        timerOnDoc={timerOnDoc}
        zoom={zoom}
        elemClick={elemClick}
        setVideoData={setVideoData}
        actionCableVideoMontior={actionCableVideoMontior}
        docFileId={docFileId}
        index={index}
        canvasRefs={canvasRefs}
        editortype={editortype}
        hoveredId={hoveredId}
        setJumpPageNum={setJumpPageNum}
      />
    </>
  );

  return (
    <div
      className="parent_doc_wrapper"
      id="parent_doc_wrapper"
      style={{
        ...(editortype !== "stats-viewer" && {
          height: `${showToolbar == false ? "100vh" : "calc(100vh - 50px)"}`,
          overflowY: "scroll",
          scrollbarWidth: "none",
        }),
      }}
      ref={containerRef}
    >
      <Document
        key={zoom}
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
        className="react-pdf__Document"
      >
        {editortype == "stats-viewer" ? (
          <div className="pdf-page" style={{ position: "relative" }}>{renderPage(pageNum - 1)}</div>
        ) : (
          <>
            {Array.from(new Array(numPages), (el, index) => (
              <div className="pdf-page" key={index} style={{ position: "relative" }}>
                {renderPage(index)}
              </div>
            ))}
          </>
        )}
      </Document>
      {editortype !== "stats-viewer" && <Footer isEditor={true} />}
      {editortype == "editor" && (
        <ElementOrder docFileId={docFileId} setHoveredId={setHoveredId} />
      )}
    </div>
  );
};

export default PdfViewer;
