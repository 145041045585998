import React, { useEffect, useState } from 'react';
import apiGETRequest from '../../../utis/apiRequest';
import { formatTimestamp } from '../../../hooks/utils';

const Footer = ({ isEditor = false }) => {
  const tlb = window?.objInit?.tlb;
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const updatingUserProfile = async () => {
      let userInfo = await apiGETRequest(`edit.json`);
      if (userInfo) {
        setUserData(userInfo);
      }
    };

    updatingUserProfile()
  }, [])

  return (
    <footer className={`footer ${!isEditor ? 'position-absolute' : ''}`} >
      {
        tlb == undefined ?
          <div className="panel-footer text-center ng-scope">
            On <span id="ppAcceptanceDate">{userData?.tos_accepted_date && formatTimestamp(userData?.tos_accepted_date)}</span> you accepted our <a className='f-clr-ch' href="#">Terms of Service</a>.
            <div>
              Copyright © 2024 Box Ventures LLC. All rights reserved.
              <a className="privacy f-clr-ch" target="_blank" href="">Privacy</a>
            </div>
          </div> :
          <div className='panel-footer text-center ng-scope'>
            The sender, {tlb?.company_name} bears the sole responsibility for the published content herein and potential processing of your Personal Data that may derive from it.
            By using this site, you acknowledge that you read and agree with the sender's
            <a href="#" className='f-clr-ch' target="_blank">Privacy policy </a>
            and <a href="#" className='f-clr-ch' target="_blank">Cookie policy </a>.<br />
            This service is powered by Kinetizine, a content delivery service by BOX Ventures LLC. <a className='f-clr-ch' href="#">Manage Cookies</a> Box. All rights reserved.
          </div>
      }

    </footer>
  );
};

export default Footer;
