import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { networkService } from "../../../config/axios";
import KdfSessHeader from "../SessionComponents/KdfSessHeader";
import SessionActiveTab from "../SessionComponents/SessionActiveTitle";
import SessionData from "../SessionComponents/SessionData";
import SessionHeading from "../SessionComponents/SessionHeading";
import SessionTable from "../SessionComponents/SessionTabls";
import StatPdfViewer from "../SessionComponents/StatPdfViewer";
import styles from "./Viewer.module.css";
import LoadingScreen from "../../../Elements/LoadingScreen/LoadingScreen";

const Viewer = () => {
  const [viewerData, setViewerData] = useState([]);
  const [showWithTags, setShowWithTags] = useState(false);
  const { hash } = useLocation();
  const {
    tl,
    doc,
    pdfFile,
    pdfFile: { thumbs_details },
  } = window.objInit;
  const token = tl?.token || doc?.group_id;
  const [selectedPage, setSelectedPage] = useState({ id: 1, index: null });
  const [sessionIndex, setSessionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false)

  const updateReceiverInfo = async () => {
    setIsLoading(true)
    try {
      let id;
      if (hash.replace("#/viewer/", "")?.split("&").length > 0) {
        id = hash.replace("#/viewer/", "")?.split("&")[0];
        let { data, status } = await networkService(
          `/statistics/receivers?receiver=${hash.replace("#/viewer/", "")?.split("&")[0] || ""
          }&token=${token}&type=${tl ? "tl" : "doc"}`,
          "GET"
        );
        if (data?.length > 0) {
          setViewerData(data);
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.error("error ", error);
    }
  };

  useEffect(() => {
    updateReceiverInfo();
  }, []);

  return (
    <>
      {isLoading ? <LoadingScreen /> :
        <>
          {viewerData?.map((viewDt, viewInd) => (
            <Container key={`${viewInd}-${viewDt?.optin_session?.id}`}>
              <Col md={8}>
                <Row className="my-2">
                  <Col md={6}>
                    <KdfSessHeader viewDt={viewDt} sessionIndex={sessionIndex} />
                  </Col>
                  <Col md={6}>
                    <SessionData viewDt={viewDt} thumbs_details={thumbs_details} />
                  </Col>
                </Row>
              </Col>
              <Row>
                <Col md={8}>
                  <SessionActiveTab
                    data={viewDt}
                    sessionIndex={sessionIndex}
                    setSessionIndex={setSessionIndex}
                  />
                  {viewDt?.sessions?.map(
                    (session, idx) =>
                      idx == sessionIndex && (
                        <div key={idx}>
                          <div className={styles.statsViewerContainer}>
                            <SessionHeading
                              session={session}
                              thumbs_details={thumbs_details}
                              data={viewDt}
                            />
                            <div className="d-flex my-4">
                              <span
                                onClick={() => setShowWithTags((prev) => true)}
                                className="text-link-success text-white tag-primary mx-1"
                              >
                                Show pages without tags
                              </span>
                              <span
                                onClick={() => setShowWithTags((prev) => false)}
                                className="text-link-success text-white tag-primary mx-1"
                              >
                                Show all
                              </span>
                            </div>
                            <SessionTable
                              data={viewDt}
                              sessions={viewDt?.sessions}
                              sessionIndex={idx}
                              selectedFilter={showWithTags ? 'not_tagged' : 'all'}
                              setSelectedPage={setSelectedPage}
                              session={session}
                              selectedPage={selectedPage}
                              thumbs_details={thumbs_details}
                              receiver={{
                                name: viewDt?.name,
                                email: viewDt?.email
                              }}
                            />
                          </div>
                        </div>
                      )
                  )}
                </Col>
                <Col md={4}>
                  <StatPdfViewer
                    pdfFile={pdfFile}
                    doc={doc}
                    tl={tl}
                    selectedPage={selectedPage}
                    viewerData={viewerData}
                  />
                </Col>
              </Row>
            </Container>
          ))}
        </>
      }
    </>
  );
};
export default Viewer;
