import React, { useContext, useState } from 'react';
import { Accordion, Container, Nav, Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import kz_logo from '../../../../assets/images/kz_logo.png';
import UserInfoContext from '../../../utis/userInfoContext';
import SupportModal from '../../Modals/SupportModal';

const SideBar = () => {
  const [openSupport, setOpenSupport] = useState(false);
  const { pathname, hash } = useLocation();
  const { userInfoData } = useContext(UserInfoContext);
  const isKdf = pathname?.includes('kdf-stats');
  const isLink = pathname?.includes('tl-stats');

  return (
    <>
      {openSupport && <SupportModal openSupport={openSupport} setOpenSupport={setOpenSupport} />}
      <Navbar expand="lg" className="bg-body-tertiary p-0 d-block">
        <Container fluid className='bg-white p-0'>
          <Navbar.Brand className='m-auto cursor-pointer'>
            <a href="/kdfs">
              <img src={kz_logo} alt="kz_logo" className='img-fluid' />
            </a>
          </Navbar.Brand>
        </Container>
        <Container className='p-0 m-0'>
          <Nav defaultActiveKey="/home" className="flex-column w-100 sidebar-nav">
            {(userInfoData?.user_type == 1 || userInfoData?.user_type == 2) && (
              <Accordion defaultActiveKey={pathname.includes('admin') ? '0' : ''}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className={`menu-sidebar ${pathname.includes('admin') ? 'active' : ''}`}>
                    Admin
                  </Accordion.Header>
                  <Accordion.Body className='p-0'>
                    <Nav.Link className={pathname.includes('admin/orgs') ? 'active' : ''} href="/admin/orgs">Organizations</Nav.Link>
                    <Nav.Link className={pathname.includes('admin/users') ? 'active' : ''} href="/admin/users">Users</Nav.Link>
                    <Nav.Link className={pathname.includes('admin/subscriptions') ? 'active' : ''} href="/admin/subscriptions">Subscriptions</Nav.Link>
                    <Nav.Link className={pathname.includes('admin/potential_bots') ? 'active' : ''} href="/admin/potential_bots">Potential Bots</Nav.Link>
                    <Nav.Link className={pathname.includes('admin/customization_log') ? 'active' : ''} href="/admin/customization_log">Customization Logs</Nav.Link>
                    <Nav.Link className={pathname.includes('admin/get_domain_users') ? 'active' : ''} href="/admin/get_domain_users">Domain Manager</Nav.Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

            <Nav.Link className={pathname.includes('kdfs') ? 'active-nav' : ''} href="/kdfs">KDFs</Nav.Link>
            <Nav.Link className={pathname.includes('recipients') ? 'active-nav' : ''} href="/recipients">Recipients</Nav.Link>
            <Nav.Link className={pathname.includes('links') ? 'active-nav' : ''} href="/links">LaserLINX</Nav.Link>

            {(isKdf || isLink) && (
              <Accordion defaultActiveKey={pathname.includes('tl-stats') || pathname.includes('kdf-stats') ? '1' : ''}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className={`menu-sidebar ${pathname.includes('tl-stats') || pathname.includes('kdf-stats') ? 'active' : ''}`}>
                    {isKdf ? 'KDF Statistics' : 'INSIGHT'}
                  </Accordion.Header>
                  <Accordion.Body className='p-0'>
                    <Nav.Link className={hash.includes('/at-a-glance') ? 'active' : ''} href={`#/at-a-glance`}>At a glance</Nav.Link>
                    <Nav.Link className={hash.includes('/page-by-page') ? 'active' : ''} href={`#/page-by-page`}>Page by page</Nav.Link>
                    <Nav.Link className={hash.includes('/views') ? 'active' : ''} href={`#/views`}>Visits</Nav.Link>
                    <Nav.Link className={hash.includes('/viewers') ? 'active' : ''} href={`#/viewers`}>Unique Recipients</Nav.Link>
                    {(hash.includes('#/session/') || hash.includes('#/viewer/')) && (
                      <Nav.Link className={`cursor-auto ${hash.includes('#/session/') || hash.includes('#/viewer/') ? 'active' : ''}`}>
                        Viewer Journey
                      </Nav.Link>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

            <Nav.Link onClick={() => setOpenSupport(true)}>Support</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default SideBar;
