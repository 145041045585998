import React, { useContext, useEffect, useRef, useState } from 'react';
import ImageToolbox from './toolBox/ImageToolbox';
import VideoToolbox from './toolBox/VideoToolbox';
import TextToolbox from './toolBox/TextToolbox';
import EmbedToolbox from './toolBox/EmbedToolbox';
import HotspotToolbox from './toolBox/HotspotToolbox';
import LightboxesToolbox from './toolBox/LightboxesToolbox';
import './EditorToolbox.css';
import { EditorInfoContext } from '../../../../../utis/userInfoContext';
import { networkService } from '../../../../config/axios';
import { csrfToken } from '../../../../../utis/csrfTokenUtils';
import SupportModal from '../../../../Modals/SupportModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { faFlag as faFlagRegular } from '@fortawesome/free-regular-svg-icons';
import ToolBoxFooter from './toolBox/ToolBoxFooter';
import CustomTooltip from '../../../../Elements/Tooltip';

const EditorToolbox = ({ doc, pdfFile, otherLanguageAvailable, setOpenCloneModal, selectedPdf, isPdfPublished, setIsPdfPublished }) => {
    const { setEditEnabled, setToolType, toolType, setOpenLightBox, setShowToast } = useContext(EditorInfoContext)
    const [openSupport, setOpenSupport] = useState(false);
    const pageNumber = useRef(1);

    const handleToolboxSelectEvent = (status) => {
        if (status !== 'LightboxesToolbox') {
            setEditEnabled(true)
        } else {
            setOpenLightBox(true)
        }
        setToolType(status)
    }

    const handlePublishKdf = async () => {
        const payload = {
            authenticity_token: csrfToken(),
            doc_id: doc?.id,
            docs_file_id: selectedPdf?.id ?? pdfFile?.id
        }
        const publishRes = await networkService(`editor/push-to-live.json`, "POST", payload)
        if (publishRes?.data?.message === 'Success') {
            setShowToast({
                showToast: true,
                toastType: 'success',
                toastMessage: "KDF Published"
            })
            setIsPdfPublished(true)
        } else {
            setShowToast({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        setIsPdfPublished(window.objInit && window.objInit.live_files_count &&
            window.objInit.live_files_count[selectedPdf?.language?.locale ?? pdfFile?.language] > 0)
    }, [selectedPdf])

    const onScroll = () => {
        const input = document.getElementById("pageNumber");
        pageNumber.current = input?.value || 1;
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                setToolType('');
                setEditEnabled(false)
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        document.getElementById("pageNumber").addEventListener("change", onScroll);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.getElementById("pageNumber").removeEventListener("change", onScroll);
        };
    }, []);

    return (
        <>
            {openSupport && <SupportModal openSupport={openSupport} setOpenSupport={setOpenSupport} />}
            <div className="editorToolbox__popup">
                <div className="editorToolbox__content">
                    {
                        toolType !== 'LightboxesToolbox' && toolType.length > 0 &&
                        <div className='shadow-sm p-3 mb-3 bg-white rounded fs-6'>
                            Drag a rectangle on the page to define the area within which you'd like to display your element.
                        </div>
                    }
                    <div className="editorToolbox__gridContainer">
                        <div className={`editorToolbox__gridItem ${toolType == 'ImageToolbox' ? 'editor-active' : ""}`} onClick={() => handleToolboxSelectEvent('ImageToolbox')}>
                            <ImageToolbox />
                        </div>
                        <div className={`editorToolbox__gridItem ${toolType == 'VideoToolbox' ? 'editor-active' : ""}`} onClick={() => handleToolboxSelectEvent('VideoToolbox')}>
                            <VideoToolbox />
                        </div>
                        <div className={`editorToolbox__gridItem ${toolType == 'TextToolbox' ? 'editor-active' : ""}`} onClick={() => handleToolboxSelectEvent('TextToolbox')}>
                            <TextToolbox />
                        </div>
                        <div className={`editorToolbox__gridItem ${toolType == 'EmbedToolbox' ? 'editor-active' : ""}`} onClick={() => handleToolboxSelectEvent('EmbedToolbox')}>
                            <EmbedToolbox />
                        </div>
                        <div className={`editorToolbox__gridItem ${toolType == 'HotspotToolbox' ? 'editor-active' : ""}`} onClick={() => handleToolboxSelectEvent('HotspotToolbox')}>
                            <HotspotToolbox />
                        </div>
                        <div className={`editorToolbox__gridItem ${toolType == 'LightboxesToolbox' ? 'editor-active' : ""}`} onClick={() => handleToolboxSelectEvent('LightboxesToolbox')}>
                            <LightboxesToolbox />
                        </div>
                    </div>
                    {otherLanguageAvailable ?
                        <div className='editor-flag-pop' onClick={() => {
                            setOpenCloneModal(true)
                        }}>
                            <div className='d-flex flags-arrow'>
                                <FontAwesomeIcon icon={faFlagSolid} />
                                <FontAwesomeIcon icon={faArrowRightLong} />
                                <FontAwesomeIcon icon={faFlagRegular} />
                            </div>
                            <div>
                                <p>Copy elements to other languages</p>
                            </div>

                        </div> : <></>
                    }
                    <div className="tolbox-btns">
                        <div className="btn-grp1 d-flex">
                            <CustomTooltip title={<p className='p-1 text-light'>Preview your last changes</p>}>
                                <button
                                    className="editorToolbox__button editorToolbox__button--secondary me-3"
                                    onClick={() => window.location = `/viewer/${doc?.group_id}/${pdfFile?.language}/dev#page=${pageNumber.current}`}
                                >
                                    Preview Changes
                                </button>
                            </CustomTooltip>
                            <CustomTooltip title={<p className='p-1 text-light'>{!isPdfPublished ? 'Publish this language edition then create & share links to it.' : 'Republish this KDF to update the online version now.'}</p>}>
                                <button
                                    className="editorToolbox__button editorToolbox__button--primary"
                                    onClick={() => handlePublishKdf()}
                                >{isPdfPublished ?
                                    'Republish' : 'Publish'}
                                </button>
                            </CustomTooltip>
                        </div>
                        <CustomTooltip title={<p className='p-1 text-light'>{!isPdfPublished ? 'Please publish your KDF first' : 'Preview live version of your KDF'}</p>}>
                            <button
                                disabled={!isPdfPublished}
                                className="editorToolbox__button editorToolbox__button--secondary1"
                                onClick={() => window.location = `/viewer/${doc?.group_id}/${pdfFile?.language}#page=${pageNumber.current}`}
                            >
                                Preview Published Version
                            </button>
                        </CustomTooltip>
                    </div>
                    <div className="d-flex lower-toolbox-btn">
                        <button
                            className="editorToolbox__button editorToolbox__button--tertiary me-2"
                            onClick={() => window.location.href = `/kdfs/${doc?.id}`}
                        >
                            KDF Settings
                        </button>
                        <button
                            className="editorToolbox__button editorToolbox__button--tertiary"
                            onClick={() => setOpenSupport(true)}
                        >
                            Support
                        </button>
                    </div>
                    <ToolBoxFooter doc={doc} />
                </div>
            </div>
        </>
    )
}
export default EditorToolbox;
