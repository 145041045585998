import React, { useState } from "react";
import { Card, Row, Col, Tabs, Tab } from "react-bootstrap";
import Button from "../../Elements/Button";
import ColumnChart from "./Charts/ColumnChart";
import ShowCharts from "./Charts/ShowCharts";
import SelectChart from "./Charts/SelectChart";

const AtAGlance = () => {
    const [selectedChart, setSelectedChart] = useState({
        browser: 'Pie',
        os: 'Pie',
        device: 'Pie',
        city: 'Pie',
        region: 'Pie',
    });

    const { tl, doc } = window?.objInit;
    const { stats } = tl || doc;

    const mostViewed = stats?.most_visited_pages?.reduce((max, obj) => {
        return obj.views > max.views ? obj : max;
    }, stats?.most_visited_pages[0]);

    const handleSelectChange = ({ target: { value } }, type) => {
        setSelectedChart((prevState) => ({
            ...prevState,
            [type]: value,
        }));
    };

    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex">
                        <Button
                            className="initial-btn-access"
                            text={"Overview"}
                            classList={`success me-2`}
                        />
                    </div>
                    <Card className="p-3">
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h5>Performance</h5>
                                    <p><strong>{stats?.viewers}</strong> <br /> Visits</p>
                                    <p><strong>{stats?.total_min_viewed}</strong> <br /> Total Time</p>
                                    <p><strong>{stats?.percent_viewed}</strong> <br /> Viewed</p>
                                    <p><strong>{mostViewed?.page}</strong> <br /> Most Visited Page</p>
                                </Col>
                                <Col>
                                    <h5>Behavioral metrics</h5>
                                    <p><strong>{stats?.returning_viewers}</strong> <br /> Returning Viewers</p>
                                    <p><strong>{stats?.optins_num}</strong> <br /> Opt-ins</p>
                                    <p><strong>0/0</strong> <br /> Viewed Video 100%</p>
                                    <p><strong>{stats?.engagement?.replace("%", "")}</strong> <br /> Score</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Tabs
                        defaultActiveKey={"mostVisitedPage"}
                        id="uncontrolled-tab-example"
                        className="mt-3 select-file-pop"
                    >
                        <Tab
                            eventKey="mostVisitedPage"
                            title={'Most Visited Pages'}
                        >
                            <div className="tab-border">
                                <ColumnChart data={stats?.most_visited_pages} name={'page_views'} />
                            </div>
                        </Tab>
                        <Tab
                            eventKey="timePerPage"
                            title={'Time per Page'}
                        >
                            <div className="tab-border">
                                <ColumnChart data={stats?.time_per_page} name={'total_secs_on_page'} />
                            </div>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs defaultActiveKey={"browser"} id="uncontrolled-tab-example" className="mt-3 select-file-pop">
                        <Tab eventKey="browser" title={'Browser'}>
                            <div className="tab-border">
                                <SelectChart selectedChart={selectedChart} handleSelectChange={handleSelectChange} name='browser' />
                                <ShowCharts selectedChart={selectedChart['browser']} data={stats?.by_browser} name='browser' />
                            </div>
                        </Tab>
                        <Tab eventKey="os" title={'OS'}>
                            <div className="tab-border">
                                <SelectChart selectedChart={selectedChart} handleSelectChange={handleSelectChange} name='os' />
                                <ShowCharts selectedChart={selectedChart['os']} data={stats?.by_os} name='os_family' />
                            </div>
                        </Tab>
                        <Tab eventKey="device" title={'Device'}>
                            <div className="tab-border">
                                <SelectChart selectedChart={selectedChart} handleSelectChange={handleSelectChange} name='device' />
                                <ShowCharts selectedChart={selectedChart['device']} name='device' data={stats?.by_device} />
                            </div>
                        </Tab>
                    </Tabs>
                </Col>
                <Col>
                    <Tabs defaultActiveKey={"city"} id="uncontrolled-tab-example" className="mt-3 select-file-pop cursor-pointer">
                        <Tab eventKey="city" title={'City'}>
                            <div className="tab-border">
                                <SelectChart selectedChart={selectedChart} handleSelectChange={handleSelectChange} name='city' />
                                <ShowCharts selectedChart={selectedChart['city']} data={stats?.by_city} name='city' />
                            </div>
                        </Tab>
                        <Tab eventKey="region" title={'Region'}>
                            <div className="tab-border">
                                <SelectChart selectedChart={selectedChart} handleSelectChange={handleSelectChange} name='region' />
                                <ShowCharts selectedChart={selectedChart['region']} data={stats?.by_subdivision} name='subdivision' />
                            </div>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
}
export default AtAGlance;