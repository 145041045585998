import {
  faClipboard,
  faFilm,
  faPen,
  faTrash,
  faUserXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getTopPage } from "../../../hooks/utils";
import HtmlText from "../../CommonComponents/HtmlText";
import Paginate from "../../CommonComponents/Pagination/Paginate";
import { networkService } from "../../config/axios";
import Button from "../../Elements/Button";
import LoadingScreen from "../../Elements/LoadingScreen/LoadingScreen";
import CustomTooltip from "../../Elements/Tooltip";
import CommonModal from "../../Modals/CommonModal";
import StatsFilters from "./StatsFilters";
import StatsTableView from "./StatsTableView";
import UpdateRecipientModal from "./UpdateRecipientModal";

const Views = () => {
  const {
    tl,
    doc,
    pdfFile: { thumbs_details },
  } = window?.objInit;
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    per_page: 10,
    type: tl ? "tl" : "doc",
  });
  const [viewDataList, setViewDataList] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [editRecipientModal, setEditRecipientModal] = useState(null);
  const [potentialBoatItem, setPotentialBoatItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { pathname } = useLocation();
  const handleApplyFilter = async (filterData, type = null) => {
    let payload = {
      ...filterData,
      ...filterPayload,
      direction: type == null ? "start_time_desc" : "first_last_asc",
      name: filterData?.recipient,
      token: tl?.token || doc?.group_id,
    };
    const { data, status } = await networkService(
      `/statistics/sessions?${new URLSearchParams(payload).toString()}`,
      "GET"
    );
    setViewDataList((prev) => ({
      ...prev,
      ...data,
      ...filterPayload,
      sessions: data.sessions,
      sessions_no: data?.sessions_no,
    }));
  };

  const updateDataList = async (payloadGet = {}) => {
    setIsLoading(true)
    let payload = {
      ...filterPayload,
      direction: "start_time_desc",
      token: tl?.token || doc?.group_id,
      ...payloadGet,
    };
    try {
      const { data, status } = await networkService(
        `/statistics/sessions?${new URLSearchParams(payload).toString()}`,
        "GET"
      );
      setIsLoading(false)
      setViewDataList((prev) => ({
        ...prev,
        ...data,
        ...filterPayload,
        sessions_no: data.sessions?.length > 0 ? data.sessions[0].cnt : 0,
      }));
    } catch (error) {
      setIsLoading(false)
      console.error(error);
    }
  };

  const handleUpdateReceipient = async (formData) => {
    let payload = {};
    payload["receiver"] = {
      ...formData,
      name: `${formData?.first_name} ${formData?.last_name}`,
    };
    if (formData?.id == undefined) {
      payload["company_id"] = editRecipientModal?.tracking_link?.company_id;
      payload["session_id"] = editRecipientModal?.id;
      payload["campaign_id"] = editRecipientModal?.tracking_link?.campaign_id;
      payload["tracking_link_id"] = editRecipientModal?.tracking_link?.id;
    }
    const { data, status } = await networkService(
      `/receivers/${formData?.id}/${formData?.id == undefined ? "add_receiver_info" : "update_receiver_info"
      }`,
      "POST",
      payload
    );
    updateDataList();
    setEditRecipientModal(null);
  };

  const handleUpdatePotentialBoatEvent = async () => {
    try {
      const { data, status } = networkService(
        `mark_as_bot/${potentialBoatItem}`,
        "PATCH"
      );
      updateDataList();
      setPotentialBoatItem(null);
    } catch (error) {
      console.error(error);
    }
  };
  const handleItemToDeleteEvent = async () => {
    try {
      const { data, status } = networkService(
        `soft_delete_session/${itemToDelete}`,
        "PATCH"
      );
      updateDataList();
      setItemToDelete(null);
    } catch (error) {
      console.error(error);
    }
  };
  const handleLoadNewData = (page) => {
    setFilterPayload({
      ...filterPayload,
      page: page
    });
    updateDataList({ page: page });
  };

  useEffect(() => {
    updateDataList();
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        label: "Visits",
        key: "date",
        render: (value, row) => (
          <CustomTooltip title={<p className="p-1 text-light">Click for viewer's journey</p>}>
            <a
              href={`${pathname}${row?.receiver?.id == undefined ? "#/session/" : "#/viewer/"
                }${row?.receiver?.id != undefined ? `${row?.receiver?.id}&` : ""
                }${row.id}${row?.receiver?.id == undefined ? ",session" : ""}`}
              className={`text-center`}
            >
              {value}
            </a>
          </CustomTooltip>
        ),
      },
      {
        label: "Link",
        key: "link",
        render: (value, row) => {
          return <div className="text-center">
            <CustomTooltip title={<p className="p-1 text-light">{row?.tracking_link_tooltip}</p>}>
              <a href={`/tl-stats/${row?.tracking_link?.token}#/views`}>{row?.tracking_link.id}</a>
            </CustomTooltip>
            <a
              href={`/links/tl/${row?.tracking_link?.token}`}
              className={``}
            >
              <p>LP={row?.landing_page?.page}</p>
            </a>
          </div>
        },
      },
      {
        label: "Recipient",
        key: "recipient",
        render: (value, row) => (
          <div className={`${!value ? "hide-icon" : "image-wrapper"} text-center`}>
            {value ? (
              <CustomTooltip title={<p className="p-1 text-light">{row?.receiver?.email || "-"}</p>}>
                <span>{value}</span>
              </CustomTooltip>
            ) : (
              "Unknown"
            )}
            <CustomTooltip title={<p className="p-1 text-light">Copy Email</p>}>
              <FontAwesomeIcon
                icon={faClipboard}
                className={`visit-icon visit-edit-icon bg-transparent`}
                onClick={async () =>
                  navigator.clipboard.writeText(row?.receiver?.email)
                }
              />
            </CustomTooltip>
            <CustomTooltip title={<p className="p-1 text-light">Edit</p>}>
              <FontAwesomeIcon
                icon={faPen}
                className={`${!value ? "visit-edit-icon" : "visit-icon bg-transparent"
                  } success-green`}
                onClick={() => setEditRecipientModal(row)}
              />
            </CustomTooltip>
          </div>
        ),
      },
      {
        label: "Location",
        key: "location",
        render: (value, row) => (
          <div className="text-center">
            <CustomTooltip title={<p className="p-1 text-light">{row?.country ?? "-"}</p>}>
              <div
                style={{
                  width: "22px",
                }}
                className={`mr-1 flag-icon flag-icon-${row?.country_code?.toLowerCase()}`}
              ></div>
            </CustomTooltip>
            <CustomTooltip title={<p className="p-1 text-light">{row?.ip}</p>}>
              <a
                href={`https://google.com/maps/place/${row?.ip_details?.location?.lat},${row?.ip_details?.location?.long}`}
                target="_blank"
              >
                {value}
              </a>
            </CustomTooltip>
          </div>
        ),
      },
      {
        label: "Device Info Score",
        key: "browser",
        render: (value, row) => (
          <div className="text-center">
            <CustomTooltip title={<p className="p-1 text-light">{row?.browser_info?.browser}</p>}>
              <span className="pe-2">
                <img src={`/browser/${row?.browser_info?.browser_icon?.toLowerCase()}`} />
              </span>
            </CustomTooltip>
            <CustomTooltip title={<p className="p-1 text-light">{row?.browser_info?.os_family}</p>}>
              <span className="pe-2">
                <img src={`/os/${row?.browser_info?.os_icon}`} />
              </span>
            </CustomTooltip>
            <CustomTooltip
              title={<p className="p-1 text-light">Click this icon to report a potential bot visit.</p>}
            >
              <span
                className="cursor-pointer"
                onClick={() => setPotentialBoatItem(row?.id)}
              >
                <img src={`/device/${row?.browser_info?.device_icon}`} />
              </span>
            </CustomTooltip>
          </div>
        ),
      },
      {
        label: "Score",
        renderHeader: (value) => (
          <CustomTooltip
            title={<p className="p-1">(Number of sessions with selected engagement pages over the threshold * Number of engagement pages over the threshold) / (Total number of sessions * Total number of pages)</p>}
            light={true}
          >
            <p className="cursor-help text-center">{value?.label}</p>
          </CustomTooltip>
        ),
        key: "engagement",
        render: (value, row) => <p className="text-center">{value?.replace("%", "")}</p>,
      },
      {
        label: "Viewed",
        renderHeader: (value, row) => (
          <CustomTooltip
            title={<p className="p-1">Percentage this doc was viewed during each visit</p>}
            light={true}
          >
            <p className="cursor-help text-center">{value?.label}</p>
          </CustomTooltip>
        ),
        key: "video_element_stats",
        render: (value, row) => (
          <div className="text-center d-flex justify-content-around align-items-center">
            <CustomTooltip title={<p className="p-1">{row?.number_of_viewed_pages}</p>} light={true}>
              <p>{row?.percent_viewed}</p>
            </CustomTooltip>
            {
              (value?.message) &&
              <CustomTooltip
                title={<p className="p-1 text-light" light={true} dangerouslySetInnerHTML={{ __html: value?.message }}></p>}
              >
                <span className={`video-icn-top ${row.video_element_stats.icon}`}></span>
              </CustomTooltip>
            }
          </div>
        ),
      },
      {
        label: "Time",
        renderHeader: (value) => (
          <CustomTooltip
            title={<p className="p-1">Total time spent viewing this KDF was {tl?.stats?.min_viewed || doc?.stats?.min_viewed} (HH:MM:SS)</p>}
            light={true}
          >
            <p className="cursor-help text-center">{value?.label}</p>
          </CustomTooltip>
        ),
        key: "min_viewed",
        render: (value, row) => (
          <CustomTooltip
            title={<HtmlText htmlContent={row?.session_pages_spend_time} />}
          >
            <p className="text-center">{value}</p>
          </CustomTooltip>
        ),
      },
      {
        label: "Top Page",
        renderHeader: (value) => <p>{value?.label}</p>,
        key: "most_viewed_pages",
        render: (value, row) => (
          <CustomTooltip
            light={true}
            title={
              <div className="bg-whte p-2">
                <img
                  src={`${thumbs_details?.normal}${row?.most_viewed_pages?.pages[0] - 1
                    }.${thumbs_details?.extension}`}
                  alt={row?.most_viewed_pages?.pages[0]}
                />
                <p>Duration {row?.pages_by_time_spent?.time_spent}</p>
                <p>
                  Tags:{" "}
                  {row?.page_tags[`${row?.most_viewed_pages?.pages[0]}`]?.map(
                    (tag) => (
                      <span className="my-1 bg-tags">{tag}</span>
                    )
                  )}
                </p>
              </div>
            }
          >
            <p className="cursor-help text-center">{getTopPage(row?.time_per_page || [])}</p>
          </CustomTooltip>
        ),
      },
      {
        label: "Exit Page",
        renderHeader: (value) => <p>{value?.label}</p>,
        key: "exit_page",
        render: (value, row) => (
          <CustomTooltip
            light={true}
            title={
              <div className="bg-whte p-2">
                <img
                  src={`${thumbs_details?.normal}${row?.popular_exit_pages[0]?.page - 1
                    }.${thumbs_details?.extension}`}
                  alt={row?.popular_exit_pages[0]?.pages}
                />
              </div>
            }
          >
            <p className="cursor-help text-center">{row?.pages?.filter((page) => page?.exit_page)[0]?.page}</p>
          </CustomTooltip>
        ),
      },
    ];
  }, [viewDataList]);

  const renderActions = (row) => (
    <FontAwesomeIcon
      icon={faTrash}
      className="alert-icon"
      onClick={() => setItemToDelete(row?.id)}
    />
  );

  return (
    <>
      <UpdateRecipientModal
        editRecipientModal={editRecipientModal}
        setEditRecipientModal={setEditRecipientModal}
        submitRecipientForm={handleUpdateReceipient}
      />
      <CommonModal
        title={"Mark as potential bot"}
        modalStatus={potentialBoatItem != null}
        changeActiveStatus={() => { }}
        centered
        classList={"upload-kdf-modal-wrapper alert-modal"}
        footer={
          <div className="d-flex m-auto">
            <Button
              text={"No"}
              classList={"alert"}
              addonClass={"my-2 mx-2"}
              onClick={() => setPotentialBoatItem(null)}
            />
            <Button
              text={"Yes"}
              classList={"success"}
              type="submit"
              addonClass={"my-2 mx-2"}
              onClick={() => handleUpdatePotentialBoatEvent()}
            />
          </div>
        }
      >
        <p style={{ fontSize: "14px" }}>
          Are you sure you want to mark this visit as bot?
        </p>
      </CommonModal>
      <CommonModal
        title={"Delete Visit"}
        modalStatus={itemToDelete != null}
        changeActiveStatus={() => { }}
        centered
        classList={"upload-kdf-modal-wrapper alert-modal"}
        footer={
          <div className="d-flex m-auto">
            <Button
              text={"No"}
              classList={"alert"}
              type="button"
              addonClass={"my-2 mx-2"}
              onClick={() => setItemToDelete(null)}
            />
            <Button
              text={"Yes"}
              classList={"success"}
              type="submit"
              addonClass={"my-2 mx-2"}
              onClick={() => handleItemToDeleteEvent()}
            />
          </div>
        }
      >
        <p style={{ fontSize: "14px" }}>
          Are you sure you want to delete the stats for this visit?
        </p>
      </CommonModal>

      <StatsFilters
        threshold={(tl || doc)?.page_viewed_sec ?? "-"}
        onFilterChange={handleApplyFilter}
        defaultDirection="start_time_desc"
        token={tl?.token || doc?.group_id}
      />
      {isLoading ? (
        <LoadingScreen />
      ) : viewDataList?.sessions?.length > 0 ? (
        <>
          <StatsTableView
            columns={columns}
            data={viewDataList?.sessions?.map((it) => {
              return {
                ...it,
                date: it?.heading_date,
                recipient: it?.receiver?.name,
                location: `${it?.ip_details?.city} ${it?.ip_details?.subdivision_code}`,
                country_code: it?.ip_details?.country_code,
                country: it?.ip_details?.country,
              };
            })}
            renderActions={renderActions}
          />
          <Paginate
            currentPage={filterPayload?.page}
            totalPages={Math.ceil(
              viewDataList?.sessions_no / viewDataList?.per_page
            )}
            onPageChange={handleLoadNewData}
            totalData={viewDataList?.sessions_no}
            itemTitle={""}
            hideText={true}
          />
        </>
      ) : (
        <div className="back-alert p-3 text-white mt-5">
          <FontAwesomeIcon icon={faUserXmark} /> Sorry, no unique recipients
          were found. Either none of your recipients opted-in or the ET needs to
          be reduced to reveal identified recipients.
        </div>
      )}
    </>
  );
};
export default Views;
