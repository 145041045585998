import React, { useContext } from "react"
import LightBoxSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/lightBoxSection/LightBoxSection"
import ElementModal from "../../Pages/Kdf/EditKdf/elementModal/ElementModal"
import { EditorInfoContext } from "../../../utis/userInfoContext"

const LightBoxModal = ({ openLightboxModal, docFileId, docId, setOpenLightBox }) => {
    const { setToolType } = useContext(EditorInfoContext)

    const handleClose = () => {
        setOpenLightBox(false);
        setToolType("")
    }
    return (
        <ElementModal
            openModal={openLightboxModal}
            heading={'Lightbox options'}
            handleCloseModal={() => handleClose()}
            z_index={'1001'}
        >
            <LightBoxSection
                docFileId={docFileId}
                docId={docId}
                handleClose={handleClose}
            />
        </ElementModal>
    )
}
export default LightBoxModal