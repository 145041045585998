import React from "react";

const DisplayLanguagesSection = ({ languages, toolType }) => {
  const getPageText = (toolType) => {
    switch (toolType) {
      case "VideoToolbox":
        return "video";
      case "ImageToolbox":
        return "image";
      case "TextToolbox":
        return "text";
      case "EmbedToolbox":
        return "embed";
      case "HotspotToolbox":
        return "hotspot";
      case "HotspotToolbox":
        return "hotspot";
    }
  };
  return (
    <div className="animation-select">
      <p>
        Display this {getPageText(toolType)} on the following language editions
        of this KDF:
      </p>
      <div>
        {languages?.map((lang, index) => {
          return (
            <div className="d-flex gap-3 align-items-center" key={index}>
              <input type="checkbox" className="fs-5" />
              <div
                style={{
                  width: "18px",
                  height: "25px",
                }}
                className={lang?.language?.class}
              ></div>
              <p className="fs-6">{lang?.language?.name ?? "English (US)"}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default DisplayLanguagesSection;
