import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext";
import CustomTooltip from "../../../../../../Elements/Tooltip";
const LightBoxElement = ({
  lightBoxPayload,
  elementName,
  getElementType,
  handleChange,
  arrayOfImageElements,
}) => {
  const { setOpenAddElement, setToolType } = useContext(EditorInfoContext);

  return (
    <div className="section-element">
      <h3>INCLUDE ELEMENT</h3>
      <label className="align-items-center d-flex">
        <input
          type="radio"
          name="element"
          value="video"
          checked={
            getElementType(lightBoxPayload?.elements)?.element_type === 5 ||
            elementName === "video"
          }
          onChange={handleChange}
        />
        <p>Video</p>
      </label>
      <label className="align-items-center d-flex">
        <input
          type="radio"
          name="element"
          checked={
            getElementType(lightBoxPayload?.elements)?.element_type === 8 ||
            elementName === "audio"
          }
          value="audio"
          onChange={handleChange}
        />
        <p>Audio</p>
      </label>
      <label className="align-items-center d-flex">
        <input
          type="radio"
          name="element"
          onChange={handleChange}
          checked={
            getElementType(lightBoxPayload?.elements)?.element_type === 7 ||
            elementName === "text"
          }
          value="text"
        />
        <p>Text</p>
      </label>
      <label className="align-items-center d-flex">
        <input
          type="radio"
          name="element"
          value="html"
          checked={
            getElementType(lightBoxPayload?.elements)?.element_type === 12 ||
            elementName === "html"
          }
          onChange={handleChange}
        />
        <p>HTML</p>
      </label>
      <label className="align-items-center d-flex">
        <input
          type="radio"
          name="element"
          value="embed"
          checked={
            getElementType(lightBoxPayload?.elements)?.element_type === 6 ||
            elementName === "embed"
          }
          onChange={handleChange}
        />
        <p>Embed URL</p>
      </label>
      <label className="align-item-center d-flex">
        <input
          className="mt-1"
          type="radio"
          name="element"
          value="image"
          onChange={handleChange}
          checked={
            getElementType(lightBoxPayload?.elements)?.element_type === 4 ||
            elementName === "image"
          }
        />
        <p>Image</p>
        {arrayOfImageElements?.length === 0 ||
          (arrayOfImageElements?.length > 0 &&
            lightBoxPayload?.slide_show === true) ? (
          <CustomTooltip title={<p className="p-1 text-light">Image</p>}>
            <FontAwesomeIcon
              icon={faPlus}
              className="ms-2 fs-6 success-green cursor-pointer"
              onClick={() => {
                setToolType("LightboxesToolbox");
                setOpenAddElement(true);
              }}
            />
          </CustomTooltip>
        ) : (
          <></>
        )}
      </label>
      {(getElementType(lightBoxPayload?.elements)?.element_type === 4 ||
        elementName === "image") && (
          <label className="mt-4">
            <input
              type="checkbox"
              name="slide_show"
              className="me-2"
              value={lightBoxPayload?.slide_show}
              checked={lightBoxPayload?.slide_show}
              onChange={handleChange}
            />
            Slide show
          </label>
        )}
    </div>
  );
};
export default LightBoxElement;
