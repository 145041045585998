import { faCaretLeft, faCaretRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { debounce } from "../../../../../hooks/utils";
import usePdfSearch from "../../../../../utis/usePdfSearch";

const SearchInput = ({ showSearch, setShowSearch }) => {
    const { searchTerm, setSearchTerm, searchInPdf, goToNextMatch, goToPreviousMatch, matchCount, currentMatchIndex, clearHighlights } = usePdfSearch();
    const debouncedSearch = useCallback(
        debounce((term) => {
            if (!term.trim()) {
                clearHighlights();
            } else {
                searchInPdf();
            }
        }, 1000),
        [searchInPdf]
    );

    useEffect(() => {
        debouncedSearch(searchTerm);
    }, [searchTerm]);

    return (
        <>
            <FontAwesomeIcon
                onClick={() => setShowSearch(!showSearch)}
                icon={faMagnifyingGlass}
                className="ms-2 cursor-pointer"
            />
            {showSearch && (
                <>
                    <input
                        className="h-first-inp ms-2 me-2 text-light"
                        placeholder="Search..."
                        autoFocus={true}
                        value={searchTerm}
                        name={'search'}
                        onChange={(e) => {
                            setSearchTerm(e.target.value)
                            if (e.target.value.trim() === "") {
                                clearHighlights();
                            }
                        }}
                    />
                    <button
                        onClick={() => goToPreviousMatch()}
                        className="icon-button"
                    >
                        <FontAwesomeIcon
                            icon={faCaretLeft}
                            className="fs-4 cursor-pointer text-light"
                        />
                    </button>
                    <button
                        onClick={() => goToNextMatch()}
                        className="icon-button"
                    >
                        <FontAwesomeIcon
                            icon={faCaretRight}
                            className="fs-4 cursor-pointer text-light"
                        />
                    </button>
                    {matchCount > 0 && (
                        <span className="ms-2">
                            {currentMatchIndex + 1} / {matchCount}
                        </span>
                    )}
                </>
            )}
        </>
    )
}
export default SearchInput