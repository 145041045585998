import React from "react"
import CommonModal from "../CommonModal";
import Button from "../../Elements/Button";

const DeleteOrgModal = ({ title, openModal, closeModal, name, handleDelete }) => {
    return (<>
        <CommonModal
            title={title}
            modalStatus={openModal}
            changeActiveStatus={(status) => closeModal()}
            classList="header-profile-modal-wrapper alert-modal"
            showFooter={false}
            centered
        >
            <div>
                <p className="mb-2">Are you sure you want to {name ?? ''} ?</p>
                <div className="kdf-btn mt-3">
                    <Button text={'No'} classList={'alert'} addonClass={'my-2 mx-2'} onClick={() => closeModal()} />
                    <Button text={'Yes'} type="submit" classList={'success'} addonClass={'my-2 mx-2'} onClick={() => handleDelete()} />
                </div>
            </div>
        </CommonModal>
    </>)
}
export default DeleteOrgModal