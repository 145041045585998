import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { EditorInfoContext } from "../../../../utis/userInfoContext";
import Button from "../../../Elements/Button";
import CustomTooltip from "../../../Elements/Tooltip";
import PdfViewer from "../../Kdf/EditKdf/pdfViewer/PdfViewer";
import styles from "../Viewer/Viewer.module.css";

const StatPdfViewer = ({
  pdfFile,
  doc,
  tl,
  selectedPage,
  viewerData,
  sessionTabActive = true,
}) => {
  const zoomOptions = window?.objZoomOptions;
  const [zoomIndex, setZoomIndex] = useState({
    index: 1,
    value: "0.5",
  });
  const [pageDimensions, setPageDimensions] = useState({
    height: "",
    width: "",
  });
  const [elements, setElements] = useState({});

  const contextValue = useMemo(
    () => ({
      editEnabled: false,
      pageDimensions,
      elements,
      setElements,
      setRectangles: () => {},
      setPageDimensions,
    }),
    [elements]
  );

  const handleZoomIn = () => {
    if (zoomIndex.index < 8) {
      setZoomIndex((prev) => ({
        ...prev,
        index: prev?.index + 1,
        value: zoomOptions[Object.keys(zoomOptions)[prev?.index + 1]].value,
      }));
    }
  };

  const handleZoomOut = () => {
    if (zoomIndex.index > 1 && zoomIndex.index < 9) {
      setZoomIndex((prev) => ({
        ...prev,
        index: prev?.index - 1,
        value: zoomOptions[Object.keys(zoomOptions)[prev?.index - 1]].value,
      }));
    }
  };

  useEffect(() => {
    setElements(window?.objInit?.elements);
  }, []);

  return (
    <>
      <div className={`${styles.pdfContainer}`}>
        <input
          className="edit-value-inp ms-1 me-1"
          id="pageNumber"
          value={selectedPage?.id || selectedPage}
          type="hidden"
        />
        <div className="nmn">
          <EditorInfoContext.Provider value={contextValue}>
            <PdfViewer
              zoom={zoomIndex}
              pdfFile={`${window.location.origin.toString()}${
                pdfFile?.file?.url
              }`}
              docFileId={pdfFile?.id}
              docId={tl?.doc?.id || doc?.id}
              pageNum={selectedPage?.id ?? selectedPage}
              docFiles={tl?.doc || doc}
              timerOnDoc={tl?.doc?.timer_on_doc || doc?.timer_on_doc}
              onLoadSuccess={() => {}}
              showToolbar={false}
              editortype={"stats-viewer"}
            />
          </EditorInfoContext.Provider>
        </div>
        <div className={styles.zoomButtons}>
          <button
            className={`cursor-pointer ${styles.zoomBtn}`}
            onClick={handleZoomIn}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <button
            className={`cursor-pointer ${styles.zoomBtn}`}
            onClick={handleZoomOut}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
        </div>
        <div className="text-center mt-3">
          <p>
            {selectedPage?.id || selectedPage}/{pdfFile?.num_pages}
          </p>
        </div>
      </div>
      {sessionTabActive && (
        <>
          <div className={`text-center`}>
            <Button
              text={"Preview"}
              classList={"outline"}
              addonClass={"mt-4 mb-2"}
              onClick={() =>
                window.open(
                  doc?.viewer_live ?? tl?.viewer,
                  `viewer-${pdfFile?.id}`
                )
              }
            />
          </div>
          <CustomTooltip
            title={
              <p className="p-1 text-light">
                KFD Title: {viewerData[0]?.optin_session?.doc_title}
              </p>
            }
          >
            <p className="custom-link-success cursor-pointer">
              {viewerData[0]?.created_time_stamp}
            </p>
          </CustomTooltip>
          <p>Opt-in Occurred</p>
        </>
      )}
    </>
  );
};
export default StatPdfViewer;
