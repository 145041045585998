import React, { useState } from "react";
import { SketchPicker } from "react-color";
import CustomTagsInput from "../../../../../../CommonComponents/Tags/CustomTagsInput";
import { networkService } from "../../../../../../config/axios";
import ClickActionSection from "./ClickActionSection"
const TagSection = ({
  tool,
  handleChange,
  totalPages,
  setTool,
  errorState,
  toolType,
  editElement,
  docData
}) => {

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handeAddTags = async (value) => {
    let tagsList = await networkService(`tags.json`, "POST", { name: value });
    if (tagsList?.status === 201) {
      setTool((prevState) => {
        const newTags = prevState.tags
          ? [...prevState.tags, tagsList.data]
          : [tagsList.data];
        return {
          ...prevState,
          tags: newTags,
        };
      });
    }
  };

  const handleRemoveTags = (value) => {
    setTool({
      ...tool,
      tags: value,
    });
  };

  const handleColorChange = (color) => {
    const rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    handleChange({
      target: {
        name: "bg_color",
        value: rgba,
      },
    });
  };

  return (
    <>
      <div className="tags-inp row">
        <div className="col-md-6 p-0 ">
          <div className="d-flex  align-items-center">
            <label className="me-2 img-lab-clr" htmlFor="">
              Tags:
            </label>
            <CustomTagsInput
              tags={tool?.tags || []}
              handleAddTags={handeAddTags}
              handleRemoveTags={handleRemoveTags}
              placeholderText={'Please add a new Tag here'}
            />
          </div>
          {(!tool?.tags || tool?.tags?.length == 0) && (
            <p className="text-danger">{errorState?.tagErr}</p>
          )}
        </div>
        <div className="col-md-6">
          <div className="toggle-container high-element">
            <label className="img-lab-clr">High Priority Element: </label>
            <input
              type="checkbox"
              name="priority"
              id="toggle2"
              value={tool?.priority}
              onChange={handleChange}
              className="toggle-input"
            />
            <label htmlFor="toggle2" className="toggle-btn"></label>
          </div>
        </div>
      </div>
      <div className="color-select">
        <div className="d-flex align-items-center justify-content-between">
          {toolType != 'Clone' && <div>
            <p>BACKGROUND COLOR:</p>
            <div className="colour-picker-field">
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  backgroundColor: tool?.value?.bg_color,
                  border: "1px solid #ccc",
                  cursor: "pointer",
                }}
                onClick={() => setDisplayColorPicker(!displayColorPicker)}
              ></div>
              {displayColorPicker && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                    onClick={() => setDisplayColorPicker(false)}
                  />
                  <SketchPicker
                    color={tool?.value?.bg_color || "#ffffff"}
                    onChangeComplete={handleColorChange}
                  />
                </div>
              )}
              <input
                type="text"
                value={tool?.value?.bg_color}
                className="colour-picker-field__item colour-picker-field__item--text"
                onChange={(e) => {
                  const value = e.target.value;
                  handleChange({ target: { name: "bg_color", value } });
                }}
              />
            </div>
          </div>}
          <div className="show-on">
            <p>SHOW ON PAGE:</p>
            <div className="">
              <input
                type="number"
                name="page"
                min={1}
                value={tool?.page}
                onFocus={(e) => e.target.select()}
                onChange={handleChange}
              />
              / {totalPages ?? 1}
            </div>
          </div>

          {
                (toolType === 'ImageToolbox' ||
                    toolType === 'HotspotToolbox' ||
                    editElement?.element_type === 4 ||
                    editElement?.element_type === 9) && toolType == 'Clone' &&
                <ClickActionSection
                    totalPages={totalPages}
                    handleChange={handleChange}
                    tool={tool}
                    currentDocId={docData?.doc_id}
                    setTool={setTool}
                    errorState={errorState}
                    pdfId={docData?.id} 
                    toolType={toolType}
                />
            }
        </div>
        {tool?.page == '' ?
          <p className="text-danger text-end">Please add a valid page number</p> :
          tool?.page > totalPages ?
            <p className="text-danger text-end">
              Please enter page from 1 to {totalPages}
            </p> :
            <></>
        }
      </div>
    </>
  );
};
export default TagSection;
