import React, { useEffect, useState } from "react";
import { networkService } from "../../../config/axios";
import CustomTagsInput from "../../../CommonComponents/Tags/CustomTagsInput";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const KdfFilerForm = ({ triggerApplyFilterEvent, kdfTitleDefault = '' }) => {
  const [kdfTitle, setKdfTitle] = useState(kdfTitleDefault);
  const [kdfTags, setKdfTags] = useState({ name: "", tags: [] });
  const [status, setStatus] = useState("All");
  const [loading, setLoading] = useState(false);
  const [titleSearchList, setTitleSearchList] = useState([]);
  const [tagsSearchList, setTagsSearchList] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const [isTitleSelected, setIsTitleSelected] = useState(false);
  const [isTagSelected, setIsTagSelected] = useState(false);

  const debouncedKdfTitle = useDebounce(kdfTitle, 1000);
  const debouncedKdfTags = useDebounce(kdfTags, 1000);

  const titleSearch = async () => {
    if (!debouncedKdfTitle || isTitleSelected) return;
    let { data, status } = await networkService(
      `kdfs.json?title=${debouncedKdfTitle}`,
      "GET"
    );
    if (status === 200) {
      setTitleSearchList(data);
    } else {
      setTitleSearchList([]);
    }
  };

  useEffect(() => {
    if (debouncedKdfTitle && !isTitleSelected) {
      titleSearch();
    } else {
      setTitleSearchList([]);
    }
  }, [debouncedKdfTitle, isTitleSelected]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Default form submit रोकने के लिए
      applyFilters(event); // Apply filters on Enter key press
    }
  };

  const handleKdfTitleChange = (e) => {
    setKdfTitle(e.target.value);
    setIsTitleSelected(false); // Reset flag when typing
  };

  const handleTitleSelect = (item) => {
    setKdfTitle(item.title);
    setTitleSearchList([]);
    setIsTitleSelected(true); // Flag as selected
  };

  const tagsSearch = async () => {
    if (kdfTags?.name !== "" && !isTagSelected) {
      let { data, status } = await networkService(
        `tags.json?tag_name=${kdfTags?.name}&items=doc_list`,
        "GET"
      );
      if (status === 200) {
        setTagsSearchList(data);
      } else {
        setTagsSearchList([]);
      }
      setKdfTags((prev) => ({ ...prev, name: "" }));
    }
  };

  useEffect(() => {
    if (debouncedKdfTags && !isTagSelected) {
      tagsSearch();
    } else {
      setTagsSearchList([]);
    }
  }, [debouncedKdfTags, isTagSelected]);

  const handleKdfTagsInputChange = (e) => {
    setKdfTags((prev) => ({ ...prev, name: e }));
    setIsTagSelected(false); // Reset flag when typing
  };

  const handleTagSelect = (item) => {
    setKdfTags((prev) => ({
      ...prev,
      tags: [...prev.tags, item],
    }));
    setTagsSearchList([]);
    setIsTagSelected(true); // Flag as selected
  };

  const handleStatusChange = (e) => setStatus(e.target.textContent);

  const applyFilters = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {};
    if (kdfTitle !== "") payload["name"] = kdfTitle;
    if (status?.toLocaleLowerCase() === "published")
      payload["published"] = true;
    else if (status?.toLocaleLowerCase() === "unpublished")
      payload["published"] = false;
    else if (status?.toLocaleLowerCase() === "private")
      payload["private_flag"] = true;
    else if (status?.toLocaleLowerCase() === "public")
      payload["private_flag"] = false;
    if (kdfTags?.tags?.length > 0)
      payload["tag_ids"] = kdfTags?.tags?.map((item) => item.id);

    await triggerApplyFilterEvent(payload);
    setLoading(false);
  };

  const resetFilters = () => {
    setKdfTitle("");
    setKdfTags({ name: "", tags: [] });
    setStatus("All");
    triggerApplyFilterEvent();
  };

  return (
    <form className="kdf-main-list">
      <div className="d-flex gap-3">
        <div className="position-relative">
          <input
            className="kdf-title"
            type="text"
            placeholder="Search here for any KDF"
            value={kdfTitle}
            onChange={handleKdfTitleChange}
            onKeyDown={handleKeyDown} 
          />
          {titleSearchList.length > 0 && (
            <ul className="kdf-title-drop-ul">
              {titleSearchList.map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer kdf-title-drop-li"
                  onClick={() => handleTitleSelect(item)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        <div className="search-container">
          <a
            href=""
            className=""
            onClick={applyFilters}
            disabled={loading}
          >
             <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
                >
                <path
                  fill="#fff"
                  d="m12.911 14-4.9-4.9a4.74 4.74 0 0 1-2.956 1.011q-2.118 0-3.587-1.468Q0 7.175 0 5.056t1.468-3.588T5.056 0t3.587 1.468 1.468 3.588A4.74 4.74 0 0 1 9.1 8.01l4.9 4.9zM5.056 8.556q1.458 0 2.479-1.021 1.02-1.02 1.02-2.48 0-1.458-1.02-2.479-1.02-1.02-2.48-1.02-1.458 0-2.479 1.02-1.02 1.02-1.02 2.48 0 1.458 1.02 2.479 1.02 1.02 2.48 1.02"
                >

                </path>
              </svg>
            {/* {loading ? "Applying Filters" : "Apply Filters"} */}
          </a>
        </div>
        </div>
        <div className="all-btn">
          <div className="dropdown">
            <a
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <mask
                    id="mask0_8_1828"
                    width="24"
                    height="24"
                    x="0"
                    y="0"
                    maskUnits="userSpaceOnUse"
                    style={{ maskType: "alpha" }}
                  >
                    <path fill="#D9D9D9" d="M0 0h24v24H0z"></path>
                  </mask>
                  <g mask="url(#mask0_8_1828)">
                    <path fill="#294561" d="M10 18v-2h4v2zm-4-5v-2h12v2zM3 8V6h18v2z"></path>
                  </g>
                </svg>
                &nbsp;
              {status}
            </a>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li className="dropdown-item" onClick={handleStatusChange}>
                All
              </li>
              <li className="dropdown-item" onClick={handleStatusChange}>
                Published
              </li>
              <li className="dropdown-item" onClick={handleStatusChange}>
                Unpublished
              </li>
              <li className="dropdown-item" onClick={handleStatusChange}>
                Private
              </li>
              <li className="dropdown-item" onClick={handleStatusChange}>
                Public
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex form-group kdf-list-inp position-relative">
          <CustomTagsInput
            tags={kdfTags?.tags || []}
            handleRemoveTags={(value) =>
              setKdfTags((prev) => ({ ...prev, tags: value }))
            }
            placeholderText={'KDF tags'}
            autoSubmit={true}
            triggerKeyDownEvent={(e) => {
              handleKdfTagsInputChange(e);
              handleKeyDown(e);  // ⬅️ यह जोड़ा गया है
            }}
          />
          {tagsSearchList.length > 0 && (
            <ul className="kdf-title-drop-ul">
              {tagsSearchList.map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer kdf-title-drop-li"
                  onClick={() => handleTagSelect(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* <button
          className="btn btn-info margin-left-15 reset"
          onClick={applyFilters}
          disabled={loading}
        >
          {loading ? "Applying Filters" : "Apply Filters"}
        </button> */}
        {/* <button className="reset-btn" type="button" onClick={resetFilters}>
          Reset Filters
        </button> */}
      </div>
    </form>
  );
};

export default KdfFilerForm;
