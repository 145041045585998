import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../../CommonComponents/Table/CustomTable";
import CommonModal from "../../../Modals/CommonModal";
import apiGETRequest from "../../../../utis/apiRequest";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import Button from "../../../Elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPen, faTrashCan, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { networkService } from "../../../config/axios";
import { UserTypes } from "../../../../hooks/utils";
import CustomTooltip from "../../../Elements/Tooltip";
import UserInfoContext from "../../../../utis/userInfoContext";
import AdminPagination from "../../../CommonComponents/Pagination/AdminPagination";
import DeleteOrgModal from "../../../Modals/Admin/DeleteOrgModal";
import MoveDataModal from "../../../Modals/Admin/MoveDataModal";
import CompanyUsersModal from "../../../Modals/Admin/CompanyUsersModal";

function buildQuery(obj, prefix) {
    const queryString = Object.keys(obj).map(key => {
        const value = obj[key];
        const fullKey = prefix ? `${prefix}[${key}]` : key;
        if (value !== null && typeof value === 'object') {
            return buildQuery(value, fullKey);
        } else {
            return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
        }
    });
    return queryString.join('&');
}

const UsersListing = ({ showCheckbox = true, setData, companyId, type, dataPerPage = 10, selectAction, setSelectAction }) => {
    const { setShowNotifications, userInfoData } = useContext(UserInfoContext)
    const [users, setUsers] = useState([]);
    const [refetch, setRefetch] = useState(true);
    const [deleteModal, setDeleteModal] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [userIds, setUserIds] = useState([])
    const totalData = users?.length;

    const handleCloseModal = () => {
        setSelectAction('action')
    }

    const adduserIds = (id) => {
        setUserIds((prevUserIds) => {
            if (prevUserIds.includes(id)) {
                return prevUserIds.filter((userId) => userId !== id);
            } else {
                return [...prevUserIds, id];
            }
        });
    }

    const handlePerformAction = () => {
        const data = {
            user_ids: userIds
        }
        const url = selectAction == 'sendMail' ? `admin/users/send_confirmation_email.json` : `admin/users/destroy_all`;
        networkService(url, selectAction == 'sendMail' ? "POST" : "DELETE", data)
            .then((response) => {
                if (response.status == 204) {
                    handleCloseModal()
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: `${selectAction == 'sendMail' ? "Mail sent to all users" : 'Deleted all users'}`,
                    })
                    setRefetch(true);
                }
            })
            .catch((error) => {
                handleCloseModal()
                setShowNotifications({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
                setUserIds([])
            });
    }

    const handlePageChange = (pageNumber) => {
        const indexOfLastData = pageNumber * dataPerPage;
        const indexOfFirstData = indexOfLastData - dataPerPage;
        setCurrentPageData(users?.slice(indexOfFirstData, indexOfLastData));
    };

    const getUsers = async (companyId) => {
        const params = {
            start: 0,
            length: 10,
            draw: 1,
            search: { value: '', regex: false },
            _: Date.now()
        };
        if (type == 'userManagement' && companyId) {
            const userLst = await networkService(`admin/company-users/${companyId}.json`, 'GET')
            delete userLst?.data?.users_length;
            setUsers([...Object.values(userLst?.data)])
        } else if (type == 'bannedUser' && companyId) {
            const userList = await networkService(`admin/banned_users/${companyId}.json`, 'GET')
            setUsers(userList?.data?.users)
        }
        else {
            const queryString = buildQuery(params);
            const users = await apiGETRequest(`admin/users_listing?${queryString}`);
            setUsers([...users?.data]);
        }
    }

    const handleDelete = async () => {
        try {
            const response = await networkService(`admin/users/${selectedUser.id}.json`, 'DELETE');
            if (response.status == 204) {
                setSelectedUser(null);
                setDeleteModal(null);
                setRefetch(true);
                setShowNotifications({
                    showToast: true,
                    toastType: "success",
                    toastMessage: "user has been banned",
                })
            }
        } catch (error) {
            setShowNotifications({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
    }

    const impersonateUser = async (id) => {
        const formData = new FormData();
        formData.append('_method', 'post');
        formData.append('authenticity_token', csrfToken());
        try {
            const response = await fetch(`/admin/impersonate/${id}`, {
                method: 'POST',
                body: formData,
                cookie: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
            });
            if (response?.ok) {
                window.location.href = '/';
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    const handlePermit = async (user) => {
        const data = {
            authenticity_token: csrfToken(),
            id: user?.id,
            user: {
                company_id: user?.company_id,
                email: user?.email,
                user_type: user?.user_type,
                banned: false,
            }
        }
        try {
            const response = await networkService(`admin/users/${user?.id}.json`, 'PATCH', data);
            if (response.status == 200) {
                setRefetch(true);
                setShowNotifications({
                    showToast: true,
                    toastType: "success",
                    toastMessage: "User account is now active again!",
                })
            }
        } catch (error) {
            setShowNotifications({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        if (refetch) {
            getUsers(companyId);
            setRefetch(false);
        }
    }, [refetch, companyId]);

    useEffect(() => {
        handlePageChange(1);
    }, [users])

    const columns = React.useMemo(() => {
        return [
            ...(showCheckbox ? [{
                Header: '',
                id: "selection",
                Cell: ({ row: { original } }) => (
                    <div className="text-center">
                        <input
                            className="cursor-pointer"
                            type="checkbox"
                            onChange={() => adduserIds(original?.id)}
                        />
                    </div>
                ),
            }] : []),
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            ...(!showCheckbox ? [{
                Header: 'User Type',
                accessor: 'type_name',
                Cell: ({ row: { original } }) => (<>{type == 'bannedUser' ? UserTypes[original?.user_type ?? original?.current_user_type] : original?.type_name}</>)
            }] : []),
            {
                Header: 'Last Login',
                accessor: 'last_login',
                Cell: ({ row }) => (
                    <>
                            <>{row?.original?.last_logged_in}</>
                    </>
                ),
            },
            {
                Header: 'Country',
                accessor: 'country',
                Cell: ({ row: { original } }) => (<> {
                    original?.country?.iso_code ?
                        <div style={{
                            width: "22px"
                        }} className={`flag-icon flag-icon-${original?.country?.iso_code?.toLowerCase()}`}></div> :
                        <>{original?.country}</>}
                </>)
            },
            ...(type !== 'bannedUser' ? [{
                Header: 'PP Acceptance on',
                accessor: 'privacy_accepted_date',
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip
                            title={row?.original?.privacy_accepted_date_tooltip ?? "rrrrrrrrr"}
                            placement={"top"}
                        >
                            <>{row?.original?.privacy_accepted_date}</>
                        </CustomTooltip>
                    </>
                ),
            }] : []),
            ...(type !== 'bannedUser' ? [{
                Header: 'ToS Acceptance on',
                accessor: 'tos_accepted_date',
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip
                            title={row?.original?.tos_accepted_date ?? ""}
                            placement={"top"}
                        >
                            <>{row?.original?.tos_accepted_date}</>
                        </CustomTooltip>
                    </>
                ),
            }] : []),
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <>
                        {type == 'bannedUser' ?
                            <Button
                                text={'Permit'}
                                classList={'success'}
                                onClick={() => handlePermit(row?.original)}
                            /> :
                            <>
                                {
                                    !showCheckbox &&
                                    <FontAwesomeIcon
                                        className="me-2 cursor-pointer manage-edit"
                                        icon={faPen}
                                        onClick={() => {
                                            setData(row?.original)
                                        }}
                                    />
                                }
                                {
                                    userInfoData?.id == row?.original?.id && userInfoData?.email == row?.original?.email ?
                                        <></> :
                                        <>
                                            <FontAwesomeIcon
                                                onClick={() => impersonateUser(row?.original?.id)}
                                                icon={faUserGroup}
                                                className="me-1 cursor-pointer"
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                className="cursor-pointer cancel"
                                                onClick={() => {
                                                    setDeleteModal(row?.original?.docs_count > 0 ? 'move' : 'delete');
                                                    setSelectedUser(row?.original);
                                                }}
                                            />
                                        </>
                                }
                                {
                                    row?.original?.is_online ?
                                        <FontAwesomeIcon icon={faCircle} className="ms-2 user-active" /> :
                                        <></>
                                }
                            </>}
                    </>
                )
            },
        ];
    }, [currentPageData]);

    return (
        <>
            <DeleteOrgModal
                title={"Ban User"}
                openModal={deleteModal == 'delete'}
                closeModal={() => { setDeleteModal(null) }}
                name={`ban ${selectedUser?.name || selectedUser?.email}`}
                handleDelete={handleDelete}
            />
            <DeleteOrgModal
                title={"Delete User"}
                openModal={selectAction === "delete" && userIds.length > 0}
                closeModal={handleCloseModal}
                name={'delete all selected users'}
                handleDelete={handlePerformAction}
            />
            <DeleteOrgModal
                title={"Send Mail"}
                openModal={selectAction === "sendMail" && userIds.length > 0}
                closeModal={handleCloseModal}
                name={'send mail to all selected users'}
                handleDelete={handlePerformAction}
            />
            <MoveDataModal
                openModal={deleteModal == 'move'}
                closeModal={() => { setDeleteModal(null) }}
                userData={selectedUser}
                handleSubmit={() => setDeleteModal('moveDataModal')}
            />
            <CompanyUsersModal
                openModal={deleteModal == 'moveDataModal'}
                closeModal={() => setDeleteModal(null)}
                companyId={companyId}
                selectedUserId={selectedUser?.id}
            />

            <CustomTable data={users?.length > 0 ? currentPageData : []} columnData={columns} showFilters={showCheckbox} />
            <AdminPagination
                dataPerPage={dataPerPage}
                totalData={totalData}
                onPageChange={handlePageChange}
            />
        </>
    )
}
export default UsersListing