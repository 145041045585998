import React, { useEffect } from "react";

function TextEditorSection({ handleTextChange, html_text, errorState }) {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/tinymce/tinymce.min.js";
        script.onload = () => {
            window.tinymce.init({
                selector: "#tiny-editor",
                height: 500, // Change height
                fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 60pt 72pt 96pt", // Custom font sizes
                plugins: [
                    "anchor", "autolink", "charmap", "codesample", "emoticons",
                    "image", "link", "lists", "media", "searchreplace", "table",
                    "visualblocks", "wordcount", "code"
                ],
                toolbar: "forecolor backcolor | fontsizeinput | undo redo | fontselect fontsizeselect | bold italic underline | alignleft aligncenter alignright | numlist bullist",
                content_style: "body { line-height: 1.2em; }",
                setup: function (editor) {
                    editor.on("init", function () {
                        editor.setContent(html_text || "");
                        editor.focus();
                    });
                    // editor.on("change", function () {
                    //     handleTextChange(editor.getContent());
                    // });
                  // Use both keyup and change for better detection
                    const updateContent = () => {
                        setTimeout(() => {
                            handleTextChange(editor.getContent());
                        }, 10); // Small delay ensures the latest content is fetched
                    };

                    editor.on("keyup", updateContent);
                    editor.on("change", updateContent);
                    },
                placeholder: "Start writing...",
            });
        };
        document.body.appendChild(script);
    }, [handleTextChange, html_text]);

    return (
        <>
            <div>
                <textarea
                    id="tiny-editor"
                    defaultValue={html_text}
                    style={{ width: "100%", height: "300px" }}
                />
                {errorState?.textErr && <p className="text-danger">{errorState?.textErr}</p>}
            </div>
        </>
    );
}

export default TextEditorSection;
