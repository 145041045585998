import pako from "pako";
import { networkService } from "../components/config/axios";
import noImage from "../../assets/images/icon_upload.svg";
import { keepProportions } from "../hooks/utils";

export const compress = (obj) => {
  // Convert object to JSON string
  const jsonString = JSON.stringify(obj);

  // Compress the JSON string
  const compressed = pako.deflate(jsonString, { to: "string" });

  // Encode compressed data to Base64
  return btoa(compressed);
};

export const decompress = (str) => {
  // Decode Base64 string to compressed binary data
  const compressed = atob(str);

  // Decompress binary data to a JSON string
  const jsonString = pako.inflate(compressed, { to: "string" });

  // Parse JSON string back to an object
  return JSON.parse(jsonString);
};

export const setReceiverCookie = function (cookieKey, cookieValue) {
  var receiverCookie = getCookie("receiver", {});
  receiverCookie[cookieKey] = cookieValue;
  setCookie("receiver", receiverCookie);
};

export const setReceiverSession = async function (id, tlId, sessionId) {
  const url = `/assign-session/${id}/${tlId}/${sessionId}.json`;
  const response = await networkService(url, "GET");
  return response.data;
};

export const getCookie = function (storageKey, returnedValue) {
  var storageValue = localStorage.getItem(storageKey);
  if (storageValue === null || storageValue === undefined) {
    storageValue = returnedValue;
  } else {
    storageValue = JSON.parse(storageValue);
  }

  return storageValue;
};

export const setCookie = function (storageKey, storageValue) {
  var compressedValue = JSON.stringify(storageValue);
  localStorage.setItem(storageKey, compressedValue);
};

export const dispatchEvent = (name, id) => {
  const event = new Event(name);
  const emitter = document.getElementById(id);
  emitter.dispatchEvent(event);
};

export const scrollPage = (number, behavior) => {
  const allCanvasElements = document.getElementsByClassName("react-pdf__Page");
  const canvasArray = Array.from(allCanvasElements);
  const targetCanvas = canvasArray.find(
    (canvas) => canvas.getAttribute("data-page-number") === `${number}`
  );
  if (targetCanvas) {
    targetCanvas.scrollIntoView({ behavior: behavior });
  }
};

export const handleClearCookie = async () => {
  networkService("users/sign_out", "delete")
    .then((response) => {
      localStorage.clear();
      window.location.href = "/";
    })
    .catch((error) => {
      console.error("Error during sign out:", error);
    });
};

const toHHMMSS = (time) => {
  var sec_num = parseInt(time, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;
  hours < 1 ? (hours = "--") : hours;
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return (hours < 10 ? "0" + hours : hours) + ":" + minutes + ":" + seconds;
};

export const filterCommonPage = (pages) => {
  let isLandingPage = pages?.findIndex(
    (item) => item?.page == item?.landing_page
  );
  var filteredPages = [];
  for (let i = 0; i < pages.length; i++) {
    if (pages.length > i + 1) {
      if (pages[i].page === pages[i + 1].page) {
        pages[i] = {
          date: pages[i].date,
          date_wrt_time_zone: pages[i].date_wrt_time_zone,
          element_tags: pages[i].element_tags.concat(pages[i + 1].element_tags),
          elements_imp_clicks: pages[i].elements_imp_clicks.concat(
            pages[i + 1].elements_imp_clicks
          ),
          exit_page: pages[i].exit_page,
          exit_page_time: pages[i].exit_page_time,
          is_landing_page: i == isLandingPage,
          landing_page: pages[i].landing_page,
          language_editions: pages[i].language_editions.concat(
            pages[i + 1].language_editions
          ),
          min_viewed: toHHMMSS(pages[i].seconds + pages[i + 1].seconds),
          optin_done: pages[i].optin_done,
          optin_reject: pages[i].optin_reject,
          other_clicks: pages[i].other_clicks.concat(pages[i + 1].other_clicks),
          page: pages[i].page,
          seconds: pages[i].seconds + pages[i + 1].seconds,
          session_pause_time: pages[i].session_pause_time,
          show_pause_time: pages[i].show_pause_time,
          time_tooltip: pages[i].time_tooltip,
          video_on_page: pages[i].video_on_page,
          viewed_at: pages[i].viewed_at,
        };
        filteredPages.push(pages[i]);
        i++;
      } else {
        filteredPages.push({
          ...pages[i],
          is_landing_page: i == isLandingPage,
        });
      }
    } else {
      filteredPages.push({
        ...pages[pages.length - 1],
        is_landing_page: i == isLandingPage,
      });
    }
  }
  if (filteredPages.length > 2) {
    if (
      filteredPages[2].optin_done &&
      filteredPages[2].page === filteredPages[2].landing_page &&
      filteredPages[2].seconds < 3
    ) {
      filteredPages.splice(2, 1);
    }
  }

  return filteredPages;
};

export const handleErrorImage = (element) => {
  element.target.src = noImage;
};

export const viewsPercentage = (score, total) =>
  ((score / total) * 100).toFixed(2);

export const imageBlurWidth = (
  position,
  max_Val,
  keep_within_page,
  keep_proportions,
  oldWidthVal
) => {
  if (position.width > max_Val.max_Width) {
    if (keep_within_page) {
      position.width = max_Val.max_Width;
    }
    if (keep_proportions) {
      position.height = keepProportions(
        position.width,
        position.height,
        oldWidthVal
      );
    }
  } else {
    if (keep_proportions) {
      position.height = keepProportions(
        position.width,
        position.height,
        oldWidthVal
      );
    }
  }
  return position;
};

export const imageBlurHeight = (
  position,
  max_Val,
  keep_proportions,
  keep_within_page,
  oldHeightVal
) => {
  if (position.height > max_Val.max_Height) {
    position.height = max_Val.max_Height;
    position.width = max_Val.max_Width;
  } else {
    if (keep_proportions) {
      var prop_width = keepProportions(
        position.height,
        position.width,
        oldHeightVal
      );
      if (keep_within_page) {
        position.width =
          prop_width >= max_Val.max_Width ? max_Val.max_Width : prop_width;
      } else {
        position.width = prop_width;
      }
    }
  }
  return position;
};

export const textBlurWidth = function (
  position,
  max_Val,
  keep_proportions,
  oldWidthVal
) {
  if (position.width > max_Val.max_Width) {
    position.width = max_Val.max_Width;
    if (keep_proportions) {
      position.height = keepProportions(
        position.width,
        position.height,
        oldWidthVal
      );
    }
  } else {
    if (keep_proportions) {
      position.height = keepProportions(
        position.width,
        position.height,
        oldWidthVal
      );
    }
  }
  return position;
};

export const textBlurHeight = function (
  position,
  max_Val,
  keep_proportions,
  oldHeightVal
) {
  if (position.height > max_Val.max_Height) {
    position.height = max_Val.max_Height;
    position.width = max_Val.max_Width;
  } else {
    if (keep_proportions) {
      if (position.width < max_Val.max_Width) {
        position.width = keepProportions(
          position.height,
          position.width,
          oldHeightVal
        );
      }
    }
  }
  return position;
};

export const iframeBlurWidth = function (
  position,
  max_Val,
  keep_proportions,
  oldWidthVal
) {
  if (position.width > max_Val.max_Width) {
    position.width = max_Val.max_Width;
    if (keep_proportions) {
      position.height = keepProportions(
        position.width,
        position.height,
        oldWidthVal
      );
    }
  } else {
    if (keep_proportions) {
      position.height = keepProportions(
        position.width,
        position.height,
        oldWidthVal
      );
    }
  }
};

export const iframeBlurHeight = function (
  position,
  max_Val,
  keep_proportions,
  oldHeightVal
) {
  if (position.height > max_Val.max_Height) {
    position.height = max_Val.max_Height;
    position.width = max_Val.max_Width;
  } else {
    if (keep_proportions) {
      if (position.width < max_Val.max_Width) {
        position.width = editor.keepProportions(
          position.height,
          position.width,
          oldHeightVal
        );
      }
    }
  }
};

export const normalizeDimensions = (val, currentScale, desiredScale) => {
  if (undefined === desiredScale) {
    desiredScale = window.objInit?.zoom_lvl || 1;
  }
  return Math.round(val * (desiredScale / currentScale));
};

export const getNormalizedDimensions = (
  geometry,
  zoom,
  pageDimensions,
  element
) => {
  let geo = {};
  let top = normalizeDimensions(
    geometry?.top,
    window.objInit?.zoom_lvl || zoom?.oldValue || 1,
    zoom?.value
  );

  let left = normalizeDimensions(
    geometry?.left,
    window.objInit?.zoom_lvl || zoom?.oldValue || 1,
    zoom?.value
  );

  let height = normalizeDimensions(
    geometry?.height,
    window.objInit?.zoom_lvl || zoom?.oldValue || 1,
    zoom?.value
  );

  let width = normalizeDimensions(
    geometry?.width,
    window.objInit?.zoom_lvl || zoom?.oldValue || 1,
    zoom?.value
  );

  const page_w = pageDimensions?.width;
  const page_h = pageDimensions?.height;
  const max_w = pageDimensions?.width - Number(geometry?.left);

  if (geometry.keep_within_page) {
    top = top + height >= page_h ? page_h - height : top;
    top = top < 0 ? 0 : top;
  }

  if (geometry.keep_within_page) {
    height = height >= page_h ? page_h : height;
    top = height >= page_h ? 0 : top;

    if (max_w > width) {
      geo = {
        position: "absolute",
        left: left,
        top: top,
        width: width,
        height: height,
        fontSize: 10 * zoom?.value + "pt",
        lineHeight: 1.3
      };
    } else {
      if (element?.element_type_name == "HTML Text") {
        geo = {
          position: "absolute",
          left: left,
          top: top,
          width: max_w,
          height: height,
          fontSize: 10 * zoom?.value + "pt",
          lineHeight: 1.3
        };
      }
      width = width >= page_w ? page_w : width;
      // left = width >= page_w ? 0 : left - (width - max_w);
      height = height >= page_h ? page_h : height;
      geo = {
        position: "absolute",
        left: left,
        top: top,
        width: width,
        height: height,
        fontSize: 10 * zoom?.value + "pt",
      };
    }
  } else {
    geo = {
      position: "absolute",
      left: left,
      top: top,
      width: width,
      height: height,
      fontSize: 10 * zoom?.value + "pt",
    };
  }

  return {
    top,
    left,
    height,
    width,
    geo
  };
};

export const getOffset = (element) => {
  if (!element) return { top: 0, left: 0 };
  const rect = element.getBoundingClientRect();
  return { top: rect.top + window.scrollY, left: rect.left + window.scrollX };
};

export const calculateRectPos = (startX, startY, endX, endY) => {
  var width = endX - startX;
  var height = endY - startY;
  var posX = startX;
  var posY = startY;

  if (width < 0) {
    width = Math.abs(width);
    posX -= width;
  }

  if (height < 0) {
    height = Math.abs(height);
    posY -= height;
  }

  return {
    left: posX,
    top: posY,
    width: width,
    height: height,
  };
};

export const gifProportion = (maxWidth, maxHeight, gifWidth, gifHeight) => {
  var ratio = 0;
  var width = gifWidth;
  var height = gifHeight;
  var newWidth = 0, newHeight = 0;

  if (width > maxWidth) {
    if (maxHeight > maxWidth) {
      ratio = gifWidth / gifHeight
      newWidth = ratio * maxHeight
      newHeight = maxHeight;
    }
    else {
      if (gifHeight === gifWidth) {
        newHeight = maxHeight;
        newWidth = maxHeight;
      } else {
        ratio = gifWidth / gifHeight
        newHeight = maxHeight;
        newWidth = ratio * maxHeight;
      }
    }
  }
  else if (height > maxHeight) {
    if (maxWidth > maxHeight) {
      if (gifHeight === gifWidth) {
        newHeight = maxHeight;
        newWidth = maxHeight;
      } else {
        ratio = gifWidth / gifHeight
        newHeight = maxHeight;
        newWidth = ratio * maxHeight;
      }
    } else {
      ratio = gifWidth / gifHeight
      newWidth = ratio * maxHeight
      newHeight = maxHeight;
    }
  } else {
    if (maxWidth > maxHeight) {
      if (gifHeight === gifWidth) {
        newHeight = maxHeight;
        newWidth = maxHeight;
      } else {
        ratio = gifWidth / gifHeight
        newHeight = maxHeight;
        newWidth = ratio * maxHeight;
      }
    } else {
      ratio = gifWidth / gifHeight
      newWidth = ratio * maxHeight
      newHeight = maxHeight;
    }
  }
  return [newHeight, newWidth]
}
