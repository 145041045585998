import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import styles from "./StatsTableView.module.css";

const StatsTableView = ({
  columns,
  data,
  renderActions,
  filterData = () => { },
}) => {
  const [filterByColumn, setFilterByColumn] = useState({});
  const handleSortingEvent = (sortingKey) => {
    let filterColumnData = {};
    if (
      filterByColumn?.columnName != undefined &&
      filterByColumn?.columnName == sortingKey
    ) {
      filterColumnData["columnName"] = sortingKey;
      filterColumnData["sortOrder"] =
        filterColumnData?.sortOrder == "asc" ? "desc" : "asc";
    } else {
      filterColumnData["columnName"] = sortingKey;
      filterColumnData["sortOrder"] = "desc";
    }
    setFilterByColumn({ ...filterColumnData });
    filterData(filterColumnData);
  };
  return (
    <div className={styles.tableContainer}>
      {data?.length} visits match the current filter settings:
      <div className="visit-match-icon">
        <Table id="stats-table-data" striped bordered hover responsive className={styles.customTable}>
          <thead>
            <tr>
              {columns?.map((col, index) => (
                <th key={index} onClick={() => handleSortingEvent(col.key)}>
                  {col.renderHeader ? col.renderHeader(col) : col.label}
                </th>
              ))}
              {renderActions != null && <th>Delete</th>}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr key={index}>
                {columns.map((col) => (
                  <td key={`${col.key}-${index}`}>
                    {col.render
                      ? col.render(row[col?.key], row)
                      : row[col?.key]}
                  </td>
                ))}
                {renderActions && <td>{renderActions(row)}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default StatsTableView;
