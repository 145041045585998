import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import "./elementModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const ElementModal = ({
  heading,
  children,
  openModal,
  handleCloseModal,
  z_index,
  modalWidth,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(openModal);
    setTimeout(() => {
      setIsAnimating(true);
    }, 10);
    return () => {
      setIsOpen(false);
      setIsAnimating(true);
    };
  }, [openModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && openModal) {
        setTimeout(() => {
          setIsOpen(false);
          handleCloseModal(false);
          const divToRemove = document.getElementById("createdDivId");
          if (divToRemove) {
            divToRemove.remove();
          }
        }, 300);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal]);

  const closeModal = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsOpen(false);
      handleCloseModal(false);
    }, 300);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("Background");
    } else {
      document.body.classList.remove("Background");
    }
    return () => {
      document.body.classList.remove("Background");
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Draggable axis="x" handle=".handle-drag" position={null}>
          <div
            className={` elementModal ${isAnimating ? "fade-in" : "fade-out"}`}
            style={{ zIndex: z_index ?? "1002" }}
          >
            <div
              className={`elementModal-content ${isAnimating ? "slide-in" : "slide-out"
                }`}
              style={{ width: modalWidth ?? "618px" }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="handle-drag">
                <div className="elementModal-header">
                  <div className={`heading ${modalWidth ? "text-dark" : ""}`}>
                    {heading}
                  </div>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faArrowsUpDownLeftRight}
                      className={`elementModal-action-btn ${modalWidth ? "text-dark" : ""
                        }`}
                    />
                    <div className="spacer" />
                    <FontAwesomeIcon
                      icon={faXmark}
                      className={`elementModal-action-btn cursor-pointer ${modalWidth ? "text-dark" : ""
                        }`}
                      onClick={() => closeModal()}
                    />
                  </div>
                </div>
              </div>
              {children}
              <div className="elementModal-footer" />
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default ElementModal;
