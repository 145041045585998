import React, { useEffect, useState } from "react";
import StatsFilters from "./StatsFilters";
import { networkService } from "../../config/axios";
import {
  faClipboard,
  faPen,
  faUserXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatsTableView from "./StatsTableView";
import CustomTooltip from "../../Elements/Tooltip";
import UpdateRecipientModal from "./UpdateRecipientModal";
import LoadingScreen from "../../Elements/LoadingScreen/LoadingScreen";
import Paginate from "../../CommonComponents/Pagination/Paginate";
import { handleErrorImage } from "../../../utis/helper";

const Viewers = () => {
  const {
    tl,
    doc,
    pdfFile: { thumbs_details },
  } = window?.objInit;
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    per_page: 10,
    type: tl ? "tl" : "doc",
  });
  const [viewDataList, setViewDataList] = useState({ loading: true });
  const [editRecipientModal, setEditRecipientModal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleApplyFilter = async (filterData, type = null) => {
    setViewDataList((prev) => ({ ...prev, loading: true }));
    let payload = {
      ...filterData,
      ...filterPayload,
      name: filterData?.recipient,
      token: tl?.token || doc?.group_id,
    };
    try {
      setIsLoading(true);
      const { data, status } = await networkService(
        `/statistics/receivers?${new URLSearchParams(payload).toString()}`,
        "GET"
      );
      setIsLoading(false);
      setViewDataList((prev) => ({
        ...prev,
        ...filterPayload,
        data: [...data],
        loading: false,
      }));
    } catch (error) {
      console.error(`Error: ${error}`);
      setIsLoading(false);
      setViewDataList((prev) => ({ ...prev, loading: false }));
    }
    setFilterPayload(payload);
  };

  const updateDataList = async (payloadGet = {}) => {
    if (Object.keys(payloadGet)?.length > 0) {
      setViewDataList((prev) => ({ ...prev, loading: true }));
    }
    try {
      let payload = {
        ...filterPayload,
        ...viewDataList,
        token: tl?.token || doc?.group_id,
        ...payloadGet,
      };
      setIsLoading(true);
      const { data, status } = await networkService(
        `/statistics/receivers?${new URLSearchParams(payload).toString()}`,
        "GET"
      );
      if (status == 200) {
        setViewDataList((prev) => ({
          ...prev,
          ...filterPayload,
          data: data,
          loading: false,
        }));
        setFilterPayload((prev) => ({ ...prev, ...payloadGet }));
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleLoadNewData = (paginateData) => {
    updateDataList({ page: paginateData });
  };

  const handleUpdateReceipient = async (formData) => {
    let payload = {};
    payload["receiver"] = {
      ...formData,
      name: `${formData?.first_name} ${formData?.last_name}`,
    };
    if (formData?.id == undefined) {
      payload["company_id"] = editRecipientModal?.tracking_link?.company_id;
      payload["session_id"] = editRecipientModal?.id;
      payload["campaign_id"] = editRecipientModal?.tracking_link?.campaign_id;
      payload["tracking_link_id"] = editRecipientModal?.tracking_link?.id;
    }
    const { data, status } = await networkService(
      `/receivers/${formData?.id}/${formData?.id == undefined ? "add_receiver_info" : "update_receiver_info"
      }`,
      "POST",
      payload
    );
    updateDataList();
    setEditRecipientModal(null);
  };

  useEffect(() => {
    updateDataList();
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        label: "Recipient",
        renderHeader: (value) => (
          <CustomTooltip
            title={<p className="p-1">{
              viewDataList?.data?.length > 0
                ? `Total Recipients: ${viewDataList?.data[0].cnt}`
                : ""
            }</p>}
            light={true}
          >
            <p className="cursor-pointer">{value?.label}</p>
          </CustomTooltip>
        ),
        key: "name",
        render: (value, row) => (
          <p className={`hide-icon`}>
            {value ? (
              <CustomTooltip title={<p className="p-1 text-light">{row?.email || "-"}</p>}>
                <span>{value}</span>
              </CustomTooltip>
            ) : (
              "Unknown"
            )}
            <CustomTooltip title={<p className="p-1 text-light">Copy Email</p>}>
              <FontAwesomeIcon
                icon={faClipboard}
                className={`icon bg-transparent`}
                onClick={async () => navigator.clipboard.writeText(row?.email)}
              />
            </CustomTooltip>
            <CustomTooltip title={<p className="p-1 text-light">Edit</p>}>
              <FontAwesomeIcon
                icon={faPen}
                className={`${value == undefined ? "" : "icon bg-transparent"
                  } success-green`}
                onClick={() =>
                  setEditRecipientModal({ id: row?.id, receiver: row })
                }
              />
            </CustomTooltip>
          </p>
        ),
      },
      {
        label: "Visits",
        key: "id",
        render: (value, row) => (
          <CustomTooltip
            light={true}
            title={
              <div className="bg-whte p-2">
                <p>
                  Last {row?.sessions?.length} of {row?.sessions?.length} view
                </p>
                {row?.sessions?.map((sessionItem) => (
                  <p key={sessionItem?.id}>
                    {sessionItem?.date}
                    <img
                      className="d-inline-flex"
                      src={`/browser/${sessionItem?.browser_info?.browser_icon?.toLowerCase()}`}
                    />
                    <img
                      className="d-inline-flex"
                      src={`/os/${sessionItem?.browser_info?.os_icon}`}
                    />
                    <img
                      className="d-inline-flex"
                      src={`/device/${sessionItem?.browser_info?.device_icon}`}
                    />
                    <span className="d-flex">
                      <img
                        src={`${assetsBasePath}/${sessionItem?.ip_details?.country_code?.toLowerCase() ??
                          "en"
                          }.png`}
                        alt={sessionItem?.ip_details?.country}
                        width="22px"
                        className="mr-1"
                      />
                      {sessionItem?.ip_details?.city ?? "-"},
                      {sessionItem?.ip_details?.country ?? "-"}
                    </span>
                  </p>
                ))}
              </div>
            }
          >
            <p className="cursor-help">{row?.sessions?.length || 0}</p>
          </CustomTooltip>
        ),
      },
      {
        label: "Time",
        key: "min_viewed",
        render: (value, row) => (
          <CustomTooltip title={<p className="p-1 text-light">HH:MM:SS</p>}>
            <p>{value}</p>
          </CustomTooltip>
        ),
      },
      {
        label: "Viewed",
        key: "percent_viewed",
      },
      {
        label: "Score",
        key: "engagement",
        render: (value, row) => {
          <p className="text-center">{value?.label?.replace("%", "")}</p>
        }
      },
      {
        label: "Top Page",
        renderHeader: (value) => <p>{value?.label}</p>,
        key: "most_viewed_pages",
        render: (value, row) => (
          <>
            {/* most_viewed_pages = {JSON.stringify(row?.sessions[0].most_viewed_pages)} */}
            {/* {row?.pages_by_time_spent?.pages?.map(pg =>  */}
            <CustomTooltip
              light={true}
              title={
                <div className="bg-whte p-2">
                  <img
                    src={`${thumbs_details?.normal}${row?.pages_by_time_spent?.pages[0] - 1
                      }.${thumbs_details?.extension}`}
                    alt={row?.pages_by_time_spent?.pages[0]}
                  />
                  <p>Duration {row?.pages_by_time_spent?.time_spent}</p>
                </div>
              }
            >
              <p className="cursor-help">
                {row?.pages_by_time_spent?.pages[0]}
              </p>
            </CustomTooltip>
            {/* )} */}
          </>
        ),
      },
      {
        label: "Exit Page(s)",
        renderHeader: (value) => <p>{value?.label}</p>,
        key: "popular_exit_pages",
        render: (value, row) => (
          <>
            {row?.popular_exit_pages?.pages?.map((pg, ind) => (
              <CustomTooltip
                key={ind}
                light={true}
                title={
                  <div className="bg-whte p-2">
                    <img
                      src={`${thumbs_details?.normal}${pg - 1}.${thumbs_details?.extension
                        }`}
                      onError={(e) => handleErrorImage(e)}
                      alt={pg}
                      width="99px"
                      height="129px"
                    />
                  </div>
                }
              >
                <p className="cursor-help">{pg}</p>
              </CustomTooltip>
            ))}
          </>
        ),
      },
    ];
  }, [viewDataList]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <StatsFilters
            threshold={(tl || doc)?.page_viewed_sec ?? "-"}
            onFilterChange={handleApplyFilter}
            sorting={true}
            defaultDirection="first_last_asc"
            token={tl?.token || doc?.group_id}
          />
          <UpdateRecipientModal
            editRecipientModal={editRecipientModal}
            setEditRecipientModal={setEditRecipientModal}
            submitRecipientForm={handleUpdateReceipient}
          />
          {viewDataList?.loading ? (
            <LoadingScreen />
          ) : viewDataList?.data?.length > 0 ? (
            <>
              <StatsTableView
                columns={columns}
                data={viewDataList?.data?.map((it) => ({
                  ...it,
                  recipient: it?.receiver?.name,
                  location: `${it?.ip_details?.city} ${it?.ip_details?.subdivision_code}`,
                  country_code: it?.ip_details?.country_code,
                  country: it?.ip_details?.country,
                }))}
              />
              <Paginate
                currentPage={viewDataList?.data}
                totalPages={Math.ceil(
                  viewDataList?.data[0].cnt / viewDataList?.per_page
                )}
                onPageChange={handleLoadNewData}
                totalData={viewDataList?.data[0].cnt}
                itemTitle={""}
                hideText={true}
              />
            </>
          ) : (
            <div className="back-alert p-3 text-white mt-5">
              <FontAwesomeIcon icon={faUserXmark} /> Sorry, no unique recipients
              were found. Either none of your recipients opted-in or the ET
              needs to be reduced to reveal identified recipients.
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Viewers;
