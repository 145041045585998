import React, { useContext, useEffect, useState } from "react";
import { networkService } from "../../../../../config/axios";
import { EditorInfoContext } from "../../../../../../utis/userInfoContext";
import { csrfToken } from "../../../../../../utis/csrfTokenUtils";

const ToolBoxFooter = ({ doc }) => {
    const { setShowToast, tagValue, setTagValue } = useContext(EditorInfoContext)
    const [deactivate, setDeavtivate] = useState(doc?.timer_on_doc)


    const handleChange = async ({ target: { checked } }, type) => {
        if (type == 'tag') {
            setTagValue(checked)
            localStorage.setItem('outsideTags', checked)
        } else {
            setDeavtivate(checked)
            const payload = {
                authenticity_token: csrfToken(),
                doc: {
                    timer_on_doc: checked,
                    user_id: doc?.user_id
                }
            }
            const res = await networkService(`kdfs/${doc?.id}.json`, 'PATCH', payload);
            if (res.status == 200) {
                setShowToast({
                    showToast: true,
                    toastType: 'success',
                    toastMessage: "Success"
                })
            } else {
                setShowToast({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: "Something went wrong"
                })
            }
        }
    }

    return (
        <>
            <div className="editorToolbox__checkboxContainer align-items-center">
                <input
                    type="checkbox"
                    id="showTags"
                    className='me-2 cursor-pointer'
                    checked={tagValue}
                    value={tagValue}
                    onChange={(e) => handleChange(e, 'tag')}
                />
                <label htmlFor="showTags">Show page tags outside the page</label>
            </div>
            <div className="editorToolbox__checkboxContainer align-items-center">
                <input
                    type="checkbox"
                    d="deactivateTimer"
                    className='me-2 cursor-pointer'
                    checked={deactivate}
                    value={deactivate}
                    onChange={(e) => handleChange(e, 'deactivate')}
                />
                <label htmlFor="deactivateTimer">Deactivate the element timer</label>
            </div>
        </>
    )
}
export default ToolBoxFooter