import React from "react";
import Image from "../../../../CommonComponents/Image";
import IFrame from "../../../../CommonComponents/IFrame";
import HtmlText from "../../../../CommonComponents/HtmlText";
import YoutubeIframe from "../../../../CommonComponents/YoutubeIframe";
import VimeoIframe from "../../../../CommonComponents/VimeoIframe";

const Element = ({ element, setVideoData, actionCableVideoMontior, dimensions, resizeDimensions, zoom, maskedDimensions, bgColor, elemClick, handleClick, handleAction
}) => {

  switch (element?.element_type_name) {
    case "Image":
      return (
        element?.geometry?.hide_beyond_page_edges ?
          <div
            onClick={(event) => {
              elemClick(element?.id, event, element)
              handleClick(element)
              element?.dropdown_data && handleAction(element)
            }}
            style={{
              width: `${maskedDimensions?.intersectingWidth - 3}px`,
              height: `${maskedDimensions?.intersectingHeight - 3}px`,
              overflow: 'hidden',
              background: `${bgColor}`,
              ...((resizeDimensions[element.id]?.left ? resizeDimensions[element.id]?.left < 0 : dimensions?.left < 0) && { float: 'right' })
            }}>
            <Image
              id={'image' + element?.id}
              src={element?.file_url ?? element?.value?.gif_link}
              alt="Uploaded image"
              onClick={(event) => elemClick(element?.id, event, element)}
              onDragStart={(e) => e.preventDefault()}
              style={{
                height: `${resizeDimensions[element.id]?.height - 3 || dimensions?.height - 3}px`,
                width: `${resizeDimensions[element.id]?.width - 3 || dimensions?.width - 3}px`,
                ...(element?.file_url && { objectFit: "cover" }),
                transform: `scale(${parseFloat(zoom?.value)})`,
                opacity: `${element?.transparency ?? 10}`,
                ...((resizeDimensions[element.id]?.left ? resizeDimensions[element.id]?.left < 0 : dimensions?.left < 0) && { float: 'right' }),
                transform: `scaleX(${element?.flip ?? 1})`,
              }}
            />
          </div>
          :
          <Image
            id={'image' + element?.id}
            src={element?.file_url ?? element?.value?.gif_link}
            alt="Uploaded image"
            onDragStart={(e) => e.preventDefault()}
            style={{
              height: `${resizeDimensions[element.id]?.height - 3 || dimensions?.height - 3}px`,
              width: `${resizeDimensions[element.id]?.width - 3 || dimensions?.width - 3}px`,
              ...(element?.file_url && { objectFit: "cover" }),
              transform: `scale(${parseFloat(zoom?.value)})`,
              opacity: `${element?.transparency ?? 10}`,
              transform: `scaleX(${element?.flip ?? 1})`
            }}
            onClick={(event) => {
              elemClick(element?.id, event, element)
              handleClick(element)
              element?.dropdown_data && handleAction(element)
            }}
          />
      );
    case "Video":
      if (element.value.source === 'vimeo') {
        return (
          <VimeoIframe
            onClick={(event) => elemClick(element?.id, event, element)}
            setVideoData={setVideoData}
            actionCableVideoMontior={actionCableVideoMontior}
            style={{
              height: `${resizeDimensions[element.id]?.height - 3 || dimensions?.height - 3}px`,
              width: `${resizeDimensions[element.id]?.width - 3 || dimensions?.width - 3}px`,
            }}
            src={element?.value?.video_link}
            title="url"
            id={element?.id}
          />
        );
      } else if (element.value.source === 'youtube') {
        return (
          <YoutubeIframe
            onClick={(event) => elemClick(element?.id, event, element)}
            actionCableVideoMontior={actionCableVideoMontior}
            setVideoData={setVideoData}
            style={{
              height: `${resizeDimensions[element.id]?.height - 3 || dimensions?.height - 3}px`,
              width: `${resizeDimensions[element.id]?.width - 3 || dimensions?.width - 3}px`,
            }}
            src={element?.value?.video_link}
            title="url"
            id={element?.id}
          />
        );
      }
    case "Web URL":
      return (
        <IFrame
          onClick={(event) => elemClick(element?.id, event, element)}
          style={{
            height: `${resizeDimensions[element.id]?.height - 3 || dimensions?.height - 3}px`,
            width: `${resizeDimensions[element.id]?.width - 3 || dimensions?.width - 3}px`,
          }}
          scrolling={element?.value?.no_scroll ? 'no' : 'yes'}
          src={element?.value?.video_link}
          title="url"
          id={element?.id}
        />
      );
    case "HTML Text":
      return <HtmlText
        onClick={(event) => {
          elemClick(element?.id, event, element)
          handleClick(element)
          element?.dropdown_data && handleAction(element)
        }}
        htmlContent={element?.value?.html_text} />;
    default:
      return <></>;
  }
};

export default Element;
