import {
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomTooltip from "../../Elements/Tooltip";
import styles from "./PageByPage.module.css";
import StatPdfViewer from "./SessionComponents/StatPdfViewer";
import { viewsPercentage } from "../../../utis/helper";

const PageByPage = ({ }) => {
  const pdfFile = window.objInit.pdfFile;
  const doc = window.objInit.tl?.doc || window.objInit.doc;
  const stats = window.objInit.tl?.stats || window.objInit.doc.stats;
  const elementsz = window?.objInit?.elements;

  const [timePerPage, setTimePerPage] = useState(stats?.time_per_page?.sort((a, b) => a.page - b.page) || []);
  const [settings, setSettings] = useState({ page: 1, settings: "time_desc" });
  const [disableBeforeChange, setDisableBeforeChange] = useState(false);
  const sliderRef = useRef(null);
  const sortingOptions = [
    { key: "time_desc", label: "Duration", icon: faArrowDown },
    { key: "time_asc", label: "Duration", icon: faArrowUp },
    { key: "seprator", label: "", icon: "" },
    {
      key: "page_asc",
      label: `Page 1 to ${pdfFile?.num_pages || "-"}`,
      icon: faArrowUp,
    },
    {
      key: "page_desc",
      label: `Page ${pdfFile?.num_pages || "-"} to 1`,
      icon: faArrowDown,
    },
  ];
  const selectedOption = sortingOptions.find(
    (option) => option.key === settings.settings
  );
  const getElementOfPage = (pageId) => {
    return Object.keys(elementsz)
      .map((elementKey) => elementsz[elementKey])
      .filter((element) => element.page == pageId);
  };

  const carouselSettings = {
    dots: false,
    infinite: pdfFile?.num_pages > 2 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (oldIndex, newIndex) => {
      if (disableBeforeChange) return;
      setSettings((prev) => ({
        ...prev,
        page: timePerPage[newIndex]?.page || 1,
      }));
    },
    afterChange: (oldIndex, newIndex) => {
      if (disableBeforeChange) {
        setDisableBeforeChange(false);
      };
    },
  };

  const handleUpdateSettingsPage = (e) => {
    const value = parseInt(e.target.value, 10);
    setSettings((prev) => ({ ...prev, page: e.target.value }));
    if (value > 0 && value <= timePerPage.length) {
      setDisableBeforeChange(true);
      sliderRef.current.slickGoTo(value - 1);
    }
  };

  const convertToSeconds = (timeStr) => {
    const [hh, mm, ss] = timeStr
      .split(":")
      .map((t) => parseInt((t == "--" ? "00" : t) || 0, 10));
    return hh * 3600 + mm * 60 + ss;
  };

  const durationPercentage = (score, total) =>
    ((convertToSeconds(score) / convertToSeconds(total)) * 100).toFixed(2);

  const handleUpdateSort = (ekey) => {
    let sortedData;
    switch (ekey) {
      case "time_desc":
        sortedData = [...timePerPage].sort(
          (a, b) =>
            convertToSeconds(b.total_min_on_page) -
            convertToSeconds(a.total_min_on_page)
        );
        break;
      case "time_asc":
        sortedData = [...timePerPage].sort(
          (a, b) =>
            convertToSeconds(a.total_min_on_page) -
            convertToSeconds(b.total_min_on_page)
        );
        break;
      case "page_asc":
        sortedData = [...timePerPage].sort((a, b) => a.page - b.page);
        break;
      case "page_desc":
        sortedData = [...timePerPage].sort((a, b) => b.page - a.page);
        break;
      default:
        sortedData = [...timePerPage];
    }
    setTimePerPage((prev) => [...sortedData]);
    setSettings((prev) => ({
      ...prev,
      settings: ekey,
      page: sortedData[0].page || 1,
    }));
  };

  return (
    <Row className={`lower-glance container justify-content-between`}>
      <Col md={5} className="mt-2 page-views-slider">
        <div className="d-flex align-items-center lower-content">
          <p>Page: </p>
          <input
            className="edit-value-inp ms-1 me-1"
            id="pageNumber"
            value={settings?.page}
            max={pdfFile?.num_pages}
            onChange={(e) => handleUpdateSettingsPage(e)}
          />
          <p>/{pdfFile?.num_pages || "-"}</p>
          <p className="ms-3 me-2">Sort by:</p>
          <DropdownButton
            className={styles.directionDropDown}
            id="dropdown-basic-button"
            title={
              selectedOption ? (
                <span className="me-4">
                  <FontAwesomeIcon
                    icon={selectedOption.icon}
                    className="me-2"
                  />
                  {selectedOption.label}
                </span>
              ) : (
                "Select Sorting"
              )
            }
            onSelect={(eKey) => handleUpdateSort(eKey)}
            variant="secondary"
          >
            {sortingOptions?.map((option) =>
              option.key === "seprator" ? (
                <Dropdown.Divider key={option?.key} />
              ) : (
                <Dropdown.Item key={option.key} eventKey={option.key}>
                  <FontAwesomeIcon icon={option.icon} className="me-2" />
                  {option.label}
                </Dropdown.Item>
              )
            )}
          </DropdownButton>
        </div>
        <div className={styles.carouselContainer}>
          <Slider {...carouselSettings} ref={sliderRef}>
            {Array.from({ length: pdfFile?.num_pages }, (_, i) => i + 1)?.map(
              (_, index) => {
                const data = timePerPage[index];
                if (data?.secs_on_page > doc?.page_viewed_sec) {
                  return (
                    <div key={index} className={styles.card}>
                      <div className={styles.section}>
                        <h5>Page views</h5>
                        <progress
                          value={viewsPercentage(data?.views, stats?.viewers)}
                          max="100"
                        ></progress>
                        <p>
                          This page was viewed {data?.views} out of{" "}
                          {stats?.viewers} times
                        </p>
                      </div>
                      {stats?.most_viewed_pages.pages.includes(data?.page) ? (
                        <CustomTooltip title={<p className="p-1">Most Visited Page</p>} light={true}>
                          <img
                            src="/images/green-medal.svg"
                            alt="Most Viewed"
                            className={styles.badgeImage}
                          />
                        </CustomTooltip>
                      ) : (
                        ""
                      )}
                      {data.page_exists != null ? (
                        <div className={styles.section}>
                          <h5>Exit page</h5>
                          <progress
                            value={viewsPercentage(data?.views, stats?.viewers)}
                            max="100"
                          ></progress>
                          <p>
                            {data.page_exists} out of {stats?.viewers} people
                            exited on this page
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={styles.section}>
                        <h5>Duration</h5>
                        <progress
                          value={durationPercentage(
                            data.total_min_on_page,
                            stats?.total_min_viewed
                          )}
                          max="100"
                        ></progress>
                        <p>
                          {data.total_min_on_page}/--:{stats?.total_min_viewed}
                        </p>
                      </div>
                      {getElementOfPage(settings?.page)?.length > 0 && (
                        <div className={styles.interactive}>
                          <h6>Interactive Elements Activated</h6>
                          {getElementOfPage(settings?.page)?.map((elem) => (
                            <p
                              key={elem.id}
                              className="text-link-success d-flex justify-content-between border-bottom"
                            >
                              <span className="text-link-success cursor-pointer">
                                Element {elem.id}
                              </span>
                              <a href="#/views" className="text-link-success cursor-pointer">
                                {stats?.element_clicks[elem.id]}
                              </a>
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                } else {
                  return <div className="page-not-view">This page was not viewed</div>;
                }
              }
            )}
          </Slider>
        </div>
      </Col>
      <Col md={5} className="main-coloums slider-stats">
        <div>
          <p className="mb-2">Page preview</p>
          <StatPdfViewer
            pdfFile={pdfFile}
            doc={doc}
            selectedPage={settings?.page}
            sessionTabActive={false}
          />
        </div>
      </Col>
    </Row>
  );
};

export default PageByPage;