import React, { useContext, useEffect } from "react"
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext"
import { getNormalizedDimensions, iframeBlurHeight, iframeBlurWidth, imageBlurHeight, imageBlurWidth, textBlurHeight, textBlurWidth } from "../../../../../../../utis/helper";

const PositionSection = ({ tool, handleChange, setTool, errorState, zoom }) => {
    const { editElement, setGeo, geo, pageDimensions } = useContext(EditorInfoContext)

    const blurWidth = () => {
        let position = {
            width: tool?.geometry?.width,
            height: tool?.geometry?.height,
        };
        const max_Val = {
            max_Width: (pageDimensions?.width - Number(tool?.geometry?.left))
        }
        const keep_within_page = tool.geometry.keep_within_page;
        const keep_proportions = tool.geometry.keep_proportions;

        if (tool.element_type_name === "Image") {
            position = imageBlurWidth(position, max_Val, keep_within_page, keep_proportions, editElement?.geometry?.width);
        } else if (tool.element_type_name === "HTML Text") {
            position = textBlurWidth(position, max_Val, keep_proportions, editElement?.geometry?.width);
        } else if (tool.element_type_name === "Web URL") {
            position = iframeBlurWidth(position, max_Val, keep_proportions, editElement?.geometry?.width);
        }

        setTool({
            ...tool,
            geometry: {
                ...tool.geometry,
                height: position.height,
                width: position.width
            }
        });
    }

    const blurHeight = () => {
        let position = {
            width: tool?.geometry?.width,
            height: tool?.geometry?.height,
        };
        const max_Val = {
            max_Width: (pageDimensions?.width - Number(tool?.geometry?.left)),
            max_Height: (pageDimensions?.height - Number(tool?.geometry?.top)),
        }
        const keep_within_page = tool.geometry?.keep_within_page;
        const keep_proportions = tool.geometry?.keep_proportions;

        if (tool.element_type_name === "Image") {
            position = imageBlurHeight(position, max_Val, keep_within_page, keep_proportions, editElement?.geometry?.height);
        } else if (tool.element_type_name === "HTML Text") {
            position = textBlurHeight(position, max_Val, keep_proportions, editElement?.geometry?.height);
        } else if (tool.element_type_name === "Web URL") {
            position = iframeBlurHeight(position, max_Val, keep_proportions, editElement?.geometry?.height);
        }

        setTool({
            ...tool,
            geometry: {
                ...tool.geometry,
                height: position.height,
                width: position.width
            }
        });
    }
    useEffect(() => {
        const currentDimensions = getNormalizedDimensions(tool?.geometry, zoom?.value, pageDimensions)
        setGeo(currentDimensions)
    }, [])

    return (
        <>
            <div className="position-section d-flex align-items-center justify-content-between">
                <label className="img-lab-clr">POSITION</label>
                <div className="d-flex position-left-right">
                    <div className="me-3 ">
                        Left: <input
                            type="number"
                            name="geometry.left"
                            value={tool?.geometry?.left}
                            onChange={(e) => {
                                handleChange(e)
                                setGeo({
                                    ...geo,
                                    left: e.target.value,
                                });
                            }}
                            onFocus={(e) => e.target.select()}
                        />
                    </div>
                    <div className="top-left-pop">
                        Top:
                        <input
                            type="number"
                            name="geometry.top"
                            value={tool?.geometry?.top}
                            onChange={(e) => {
                                handleChange(e)
                                setGeo({
                                    ...geo,
                                    top: e.target.value,
                                });
                            }}
                            onFocus={(e) => e.target.select()}
                        />
                    </div>
                </div>
            </div>
            <div className="size-section d-flex justify-content-between cursor-pointer">
                <div className="const-pro">
                    CONSTRAIN PROPORTIONS
                    <input
                        type="checkbox"
                        name='geometry.keep_proportions'
                        value={tool?.geometry?.keep_proportions}
                        checked={tool?.geometry?.keep_proportions}
                        onChange={handleChange}
                    />
                </div>
                <div className="d-flex flex-column">
                    <div className="d-flex align-items-center const-width">
                        <div>
                            Width:
                            <input
                                type="number"
                                name="geometry.width"
                                value={tool?.geometry?.width}
                                onChange={(e) => {
                                    handleChange(e)
                                    setGeo({
                                        ...geo,
                                        width: e.target.value,
                                    });
                                }}
                                onBlur={() => blurWidth()}
                                onFocus={(e) => e.target.select()}
                            />
                        </div>
                        <div>
                            Height:
                            <input
                                type="number"
                                name="geometry.height"
                                value={tool?.geometry?.height}
                                onChange={(e) => {
                                    handleChange(e)
                                    setGeo({
                                        ...geo,
                                        height: e.target.value,
                                    });
                                }}
                                onBlur={() => blurHeight()}
                                onFocus={(e) => e.target.select()}
                            />
                        </div>
                    </div>
                    <p className="text-danger">{errorState?.dimensionError}</p>
                </div>
            </div>
        </>
    )
}
export default PositionSection