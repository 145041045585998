import React, { useContext, useEffect, useState } from "react";
import { csrfToken } from "../../../../utis/csrfTokenUtils";
import { EditorInfoContext } from "../../../../utis/userInfoContext";
import { networkService } from "../../../config/axios";
import CustomTagsInput from "../../../CommonComponents/Tags/CustomTagsInput";

const TagEditorSection = ({
  docFileId,
  docId,
  docFiles,
  currentPage,
  numPages,
}) => {
  const { setShowToast, tagValue } = useContext(EditorInfoContext);
  const [docTags, setDocTags] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [isChecked, setIsChecked] = useState({
    monitor: 0,
    all_tag: 0,
  });
  const [tagIds, setTagIDs] = useState([]);

  //add tags to targeted page api call
  const handleAddTagsWithPage = async (tags, type) => {
    const payload = {
      authenticity_token: csrfToken(),
      doc_id: docId,
      docs_file_id: docFileId,
      page: pageNumber,
      tags_doc_files: tags,
    };

    let pageList = await networkService(`docs/page-tags`, "POST", payload);
    if (pageList.status == 200 && type == "added") {
      setShowToast({
        showToast: true,
        toastType: "success",
        toastMessage: "Tags added",
      });
    }
  };

  //add tags api
  const handeAddTags = async (value) => {
    let ids = [];
    let tagsList = await networkService(`tags.json`, "POST", { name: value });
    ids.push(...docTags?.tags[pageNumber].map((item) => item?.id));
    if (tagsList?.status === 201) {
      setDocTags((prevDocTags) => {
        const updatedTags = [...(prevDocTags?.tags[pageNumber] || [])];
        if (tagsList?.data) {
          updatedTags?.push(tagsList.data);
        }
        return {
          ...prevDocTags,
          tags: {
            ...prevDocTags?.tags,
            [pageNumber]: updatedTags,
          },
        };
      });
      const uniqueIds = Array.from(
        new Set([...ids, ...tagIds, tagsList?.data?.id])
      );
      setTagIDs(uniqueIds);
      handleAddTagsWithPage(uniqueIds, "added");
    }
  };

  const handleRemoveTags = (value) => {
    const remainingIds = value?.map((item) => item?.id);
    handleAddTagsWithPage(remainingIds, "deleted");
    setDocTags((prevDocTags) => {
      const currentTags = prevDocTags?.tags[pageNumber] || [];
      const updatedTags = currentTags.filter((tag) =>
        remainingIds.includes(tag.id)
      ); // Filter only the remaining IDs
      return {
        ...prevDocTags,
        tags: {
          ...prevDocTags?.tags,
          [pageNumber]: updatedTags,
        },
      };
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setIsChecked({
      ...isChecked,
      [name]: checked ? 1 : 0,
    });
  };

  useEffect(() => {
    const handleTagSection = async () => {
      const data = {
        authenticity_token: csrfToken(),
        doc_id: docId,
        docs_file_id: docFileId,
        page: pageNumber,
        monitor: isChecked?.monitor,
        all_tag: isChecked?.all_tag,
      };
      await networkService(`docs/engagement-pages`, "POST", data);
    };
    if (isChecked?.monitor !== undefined || isChecked?.all_tag !== undefined) {
      handleTagSection();
    }
  }, [isChecked]);

  useEffect(() => {
    setDocTags(docFiles?.find((elem) => elem?.id == docFileId));
  }, [docId]);

  useEffect(() => {
    setIsChecked({
      monitor: docTags?.engagement_pages?.includes(currentPage),
      all_tag: docTags?.all_page_tags?.includes(currentPage),
    });
  }, [docTags]);

  const onScroll = () => {
    const wrapper = document.getElementById("parent_doc_wrapper");
    if (!wrapper) return;

    const pageHeight = wrapper.scrollHeight / numPages;
    const scrollPosition = wrapper.scrollTop + wrapper.clientHeight / 2;
    const newPage = Math.ceil(scrollPosition / pageHeight);
    if (newPage > 0 && newPage <= numPages) {
      setPageNumber(newPage);
    }
  };

  useEffect(() => {
    const wrapper = document.getElementById("parent_doc_wrapper");
    if (wrapper && numPages > 1) {
      wrapper.addEventListener("scroll", onScroll);
    }

    return () => {
      const wrapper = document.getElementById("parent_doc_wrapper");
      if (wrapper) {
        wrapper.removeEventListener("scroll", onScroll);
      }
    };
  }, [numPages]);

  return (
    <div
      className={`tag-section ${tagValue ? "move-right" : "move-left"}`}
    >
      <CustomTagsInput
        tags={docTags?.tags ? docTags?.tags[currentPage] : []}
        handleAddTags={handeAddTags}
        handleRemoveTags={handleRemoveTags}
        placeholderText="Add a page tag here"
      />
      <div className="toggle-group mt-3">
        <input
          type="checkbox"
          name="all_tag"
          value={isChecked?.all_tag}
          checked={!!isChecked?.all_tag}
          onChange={handleCheckboxChange}
        />
        <label className="ms-2">Include in engagement stats</label>
      </div>
      <div className="toggle-group">
        <input
          type="checkbox"
          name="monitor"
          value={isChecked?.monitor}
          checked={!!isChecked?.monitor}
          onChange={handleCheckboxChange}
        />
        <label className="ms-2">Apply to all language editions</label>
      </div>
    </div>
  );
};
export default TagEditorSection;
