import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';

import styles from './Paginate.module.css';

const Paginate = ({ currentPage, totalPages, onPageChange, totalData, itemTitle, hideText = null }) => {
    const handlePageClickEvent = (page) => {
        if (page.type === 'number') {
            if (page.value >= 1 && page.value <= totalPages) {
                onPageChange(page.value);
            }
        } else if (page.type === 'text' && page.value.toLowerCase() === 'first') {
            onPageChange(1);
        } else if (page.type === 'text' && page.value.toLowerCase() === 'last') {
            onPageChange(totalPages);
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push({ type: 'number', value: i });
            }
        } else {
            if (currentPage <= 3) {
                // Show first 5 pages
                for (let i = 1; i <= 5; i++) {
                    pageNumbers.push({ type: 'number', value: i });
                }
                pageNumbers.push({ type: 'ellipsis', value: '...' });
            } else if (currentPage > totalPages - 3) {
                // Show last 5 pages
                pageNumbers.push({ type: 'ellipsis', value: '...' });
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pageNumbers.push({ type: 'number', value: i });
                }
            } else {
                // Show current, previous 2, next 2, and ellipsis on both sides
                pageNumbers.push({ type: 'ellipsis', value: '...' });
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    pageNumbers.push({ type: 'number', value: i });
                }
                pageNumbers.push({ type: 'ellipsis', value: '...' });
            }
        }

        return [
            { type: 'text', value: 'First' },
            { type: 'icon', value: 'faLessThan' },
            ...pageNumbers,
            { type: 'icon', value: 'faGreaterThan' },
            { type: 'text', value: 'Last' }
        ];
    };

    const pages = generatePageNumbers();

    const paginationClassContainer = (page) => {
        let result = [];
        result.push(styles.paginationLink);
        if (page.value === currentPage) {
            result.push(styles.active);
        }
        if (page.type === 'icon') {
            if ((page.value === 'faLessThan' && currentPage === 1) || (page.value === 'faGreaterThan' && currentPage === totalPages)) {
                result.push(styles.disable);
            }
        } else if (page.type === 'text') {
            if ((page.value === 'First' && currentPage === 1) || (page.value === 'Last' && currentPage === totalPages)) {
                result.push(styles.disable);
            }
        }
        return result.join(' ');
    };

    return (
        <Container className={styles.paginationContainer + ' p-0 my-3'}>
            <Row className='mb-2 gx-0'>
                {/* <Col md={6} className='p-0'>
                    <ul className='d-flex mb-2'>
                        {
                            pages?.map(
                                (page, index) => (
                                    <li
                                        key={index}
                                        className={paginationClassContainer(page)}
                                        onClick={() => handlePageClickEvent(page)}
                                    >
                                        {
                                            page.type === 'icon' ?
                                                page.value === 'faLessThan' ?
                                                    <FontAwesomeIcon icon={faLessThan} /> :
                                                    <FontAwesomeIcon icon={faGreaterThan} />
                                                : page.type === 'ellipsis' ?
                                                    <span className={styles.ellipsis}>...</span>
                                                    :
                                                    page.value
                                        }
                                    </li>
                                )
                            )
                        }
                    </ul>
                </Col> */}
                {hideText !== true &&
                    <Col md={12} className={`${styles.dataResponse} p-0`}>
                        <p className="text-center">Search result <span> {totalData} </span> {itemTitle} {totalData > 1 ? "match" : "matches"} found</p>
                    </Col>}
            </Row>
        </Container>
    );
};

export default Paginate;