import {
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faDownload,
  faEllipsisVertical,
  faLessThan,
  faPencil,
  faRightFromBracket,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import landscape from "../../../../../../assets/images/landscape.png";
import portrait from "../../../../../../assets/images/portrait.png";
import {
  dispatchEvent,
  handleClearCookie,
  scrollPage,
} from "../../../../../utis/helper";
import LanguageDropdown from "../../../../CommonComponents/LanguageDropdown/LanguageDropdown";
import { networkService } from "../../../../config/axios";
import Button from "../../../../Elements/Button";
import CustomTooltip from "../../../../Elements/Tooltip";
import SearchInput from "../pdfEditorElements/SearchInput";

const EditorHeader = ({
  pdfFile,
  setOpenSideSection,
  openSideSection,
  setZoomIndex,
  zoomIndex,
  pageNumber,
  setPageNumber,
  docFiles,
  setSelectedPdf,
  title,
  downloadStatus,
  selectedPdf,
  tlb,
  editorStatus,
  setLaserLINKEditStatus,
  showToolbar,
  showEditBtn,
  currentUserId,
  isPdfPublished,
  setIsPdfPublished,
  showThumbnail,
  otherLanguageAvailable
}) => {
  const [langName, setLangName] = useState({
    img: "",
    name: "",
    class: "flag-icon flag-icon-us",
    groupId: "",
  });
  const [showSearch, setShowSearch] = useState(false);
  const [userProfileData, setUserProfileData] = useState({});
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const pageNumberRef = useRef(null);

  const zoomingOptions = window.objInit?.zoomOptions;
  const arrayOfElements = Object.values(
    Object.entries(docFiles).length !== 0 && docFiles
  );
  const selectedLanguage = arrayOfElements?.find(
    (elem) => elem.id == pdfFile?.id
  );

  const getClosestZoomValueAndIndex = (currentScale, zoomingOptions, isZoomIn) => {
    const zoomValues = Object.values(zoomingOptions).map(opt => parseFloat(opt.value));
    const sortedZooms = zoomValues.filter(v => !isNaN(v)).sort((a, b) => a - b);

    const newValue = isZoomIn
      ? sortedZooms.find(v => v > currentScale) || 4
      : [...sortedZooms].reverse().find(v => v < currentScale) || 0.25;

    const newIndex = Object.keys(zoomingOptions).findIndex(
      key => parseFloat(zoomingOptions[key].value) === newValue
    );

    return { newValue, newIndex };
  };

  const onScroll = () => {
    const input = document.getElementById("pageNumber");
    setCurrentPageNumber(parseInt(input?.value));
  };

  const handleZoom = (option) => {
    if (option === "in" && (zoomIndex.index < 8 || zoomIndex.index == 'page-height' || zoomIndex?.index == 'page-width')) {
      let newValue, newIndex;
      if (zoomIndex.index === 'page-height' || zoomIndex.index === 'page-width') {
        ({ newValue, newIndex } = getClosestZoomValueAndIndex(zoomIndex.value, zoomingOptions, true));
      } else {
        newIndex = zoomIndex.index + 1;
        newValue = zoomingOptions[Object.keys(zoomingOptions)[newIndex]].value;
      }
      setZoomIndex({
        ...zoomIndex,
        index: newIndex,
        value: newValue,
        oldValue: zoomIndex.value,
      });
    } else if (option === "out" && (zoomIndex.index > 0 && zoomIndex.index < 9 || zoomIndex.index == 'page-height' || zoomIndex?.index == 'page-width')) {
      let newValue, newIndex;
      if (zoomIndex.index === 'page-height' || zoomIndex.index === 'page-width') {
        ({ newValue, newIndex } = getClosestZoomValueAndIndex(zoomIndex.value, zoomingOptions, false));
      } else {
        newIndex = zoomIndex.index - 1;
        newValue = zoomingOptions[Object.keys(zoomingOptions)[newIndex]].value;
      }
      setZoomIndex({
        ...zoomIndex,
        index: newIndex,
        value: newValue,
        oldValue: zoomIndex.value,
      });
    } else if (option == "landscape") {
      setZoomIndex({
        ...zoomIndex,
        index: 'page-width',
        value: "2",
        oldValue: zoomIndex.value,
      });
    } else if (option == "portrait") {
      setZoomIndex({
        ...zoomIndex,
        index: 'page-height',
        value: "1",
        oldValue: zoomIndex.value,
      });
    }
  };

  const handleSelect = (value) => {
    setLangName({
      img: value?.language?.locale,
      class: value?.language?.class,
      name: value?.language?.name,
      groupId: value?.language?.group_id,
    });
    setSelectedPdf(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const num = parseInt(e.target.value, 10);
      dispatchEvent("change", "pageNumber");
      setPageNumber(num);
      scrollPage(num, "instant");
      e.preventDefault();
    }
  };

  const updateInputRefEvent = (valueToUpdate) => {
    if (pageNumberRef && pageNumberRef.current != null) {
      setTimeout(() => {
        if (pageNumberRef.current) pageNumberRef.current.value = valueToUpdate || 1;
      }, 400);
    }
  };

  const goBackToListing = () => {
    if (window.opener && !window.opener.closed) {
      window.opener.focus(); // Switch focus to the listing tab
      window.close(); // Close the editor tab
    } else {
      window.location.href = "/kdfs"; // Fallback if opener is not available
    }
  };

  useEffect(() => {
    updateInputRefEvent(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    setLangName({
      ...langName,
      groupId: docFiles[0]?.group_id,
    });
  }, [docFiles]);

  useEffect(() => {
    setIsPdfPublished(
      window.objInit &&
      window.objInit.live_files_count &&
      window.objInit.live_files_count[
      selectedPdf?.language?.locale ?? pdfFile?.language
      ] > 0
    );
  }, [selectedPdf]);

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await networkService(
        `profiles/${currentUserId}.json`,
        "GET"
      );
      setUserProfileData(response?.data);
    };
    if (!editorStatus) {
      setOpenSideSection(tlb?.show_thumbnail ?? true);
    }
    if (currentUserId) {
      fetchUserData();
    }
    document.getElementById("pageNumber").addEventListener("change", onScroll);
  }, []);

  return (
    <header
      className="header-editor"
      style={{ display: showToolbar === false ? "none" : "" }}
    >
      <div className={selectedPdf?.num_pages > 1 ? "h-first" : ""}>
        <div className="d-flex align-items-center">
          {editorStatus && (
            <div>
              <button
                className="back-button"
                // onClick={() => (window.location = "/kdfs")}
                onClick={goBackToListing}
              >
                <FontAwesomeIcon className="me-2" icon={faLessThan} />
                KDFs
              </button>
            </div>
          )}
          <>
            {showThumbnail && (
              <CustomTooltip
                title={<p className="p-1 text-light">Toggle Sidebar</p>}
                placement={"bottom"}
              >
                <div
                  className="ms-2 three-dots"
                  onClick={() => setOpenSideSection(!openSideSection)}
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} className="fs-4" />
                </div>
              </CustomTooltip>
            )}
            <div
              className={`${selectedPdf?.num_pages > 1
                ? "d-flex align-items-center  editor-inp"
                : "d-none"
                }`}
            >
              <input
                type="number"
                ref={pageNumberRef}
                className="edit-value-inp ms-1 me-1"
                id="pageNumber"
                min={1}
                max={selectedPdf?.num_pages}
                onKeyDown={handleKeyDown}
              />
              <p className="text-light">/ {selectedPdf?.num_pages}</p>
            </div>
          </>
        </div>
      </div>
      <div className="title edit-middle-h d-flex align-items-center justify-content-between px-2">
        {tlb?.cyoa_enabled != true && (
          <div className="h-first-search align-items-center">
            <SearchInput showSearch={showSearch} setShowSearch={setShowSearch} />
          </div>
        )}
        <p className="fs-6 text-ellipsis text-light editor-header-text">
          {title?.substring(0, 40)} {title.length > 40 ? "..." : ""}
        </p>
        <div className="d-flex align-items-center gap-2">
          {showEditBtn && (
            <Button
              text={
                <>
                  Edit KDF
                  <FontAwesomeIcon icon={faPencil} className="success ms-1" />
                </>
              }
              classList={"success"}
              addonClass={"mx-2"}
              onClick={() => (window.location = `/editor/${pdfFile?.doc_id}#page=${currentPageNumber}`)}
            />
          )}
          {editorStatus && isPdfPublished && (
            <div>
              <CustomTooltip
                title={<p className="p-1 text-light">Share</p>}
                placement={"bottom"}
              >
                <FontAwesomeIcon
                  className="fs-5 share-icon cursor-pointer"
                  icon={faShareFromSquare}
                  onClick={() => setLaserLINKEditStatus(true)}
                />
              </CustomTooltip>
            </div>
          )}
        </div>
      </div>
      <div className="edior-last-el">
        <div className="icons-editor">
          <CustomTooltip
            title={<p className="p-1 text-light">Zoom In</p>}
            placement={"bottom"}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              className="fs-4 me-2 cursor-pointer"
              onClick={() => handleZoom("in")}
              id="zoomIn"
            />
          </CustomTooltip>
          <CustomTooltip
            title={<p className="p-1 text-light">Zoom Out</p>}
            placement={"bottom"}
          >
            <FontAwesomeIcon
              icon={faCircleMinus}
              onClick={() => handleZoom("out")}
              className="fs-4 me-2 cursor-pointer"
              id="zoomOut"
            />
          </CustomTooltip>
          <CustomTooltip
            title={<p className="p-1 text-light">Full height</p>}
            placement={"bottom"}
          >
            <img
              src={portrait}
              alt="portrait"
              height="35px"
              width="30px"
              onClick={() => handleZoom("portrait")}
              className="me-2 cursor-pointer"
            />
          </CustomTooltip>
          <CustomTooltip
            title={<p className="p-1 text-light">Full width</p>}
            placement={"bottom"}
          >
            <img
              src={landscape}
              alt="landscape"
              height="30px"
              width="35px"
              onClick={() => handleZoom("landscape")}
              className="me-2 cursor-pointer"
            />
          </CustomTooltip>
          <NavDropdown
            title={
              <FontAwesomeIcon
                className="fs-4 cursor-pointer"
                icon={faCircleInfo}
              />
            }
            id="navbarScrollingDropdown"
            className="px-0 header-info-icons mt-1"
          >
            <NavDropdown.Item className="profile-drop">
              <div className="text-dark">
                KDF:{" "}
                <span
                  onClick={() =>
                    (window.location = `/kdfs/token/${langName?.groupId}`)
                  }
                >
                  {title}
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item
              className="profile-drop"
              download={selectedLanguage?.title ?? title}
              href={selectedPdf?.file?.url ?? selectedPdf?.url}
              id="download-pdf"
            >
              <div className="text-dark">
                PDF source:
                <span className="ms-1">{selectedLanguage?.title}</span>
              </div>
            </NavDropdown.Item>
          </NavDropdown>
        </div>
        <div className=" icons-editor header-flag-dd">
          {
            otherLanguageAvailable &&
            <LanguageDropdown
              arrayOfElements={arrayOfElements}
              name={langName}
              handleSelect={handleSelect}
              selectedLanguage={selectedLanguage?.language}
            />
          }
          {downloadStatus && (
            <a
              href={selectedPdf?.file?.url ?? selectedPdf?.url}
              download={docFiles?.title ?? title}
              id="download-pdf"
            >
              <FontAwesomeIcon icon={faDownload} className="ms-2" />
            </a>
          )}
        </div>
        {editorStatus && (
          <NavDropdown
            title={
              <>
                <img
                  className="user-imgs"
                  src={`https:${userProfileData?.image}`}
                  alt=""
                />
                <span className="mx-3 user-name-header"></span>
              </>
            }
            id="navbarScrollingDropdown"
            className="header-info-iconss"
          >
            <NavDropdown.Item className="profile-drop" href="/customizations">
              <FontAwesomeIcon icon={faCircleInfo} className="" />{" "}
              Customizations
            </NavDropdown.Item>
            <NavDropdown.Item
              className="profile-drop"
              onClick={handleClearCookie}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className="active-icon"
              />
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </div>
    </header>
  );
};

export default EditorHeader;
