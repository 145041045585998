import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { networkService } from "../../../config/axios";
import LaserlinkSessHeading from "../SessionComponents/LaserlinkSessHeading";
import SessionActiveTab from "../SessionComponents/SessionActiveTitle";
import SessionData from "../SessionComponents/SessionData";
import SessionHeading from "../SessionComponents/SessionHeading";
import SessionTable from "../SessionComponents/SessionTabls";
import StatPdfViewer from "../SessionComponents/StatPdfViewer";
import styles from "./Session.module.css";
import LoadingScreen from "../../../Elements/LoadingScreen/LoadingScreen";
import "../../../../../../app/assets/stylesheets/icomoon.css.scss";

const Session = () => {
  const [sessionData, setSessionData] = useState([]);
  const [showWithTags, setShowWithTags] = useState(false);
  const [selectedPage, setSelectedPage] = useState({ id: 1, index: 0 });
  const [sessionIndex, setSessionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const { hash } = useLocation();
  const {
    tl,
    doc,
    pdfFile: { thumbs_details },
  } = window.objInit;
  const pdfFile = window.objInit.pdfFile;
  const data = doc || tl;

  const updateReceiverInfo = async () => {
    setIsLoading(true)
    try {
      const response = await networkService(
        `/statistics/receivers?receiver=${hash.replace("#/session/", "") || ""
        }&token=${data?.token || data?.group_id}&type=${tl ? "tl" : "doc"}`,
        "GET"
      );
      if (response.data?.length > 0) {
        setIsLoading(false)
        setSessionData(response.data);
      }
    } catch (error) {
      console.error("error ", error);
    }
  };

  useEffect(() => {
    updateReceiverInfo();
  }, []);

  return (
    <>
      {isLoading ? <LoadingScreen /> :
        <>
          {sessionData?.map((viewDt, viewInd) => (
            <Container key={`${viewInd}-${viewDt?.optin_session?.id}`}>
              <Col md={11}>
                <Col md={8}>
                  <Row className="my-2">
                    <Col md={6}>
                      <LaserlinkSessHeading
                        viewDt={viewDt}
                        sessionIndex={sessionIndex}
                      />
                    </Col>
                    <Col md={6}>
                      <SessionData
                        viewDt={viewDt}
                        thumbs_details={thumbs_details}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Row className="mt-5">
                <Col md={8}>
                  <SessionActiveTab
                    data={viewDt}
                    sessionIndex={sessionIndex}
                    setSessionIndex={setSessionIndex}
                  />
                  {viewDt?.sessions?.filter((session, idx) => {
                    return idx == sessionIndex;
                  })?.map(
                    (session, idx) =>
                      <div key={idx}>
                          <div className={styles.statsViewerContainer}>
                            <SessionHeading
                              session={session}
                              thumbs_details={thumbs_details}
                              data={viewDt}
                            />
                            <div className="d-flex my-2">
                              <span
                                onClick={() => setShowWithTags((prev) => true)}
                                className="text-link-success text-white tag-primary mx-1"
                              >
                                Show pages without tags
                              </span>
                              <span
                                onClick={() => setShowWithTags((prev) => false)}
                                className="text-link-success text-white tag-primary mx-1"
                              >
                                Show all
                              </span>
                            </div>

                            <SessionTable
                              sessionIndex={idx}
                              setSelectedPage={setSelectedPage}
                              session={session}
                              selectedFilter={showWithTags ? 'not_tagged' : 'all'}
                              sessions={viewDt.sessions}
                              selectedPage={selectedPage}
                              thumbs_details={thumbs_details}
                              receiver={{
                                name: viewDt?.name,
                                email: viewDt?.email
                              }}
                            />
                          </div>
                        </div>
                  )}
                </Col>
                <Col md={4}>
                  <StatPdfViewer
                    pdfFile={pdfFile}
                    doc={doc}
                    tl={tl}
                    selectedPage={selectedPage}
                    viewerData={sessionData}
                  />
                </Col>
              </Row>
            </Container>
          ))}
        </>
      }
    </>
  );
};
export default Session;
