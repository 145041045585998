import React from "react"
import CustomTooltip from "../../../Elements/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { Col, Row } from "react-bootstrap"
import styles from '../Viewer/Viewer.module.css'
import { handleErrorImage } from "../../../../utis/helper"

const SessionHeading = ({ session, thumbs_details, data }) => {
    return (
        <>
            <Row
                className={`border-top align-items-center justify-content-around ${styles.statsTableHeader}`}
            >
                <Col xs="auto" className={styles.statItem}>
                    <CustomTooltip
                        title={
                            <p className="p-1 text-light">
                                Total time may vary by a few seconds
                            </p>
                        }
                    >
                        <span className={styles.label}>
                            Total Time: {session?.min_viewed}
                        </span>
                    </CustomTooltip>
                </Col>
                <Col xs="auto" className={styles.statItem}>
                    <span className={styles.label}>
                        Viewed: {session?.percent_viewed}
                    </span>
                </Col>
                <Col xs="auto" className={styles.statItem}>
                    <CustomTooltip
                        light={true}
                        title={
                            <div className="bg-whte p-2">
                                <img
                                    src={`${thumbs_details?.normal}${session?.pages_by_time_spent?.pages[0] - 1
                                        }.${thumbs_details?.extension}`}
                                    onError={(e) => handleErrorImage(e)}
                                    alt={session?.pages_by_time_spent?.pages[0]}
                                    width="99px"
                                    height="129px"
                                />
                                <span>
                                    Duration :{" "}
                                    {session?.pages_by_time_spent?.time_spent}
                                </span>
                            </div>
                        }
                    >
                        <span className={`${styles.label} cursor-help`}>
                            Top Page: {session?.pages_by_time_spent?.pages[0]}
                        </span>
                    </CustomTooltip>
                </Col>
                <Col xs="auto" className={styles.statItem}>
                    {session?.popular_exit_pages?.map((pg, ind) => (
                        <CustomTooltip
                            key={ind}
                            light={true}
                            title={
                                <div className="bg-whte p-2">
                                    <img
                                        src={`${thumbs_details?.normal}${pg?.page - 1
                                            }.${thumbs_details?.extension}`}
                                        onError={(e) => handleErrorImage(e)}
                                        alt={pg?.page}
                                        width="99px"
                                        height="129px"
                                    />
                                </div>
                            }
                        >
                            <span className={`${styles.label} cursor-help`}>
                                Exit Page: {pg?.page}
                            </span>
                        </CustomTooltip>
                    ))}
                </Col>
                <Col xs="auto" className={styles.statItem}>
                    <CustomTooltip
                        title={
                            <p className="p-1 text-light">
                                (Number of sessions with selected engagement
                                pages over the threshold * Number of engagement
                                pages over the threshold) / (Total number of
                                sessions * Total number of pages)
                            </p>
                        }
                    >
                        <span className={`${styles.label} cursor-help`}>
                            Score: {session?.engagement?.replace("%", "")}
                        </span>
                    </CustomTooltip>
                </Col>
                <Col xs="auto" className={styles.statItem}>
                    <CustomTooltip
                        light={true}
                        title={
                            <div className="bg-whte p-2">
                                <a
                                    href={`https://google.com/maps/place/${session?.ip_details?.location?.lat},${data?.optin_session?.ip_details?.location?.long}`}
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faLocationDot} />
                                    <img
                                        src={`${assetsBasePath}/${session?.ip_details?.country_code?.toLowerCase() ??
                                            "en"
                                            }.png`}
                                        alt={session?.ip_details?.country}
                                        width="22px"
                                        className="mr-1"
                                    />
                                    {session?.ip_details?.city ?? "-"},
                                    {session?.ip_details?.subdivision_code ?? "-"}
                                    ,{session?.ip_details?.country ?? "-"},
                                    {session?.ip_details?.continent_code ?? "-"}
                                </a>
                            </div>
                        }
                    >
                        <p className={`${styles.label} cursor-pointer`}>
                            <FontAwesomeIcon icon={faLocationDot} />
                        </p>
                    </CustomTooltip>
                </Col>
                <Col xs="auto" className={styles.icons}>
                    <CustomTooltip
                        title={
                            <p className="p-1 text-light">
                                {session?.browser_info?.browser}
                            </p>
                        }
                    >
                        <img
                            className="d-inline-flex"
                            src={`/browser/${session?.browser_info?.browser_icon?.toLowerCase()}`}
                        />
                    </CustomTooltip>
                    <CustomTooltip
                        title={
                            <p className="p-1 text-light">
                                {session?.browser_info?.os_family}
                            </p>
                        }
                    >
                        <img
                            className="d-inline-flex"
                            src={`/os/${session?.browser_info?.os_icon}`}
                        />
                    </CustomTooltip>
                    <CustomTooltip
                        title={
                            <p className="p-1 text-light">
                                {session?.browser_info?.device}
                            </p>
                        }
                    >
                        <img
                            className="d-inline-flex"
                            src={`/device/${session?.browser_info?.device_icon}`}
                        />
                    </CustomTooltip>
                </Col>
            </Row>
        </>
    )
}
export default SessionHeading