import { faCopy, faGear, faLayerGroup, faQuestion, faRectangleXmark, faShareFromSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import utilsUploadFormData from "../../../utis/upoadFormData";
import CustomTagsInput from "../../CommonComponents/Tags/CustomTagsInput";
import CustomTooltip from "../../Elements/Tooltip";
import styles from "./linkCard.module.css";
import { networkService } from '../../config/axios';
import UserInfoContext from '../../../utis/userInfoContext';

const LinkCard = ({ data, trigerCopyLaserLinkEvent, triggerSettingsLaserLinkEvent, triggerDeleteLaserLinkEvent, updateLinkData, triggerUdateRecord }) => {
    const [kdfsTagsEditStatus, setKdfsTagsEditStatus] = useState(false);
    const [getCopyTextStatus, setCopyTextStatus] = useState(false);
    const [editableTitle, setEditableTitle] = useState({ status: false, value: data?.name, id: data?.id, docId: data?.doc?.id });
    const editableRef = useRef(null);
    const { setShowNotifications } = useContext(UserInfoContext);

    const handleAddKDFTagsEvent = async (value) => {
        if (value.length > data?.tags.length) {
            let updateTagsToDoc = await utilsUploadFormData(
                `tags.json`,
                { name: value[value.length - 1] },
                "POST"
            );
            if (updateTagsToDoc?.data != undefined) {
                let vaueToUpdate = [
                    ...data?.tags?.map((item) => item.id),
                    updateTagsToDoc.data.id,
                ];
                updateLinkData(data, vaueToUpdate);
            }
        } else {
            updateLinkData(
                data,
                data?.tags
                    .filter((item) => value.includes(item.name))
                    ?.map((item) => item.id)
            );
        }
    };

    const handleCopyTextEvent = async (value) => {
        await navigator.clipboard.writeText(value);
        let timer = setTimeout(() => {
            setCopyTextStatus(false);
            timer = null;
        }, 3000);
        setCopyTextStatus(true);
    };

    const handleUpdateEditableTitleEvent = (e) => {
        setEditableTitle((prev) => ({ ...prev, value: e.target.value }));
    };

    useEffect(() => {
        setCopyTextStatus(false);
    }, []);

    const handleOutsideOrEnter = async () => {
        try {
            if (editableRef.current != null) {
                const { data, status } = await networkService(`links/${editableTitle?.id}.json`, 'PATCH',
                    {
                        doc_id: editableTitle?.docId,
                        id: editableTitle?.id,
                        name: editableRef?.current?.value,
                    });
                if (status == 200) {
                    setEditableTitle((prev) => ({ ...prev, status: false }))
                    triggerUdateRecord();
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: 'Changes Saved',
                    })
                }
            }
        } catch (error) {
            if (error.response.status == 422) {
                setEditableTitle((prev) => ({ ...prev, status: false, value: data?.title }))
                setShowNotifications({
                    showToast: true,
                    toastType: "danger",
                    toastMessage: 'Sorry, Laser Link title must be unique. This title already exists.',
                })
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editableRef.current && !editableRef.current.contains(event.target)) {
                handleOutsideOrEnter();
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                handleOutsideOrEnter();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <Row className={styles.linkWrapper}>
            <Col md={9}>
                <Row className={styles.linkHeader}>
                    <Col md={10}>
                        <div className={styles.docTitle}>
                            <CustomTooltip title={<p className="p-1 text-light">{data?.template ? 'Template' : 'Individual'} LaserLINK</p>}>
                                {data?.template ?
                                    <FontAwesomeIcon icon={faLayerGroup} />
                                    :
                                    <div className={data?.language_flag?.class} style={{
                                        width: "22px",
                                    }}></div>
                                }
                            </CustomTooltip>
                            {editableTitle.status ?
                                <input
                                    type="text"
                                    value={editableTitle?.value}
                                    ref={editableRef}
                                    onChange={(e) => handleUpdateEditableTitleEvent(e)} />
                                :
                                <strong onClick={() => setEditableTitle((prev) => ({ ...prev, status: true }))}>{data?.name}</strong>}
                        </div>
                    </Col>
                    <Col md={2} className={`d-flex justify-content-between align-items-center ${styles.actionWrapper}`}>
                        <CustomTooltip title={<p className="p-1 text-light">Clone this LaserLINK</p>}>
                            <FontAwesomeIcon icon={faCopy} onClick={() => trigerCopyLaserLinkEvent(data.id)} className="cursor-pointer fs-6" />
                        </CustomTooltip>
                        <CustomTooltip title={<p className="p-1 text-light">Settings</p>} >
                            <FontAwesomeIcon icon={faGear} className="cursor-pointer fs-6" onClick={() => triggerSettingsLaserLinkEvent(data)} />
                        </CustomTooltip>
                        <CustomTooltip title={<p className="p-1 text-light">Remove LaserLINK</p>} >
                            <FontAwesomeIcon icon={faTrashCan} className="cursor-pointer fs-6" onClick={() => triggerDeleteLaserLinkEvent(data.id)} />
                        </CustomTooltip>
                    </Col>
                </Row>
                <Row id="link-list-top" className={styles.linkBody}>
                    <Col md={10}>
                        <div className={`${styles.docContent} my-1`}>
                            <CustomTooltip title={<p className="p-1 text-light">Display all LaserLINX for this KDF</p>}>
                                <span className="text-link-success">KDF: </span>
                            </CustomTooltip>
                            <CustomTooltip title={<p className="p-1 text-light">KDF Settings</p>}>
                                <a href={`/kdfs/${data?.doc?.id}`} className="text-link-success"> {data?.doc?.title}</a>
                            </CustomTooltip>
                        </div>
                        <div className={`${styles.docContent} my-1`}>
                            <div className="d-flex">
                                <span>ET: </span>
                                <CustomTooltip title={<p className="p-1 text-light">Engagement Threshold</p>}>
                                    <span>{data?.doc?.page_viewed_sec ?? 0} secs.</span>
                                </CustomTooltip>
                                {
                                    data?.domain && <CustomTooltip title={<p className="p-1 text-light">Your chosen domain aims to enhance recipient trust and engagement.</p>}>
                                        <p className="color-blue mx-1">{data?.domain ?? ' '}</p>
                                    </CustomTooltip>
                                }
                                {
                                    data?.has_optin &&
                                    <CustomTooltip title={<p className="p-1 text-light">{data?.tooltip_expire_time}</p>}>
                                        <span>Access Gate</span>
                                    </CustomTooltip>
                                }
                            </div>
                            <CustomTooltip title={<p className="p-1 text-light">Your chosen domain aims to ehance recipient trust and engagement.</p>}>
                                <strong className="text-link-primary"> {data?.doc?.domain}</strong>
                            </CustomTooltip>
                        </div>
                        <div className={`${styles.docContent} my-1`}>
                            <span className="">Created by: </span>
                            <CustomTooltip title={<p className="p-1 text-light">{data?.date_created ?? '-'}</p>}>
                                <strong> {data?.username}</strong>
                            </CustomTooltip>
                        </div>
                        {
                            data?.receivers?.length > 0 &&
                            <div className={`${styles.docContent} my-1`}>
                                <span className="">Recipient: </span>
                                <CustomTooltip title={<p className="p-1 text-light">{data?.receivers[0]?.email ?? '-'}</p>}>
                                    <a href={`/tl-stats/${data.token}#/views`}>
                                        <strong> {data?.receivers[0]?.name} {data?.receivers?.length > 1 && `(and ${data?.receivers?.length - 1} more)`} </strong>
                                    </a>
                                </CustomTooltip>
                            </div>
                        }
                        <div className="custom-tag-wrapper kdf-settings d-flex" onClick={() => !kdfsTagsEditStatus && setKdfsTagsEditStatus(true)}>
                            Tags: {
                                kdfsTagsEditStatus ?
                                    // <CustomTags 
                                    //     updateValue={handleAddKDFTagsEvent}
                                    //     tags={data?.tags}
                                    //     handleCancelEvent={(status) => setKdfsTagsEditStatus(!status)}
                                    //     dataPlaceholder="Add a new tag..."
                                    //     addOnClass="w-100"
                                    // />
                                    <>
                                        <CustomTagsInput
                                            tags={data?.tags || []}
                                            placeholderText="Add a new tag..."
                                            handleAddTags={(value) => {
                                                handleAddKDFTagsEvent([...data?.tags || [], value]);
                                                setKdfsTagsEditStatus(false);
                                            }}
                                            handleRemoveTags={(value) => {
                                                handleAddKDFTagsEvent([...data?.tags?.filter(it => it != value)]);
                                                setKdfsTagsEditStatus(false);
                                            }}
                                        />
                                        <CustomTooltip title={<p className="p-1 text-light">You can add a tag by pressing enter, tab, delimiting with comma or clicking outside the tags area</p>}>
                                            <FontAwesomeIcon icon={faQuestion} className="custom-tag-icon mx-2" />
                                        </CustomTooltip>
                                        <FontAwesomeIcon
                                            icon={faRectangleXmark}
                                            onClick={() => setKdfsTagsEditStatus(false)}
                                            className="cancel mx-2 edit-icon"
                                        />
                                    </>
                                    :
                                    (
                                        data?.tags?.length > 0 ?
                                            data?.tags?.map((tag, tagInd) => <span key={tagInd} className="tag-primary text-white mx-1 rounded">{tag.name}</span>)
                                            :
                                            <span className="d-block custom-tag-element mb-0 ms-1">Add KDF Tags here</span>
                                    )
                            }
                        </div>
                    </Col>
                    <Col md={2} className="laserlink-img-list">
                        <img src={data?.thumb || data?.thumbnail} alt={data?.name} width="100%" />
                    </Col>
                </Row>
            </Col>
            <Col md={2} className="text-center">
                {!data?.template
                    ?
                    <>
                        <a href={`/tl-stats/${data?.token}#/views`} className={styles.statsButton}>
                            <strong className="d-block">{data?.stats?.viewers}</strong>
                            <span>Visits</span>
                        </a>
                        {
                            data?.stats?.optins?.length > 0 &&
                            <a href={`/tl-stats/${data?.token}#/views`} className={styles.statsButton}>
                                <strong className="d-block">{data?.stats?.optins?.length}</strong>
                                <span>Opt-in{data?.stats?.optins?.length > 1 && 's'}</span>
                            </a>
                        }
                        <CustomTooltip title={<p className="p-1 text-light">{data?.link_total_sec_viewed}</p>}>
                            <a href={`/tl-stats/${data?.token}#/at-a-glance`} className={styles.statsButton}>
                                <strong className="d-block">{data?.stats?.percent_viewed}</strong>
                                <span>Viewed</span>
                            </a>
                        </CustomTooltip>
                        {data?.last_viewed && <a href={`/tl-stats/${data.token}#/at-a-glance`} className={styles.statsButton}>
                            <strong className="d-block">{data?.last_viewed}</strong>
                            <span>Last Viewed</span>
                        </a>}
                    </>
                    : ''
                }
            </Col>
            <Col md={1}>
                {
                    !data?.template &&
                    <CustomTooltip title={<p className="p-1 text-light">Share</p>}>
                        {/* <FontAwesomeIcon icon={faShareFromSquare} className="active" /> */}
                        <DropdownButton
                            id={`dropdown-button-drop-${'up'}`}
                            drop={'up'}
                            variant="none"
                            title={<FontAwesomeIcon icon={faShareFromSquare} className="active" />}
                            className="shareButtonIcon"
                        >
                            <Dropdown.Item
                                href={`https://www.facebook.com/sharer/sharer.php?u=${data?.tracking_link}`}
                                target="_blank"
                            >
                                {/* <FontAwesomeIcon icon={faFacebookF} />  */}
                                Facebook
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={`https://twitter.com/intent/tweet?text=${data?.tracking_link}`}
                                target="_blank"
                            >
                                {/* <FontAwesomeIcon icon={faTwitter} />  */}
                                Twitter
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={`mailto:?subject=Check%20this%20out&body=${data?.tracking_link}`}
                                target="_blank"
                            >
                                {/* <FontAwesomeIcon icon={faEnvelope} />  */}
                                Email
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={`https://www.linkedin.com/shareArticle?mini=true&url=https://powered-by.kinetizine.com/MTczMTkwODAwOA/en-US&source=LinkedIn`}
                                target="_blank"
                            >
                                {/* <FontAwesomeIcon icon={faLinkedin} /> */}
                                Linkdin
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    handleCopyTextEvent(data?.tracking_link ?? '')
                                }
                            >
                                {getCopyTextStatus ? 'Link Copied!' : 'Copy Link'}
                            </Dropdown.Item>
                        </DropdownButton>
                    </CustomTooltip>}
            </Col>
        </Row>
        // </Container>
    );
}
export default LinkCard;
