import React, { useEffect, useState, useRef, useContext } from "react";
import "./ElementOrder.css";
import Image from "../../../../CommonComponents/Image";
import HtmlText from "../../../../CommonComponents/HtmlText";
import IFrame from "../../../../CommonComponents/IFrame";
import { networkService } from "../../../../config/axios";
import { csrfToken } from "../../../../../utis/csrfTokenUtils";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";

const ElementOrder = ({ docFileId, setHoveredId }) => {
    const [showElementOrder, setShowElementOrder] = useState(false);
    const [dataOrder, setDataOrder] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const onScroll = (e) => {
        const value = e.target.value;
        setPageNumber(parseInt(value));
    };

    const dragIndexRef = useRef(null);
    const { elements, setElements } = useContext(EditorInfoContext);

    const handleDragStart = (e, index) => {
        dragIndexRef.current = index;
        e.dataTransfer.setData("draggedIndex", index);
        e.target.style.opacity = "0.5";
        e.target.classList.add("dragging");
    };

    const handleDragOver = (e, targetIndex) => {
        e.preventDefault();
        e.target.classList.add("drop-target");
        const draggedIndex = dragIndexRef.current;
        if (draggedIndex === targetIndex) return;
        const updatedOrder = [...dataOrder];
        const draggedItem = updatedOrder.splice(draggedIndex, 1)[0];
        updatedOrder.splice(targetIndex, 0, draggedItem);
        dragIndexRef.current = targetIndex;
        setDataOrder(updatedOrder);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.target.classList.remove("drop-target");
        const updatedOrder = dataOrder.map((element, idx) => ({
            ...element,
            order_on_page: idx + 1,
        }));

        setDataOrder(updatedOrder);
        setElements(updatedOrder?.reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
        }, {}))

        const data = {
            authenticity_token: csrfToken(),
            elements: updatedOrder.map((element, idx) => ({
                id: element?.id,
                order_on_page: idx + 1,
            })),
        };

        await networkService("docs/set-order-on-page", "POST", data)
    };

    const handleDragEnd = (e) => {
        e.target.classList.remove("dragging");
        e.target.style.opacity = "1";
        dragIndexRef.current = null;
    };


    useEffect(() => {
        let sortedData = Object.values(Object.entries(elements).length !== 0 && elements)
        setDataOrder(sortedData?.sort((a, b) => a.order_on_page - b.order_on_page));
    }, [elements]);

    useEffect(() => {
        document.getElementById("pageNumber").addEventListener("change", onScroll);
    }, []);

    const renderElements = (element) => {
        switch (element?.element_type_name) {
            case "Image":
                return (
                    <Image
                        id={"image" + element?.id}
                        src={element?.file_url ?? element?.value?.gif_link}
                        alt="Uploaded image"
                        style={{
                            width: "100%",
                            height: "100%",
                            ...(element?.file_url && { objectFit: "cover" }),
                        }}
                    />
                );
            case "Video":
                return (
                    <IFrame
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                        scrolling={element?.value?.no_scroll ? "no" : "yes"}
                        src={element?.value?.video_link}
                        title="url"
                        id={element?.id}
                    />
                );
            case "Web URL":
                return (
                    <IFrame
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                        scrolling={element?.value?.no_scroll ? "no" : "yes"}
                        src={element?.value?.video_link}
                        title="url"
                        id={element?.id}
                    />
                );
            case "HTML Text":
                return <HtmlText htmlContent={element?.value?.html_text} />;
            default:
                return <></>;
        }
    };

    return (
        <div
            className={`${showElementOrder ? "elem-move-left" : "elem-move-right"
                } element-order-main`}
        >
            <div
                className="element-order-sidebar"
                onClick={() => setShowElementOrder(!showElementOrder)}
            >
                <p>Element Order</p>
            </div>
            <div className="inner-element-order">
                {dataOrder &&
                    dataOrder.map((element, idx) => (
                        docFileId === element?.docs_file_id &&
                        element?.geometry?.top !== null &&
                        element?.geometry?.left !== null &&
                        element?.geometry?.height !== null &&
                        element?.geometry?.width !== null &&
                        pageNumber == element?.page && (
                            <div
                                className="element-img-order"
                                key={idx}
                                draggable
                                onDragStart={(e) => handleDragStart(e, idx)}
                                onDragEnd={handleDragEnd}
                                onDragOver={(e) => handleDragOver(e, idx)}
                                onDrop={handleDrop}
                                style={{
                                    ...(element?.value?.bg_color && { background: element?.value?.bg_color })
                                }}
                                onMouseEnter={() => setHoveredId(element?.id)} // Set hovered index on hover
                                onMouseLeave={() => setHoveredId(null)}
                            >
                                {renderElements(element)}
                            </div>
                        )
                    ))}
            </div>
        </div >
    );
};

export default ElementOrder;