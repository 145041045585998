import React, { useEffect, useState } from "react";
import {
  animations,
  disappear,
  entranceLoop,
} from "../../../../../../../hooks/utils";
import CustomTooltip from "../../../../../../Elements/Tooltip";

const AnimationSection = ({ tool, handleChange }) => {
  const [iterationCount, setIterationCount] = useState(1);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    const frequency = tool?.value?.frequency;
    const count =
      frequency === 'loop5Seconds'
        ? 5
        : frequency === 'loop10Seconds'
          ? 10
          : frequency === 'loopAnimation'
            ? 'infinite'
            : 1;

    setIterationCount(count);
    setAnimationKey((prevKey) => prevKey + 1);
  }, [tool?.value?.frequency]);

  return (
    <div className="animation-select">
      <div className="animation-card">
        <div className="animation-title">
          <p>TIMED ANIMATIONS FOR ELEMENT {tool?.id}</p>
        </div>
      </div>
      <div className="animation-card">
        <div className="animation-title">
          <p>APPEAR AFTER:</p>
        </div>
        <input
          type="number"
          className="input-field"
          value={tool?.sec_to_delay}
          name="sec_to_delay"
          onChange={handleChange}
          min={0}
          onFocus={(e) => e.target.select()}
        />
        <CustomTooltip title={<p className="p-1 text-light">This is a simple preview of the animation you have selected.</p>}>
          <div
            className={`color-picker-box animate__animated animate__${tool?.value?.disappear?.length > 0
              ? tool?.value?.disappear
              : tool?.value?.animation
              }`}
            key={animationKey}
            style={{
              animationIterationCount: iterationCount,
            }}
          ></div>
        </CustomTooltip>
        <div className="animation-title">
          <p>DISAPPEAR AFTER:</p>
        </div>
        <input
          type="number"
          className="input-field"
          name="disappear_after"
          value={tool?.disappear_after}
          onChange={handleChange}
          min={0}
          onFocus={(e) => e.target.select()}
        />
      </div>

      <div className="animation-card">
        <select
          className="animation-selector ent-animation"
          name="animation"
          value={tool?.value?.animation}
          onChange={handleChange}
        >
          <option value="">Entrance Animations</option>
          {Object.entries(animations()?.animations).map(
            ([category, animationArray]) => (
              <optgroup key={category} label={category.replace(/_/g, " ")}>
                {animationArray.map((animation) => (
                  <option key={animation.value} value={animation?.value}>{animation.key}</option>
                ))}
              </optgroup>
            )
          )}
        </select>

        {tool?.value?.animation && (
          <select
            className="animation-selector"
            name="frequency"
            onChange={handleChange}
            value={tool?.value?.frequency}
          >
            <option value="">Entrance Loops</option>
            {entranceLoop()?.entrance_loop?.map((animation) => (
              <option key={animation?.value} value={animation?.value}>{animation?.key}</option>
            ))}
          </select>
        )}

        {tool?.disappear_after > 0 && (
          <select
            className="animation-selector"
            name="disappear"
            onChange={handleChange}
            value={tool?.value?.disappear}
          >
            <option value="">Disappear After</option>
            {Object.entries(disappear()?.disappear).map(
              ([category, animationArray]) => (
                <optgroup key={category} label={category.replace(/_/g, " ")}>
                  {animationArray.map((animation) => (
                    <option key={animation?.value} value={animation?.value} >
                      {animation?.key}
                    </option>
                  ))}
                </optgroup>
              )
            )}
          </select>
        )}
      </div>

      <div className="animation-card viewport-main">
        <div className="d-flex align-items-center cursor-pointer">
          <input
            type="radio"
            className="toggle-button"
            name="timerField"
            onChange={handleChange}
            value="ViewPort"
            id='ViewPort'
            checked={tool?.value?.timerField === "ViewPort"}
          />
          <label htmlFor="ViewPort" className="img-lab-clr">Whenever in viewport</label>
        </div>
        <div className="d-flex align-items-center cursor-pointer">
          <input
            type="radio"
            className="toggle-button ms-3"
            onChange={handleChange}
            name="timerField"
            value="OncePerVisit"
            id='OncePerVisit'
            checked={tool?.value?.timerField === "OncePerVisit"}
          />
          <label htmlFor="OncePerVisit" className="img-lab-clr">Once Per visit</label>
        </div>
      </div>
    </div>
  );
};
export default AnimationSection;
