import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./GifSections.css";
import apiGETRequest from "../../../../../../../../utis/apiRequest";
import Button from "../../../../../../../Elements/Button";
import CustomTooltip from "../../../../../../../Elements/Tooltip";
import { debounce } from "../../../../../../../../hooks/utils";

const GifSection = ({ tool, handleSelectGifs, activeTab }) => {
  const [gifData, setGifData] = useState([]);
  const [search, setSearch] = useState("");
  const [isGif, setIsGif] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gifDimensions, setGifDimensions] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false)
  const inputRef = useRef(null);

  const handleGifChange = (status) => {
    setButtonClicked(true)
    setIsGif(status);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const fetchGifsTenor = useCallback(
    debounce(async (searchQuery, isGifParam) => {
      try {
        setLoading(true);
        const gifList = await apiGETRequest(
          `editor/get-gifs/${searchQuery !== "" ? searchQuery : "excited"}/${isGifParam}`
        );
        setGifData(gifList?.data);
      } catch (error) {
        console.error("Error fetching gifs:", error);
      }
      setLoading(false);
      setTimeout(() => inputRef.current?.focus(), 0);
    }, 1000),
    []
  );

  useEffect(() => {
    fetchGifsTenor(search, isGif);
  }, [search, isGif, fetchGifsTenor]);

  useEffect(() => {
    const fetchGifDimensions = async () => {
      const dimensions = {};
      const promises = gifData.map((gif) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            dimensions[gif] = { width: img.naturalWidth, height: img.naturalHeight };
            resolve();
          };
          img.onerror = () => resolve();
          img.src = gif;
        });
      });

      await Promise.all(promises);
      setGifDimensions(dimensions);
    };

    if (gifData.length) {
      inputRef.current?.focus();
      fetchGifDimensions()
    }
  }, [gifData]);

  useLayoutEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 0);
  }, [activeTab]);

  return (
    <div className="GIFs-main">
      <div className="Gif-inp mb-2 d-flex justify-content-between align-items-center">
        <input
          ref={inputRef}
          placeholder="Search GIFs via GIPHY"
          onChange={(e) => setSearch(e?.target?.value)}
          value={search}
          className="center-placeholder"
        />
        <Button
          classList={!isGif ? 'primary' : "primary1"}
          text="Stickers"
          addonClass="px-2"
          onClick={() => handleGifChange(false)}
        />
        <Button
          classList={isGif ? 'primary' : "primary1"}
          text="GIFs"
          addonClass="px-2"
          onClick={() => handleGifChange(true)}
        />
      </div>
      {(!tool?.id || tool?.gif_type !== 'sticker' || (tool?.id && search?.length > 0) || buttonClicked) && gifData?.length > 0 ? (
        <div className="gifs-items">
          <div className="row flex-wrap gy-3">
            {loading ? (
              <div className="p-3">Loading....</div>
            ) : (
              <>
                {gifData?.map((gif, index) => {
                  const dimensions = gifDimensions[gif];
                  return (
                    <CustomTooltip
                      title={
                        dimensions ? (
                          <>
                            <span className="d-block" >Image Dimensions:</span>
                            <span className="d-block">
                              Height: {dimensions?.height}px, Width:{" "}
                              {dimensions?.width}px
                            </span>
                          </>
                        ) : (
                          "Loading dimensions..."
                        )
                      }
                      key={index}
                      placement={"top"}
                      light={true}
                    >
                      <div
                        className="col-4"
                        onClick={() => {
                          handleSelectGifs("sticker", gif, dimensions);
                          setGifData([]);
                        }}
                      >
                        <img
                          style={{ height: "160px", width: "160px" }}
                          src={gif}
                          alt="gif link"
                        />
                      </div>
                    </CustomTooltip>
                  );
                })}
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {tool?.gif_type &&
        tool?.gif_type === "sticker" &&
        tool?.value?.gif_link && (
          <img
            src={tool?.value?.gif_link}
            alt="gif link"
            style={{ height: "160px", width: "160px", opacity: `${tool?.transparency ?? 10}` }}
          />
        )}
    </div>
  );
};

export default GifSection;
