import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PageByPage from "./PageByPage";
import AtAGlance from "./AtAGlance";
import Views from "./Views";
import Viewers from "./Viewers";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import CustomTooltip from "../../Elements/Tooltip";
import LaserLinkModal from "../../Elements/LaserLink/LaserLinkModal";
import Viewer from "./Viewer/Viewer";
import Session from "./Session/Session";

const RenderComponent = () => {
  const { pathname, hash } = useLocation();
  const { tl, doc } = window?.objInit;

  if (hash.includes("/page-by-page")) {
    return <PageByPage />;
  } else if (hash.includes("/at-a-glance")) {
    return <AtAGlance />;
  } else if (hash.includes("/views")) {
    return <Views />;
  } else if (hash.includes("/viewers")) {
    return <Viewers />;
  } else if (hash.includes("/viewer/")) {
    return <Viewer />;
  } else if (hash.includes("/session/")) {
    return <Session />;
  } else {
    if (pathname.includes(tl?.token || doc?.group_id)) {
      window.location.href = `/${pathname?.split("/")[1]}/${tl?.token || doc?.group_id
        }#/at-a-glance`;
    }
    return <>Nothing Found {JSON.stringify(pathname)}</>;
  }
};

const StatsView = ({ time_zone_list }) => {
  const [selectedKDFLink, setSelectedKDFLink] = useState(null);
  const { tl, pdfFile, doc } = window?.objInit;
  const data = tl || doc;
  const { hash, pathname } = useLocation();
  const isKdf = pathname.includes("kdf-stats");

  const handleSelect = (token) => {
    if (token) {
      window.location.href = `/tl-stats/${token}#/views`;
    }
  };

  return (
    <>
      <LaserLinkModal
        laserItem={selectedKDFLink}
        time_zone_list={time_zone_list}
        updateSelectedKDFLink={setSelectedKDFLink}
      />
      <Container
        className={`bg-light-container ${hash.includes("/page-by-page") ? "page-by-page-pading" : ""
          }`}
      >
        <Row className="laser-visit mb-3">
          <Col md={6}>
            <div className="mb-2">
              {isKdf ? (
                <>
                  <span className="custom-link-success cursor-pointer me-2">
                    KDF:{" "}
                    <div
                      style={{
                        width: "22px",
                        marginRight: '5px'
                      }}
                      className={`mr-1 ${data?.doc_files_languages?.filter(
                        (language) => language.status === "active"
                      )[0]?.language?.class
                        }`}
                    ></div>
                    <CustomTooltip
                      title={<p className="p-1 text-light">KDF Settings</p>}
                    >
                      <a href={`/kdfs/${doc?.id}`} target="_blank">
                        {doc?.title ?? "-"}
                      </a>
                    </CustomTooltip>
                  </span>
                  {data?.tracking_links?.length > 0 &&
                    data?.tracking_links?.length < 2 ? (
                    <CustomTooltip
                      title={
                        <p className="p-1 text-light">LaserLINK Insights</p>
                      }
                    >
                      <span className="custom-link-success cursor-pointer">
                        LaserLINK:{" "}
                        <div
                          style={{
                            width: "22px",
                            marginRight: '5px'
                          }}
                          className={`mr-1 ${data?.tracking_links[0].language_flag?.class}`}
                        ></div>
                        <a
                          href={`https://${data.tracking_links[0].domain}/${data.tracking_links[0].token}/${data.tracking_links[0].language_flag.locale}`}
                          target="_blank"
                        >
                          {data?.tracking_links[0]?.name || ""}
                        </a>
                      </span>
                    </CustomTooltip>
                  ) : (
                    <></>
                  )}
                  <p className="mt-1">
                    <span>Total Pages: </span>
                    {pdfFile?.num_pages ?? "-"}
                  </p>
                </>
              ) : (
                <CustomTooltip
                  title={
                    <p className="p-1 text-light">LaserLINK Settings</p>
                  }
                >
                  <span className="cursor-pointer">
                    LaserLINK:{" "}
                    <span
                      style={{
                        width: "22px",
                        marginRight: '5px'
                      }}
                      className={`${data?.language?.class}`}
                    ></span>
                    <span className="custom-link-success" onClick={() => setSelectedKDFLink(data)}>
                      {data?.name || ""}
                    </span>
                  </span>
                </CustomTooltip>
              )}
            </div>
            {
              !isKdf &&
              <div>
                <span>
                  {(data?.tracking_link?.landing_page ||
                    data?.landing_page) &&
                    pdfFile.num_pages > 1 && (
                      <span style={{ color: "#787979" }} className="me-2">
                        LP={data?.tracking_link?.landing_page ?? data?.landing_page}
                      </span>
                    )}
                  {(data?.tracking_link?.disable_settings?.disable_for ||
                    data?.disable_settings?.disable_for) && (
                      <span style={{ color: "#787979" }} className="me-2">
                        SD=
                        {data?.tracking_link?.disable_settings?.disable_for ?? data?.disable_settings?.disable_for}
                      </span>
                    )}
                  {data?.opt_in?.show_on_page && (
                    <span style={{ color: "#787979" }} className="me-2">
                      OI={data?.opt_in?.show_on_page}
                    </span>
                  )}
                </span>
                {(data?.domain || data?.tracking_link?.domain) && (
                  <span>
                    Domain:{" "}
                    {(data?.domain || data?.tracking_link?.domain) ?? "-"}
                  </span>
                )}
                {data?.tags?.length > 0
                  ? data?.tags?.map((tag, tagIndex) => (
                    <span
                      key={`${tag?.id}`}
                      className="tag-primary text-white"
                    >
                      {tag?.name || "-"}
                    </span>
                  ))
                  : ""}
              </div>
            }
            {data?.page_viewed_sec != null &&
              (hash.includes("/page-by-page") ||
                hash.includes("/at-a-glance") ||
                hash.includes("/viewer/")) ? (
              <div>
                <CustomTooltip
                  title={
                    <p className="p-1 text-light">
                      Display recipients who actively viewed this KDF for at
                      least this amount of time per page.
                    </p>
                  }
                >
                  <span>Engagement Threshold: </span>
                </CustomTooltip>
                <CustomTooltip
                  title={
                    <p className="p-1 text-light">
                      Display recipients who actively viewed this KDF for at
                      least this amount of time per page.
                    </p>
                  }
                >
                  <span>{data?.page_viewed_sec} Seconds</span>
                </CustomTooltip>
              </div>
            ) : (
              ""
            )}
          </Col>
          <Col md={6}>
            {!isKdf ? (
              <>
                <div>
                  <CustomTooltip
                    title={
                      <p className="p-1 text-light">
                        Display all LaserLINX for this KDF
                      </p>
                    }
                  >
                    <span className="custom-link-success cursor-pointer">
                      KDF:{" "}
                    </span>
                  </CustomTooltip>
                  <CustomTooltip
                    title={<p className="p-1 text-light">KDF Settings</p>}
                  >
                    <a href={`/kdfs/${tl?.doc?.id}`} target="_blank">
                      {tl?.doc?.title ?? "-"}
                    </a>
                  </CustomTooltip>
                </div>
                <p>
                  <span>Total Pages: </span>
                  {pdfFile?.num_pages ?? "-"}
                </p>
              </>
            ) : (
              <>
                {data?.tracking_links?.length > 1 && (
                  <>
                    <p>
                      {data.tracking_links.length} LaserLINX point to this
                      KDF
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Displaying all - Click to Filter"
                      variant="light"
                      className="my-2 border dropdown-button hov-clr"
                    >
                      {data?.tracking_links?.map((trackingLink) => (
                        <Dropdown.Item
                          key={trackingLink?.name}
                          onClick={() => handleSelect(trackingLink?.token)}
                          className="d-flex align-items-center"
                        >
                          <div
                            style={{
                              width: "22px",
                              height: "22px",
                              marginRight: "10px",
                            }}
                            className={trackingLink?.language_flag?.class}
                          ></div>
                          <span>
                            {trackingLink?.name} {trackingLink?.created_at}{" "}
                            ({trackingLink?.id})
                          </span>
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
        <RenderComponent />
      </Container>
    </>
  );
};

export default StatsView;
