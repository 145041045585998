import React, { useEffect, useMemo } from "react";
import Element from "../../../../../../Elements/KDFElements/Element";
import CommonModal from "../../../../../../Modals/CommonModal";
import ImageSlider from "./ImageSlider";
import "./LightBoxSection.css";

const ViewLightBox = ({ showLightBox, setShowLightBox }) => {
    const arrayOfImageElements = useMemo(() => {
        return showLightBox && Object.keys(showLightBox?.elements ?? showLightBox).length !== 0
            ? Object.values(showLightBox?.elements ?? showLightBox)
            : [];
    }, [showLightBox]);

    useEffect(() => {
        const handleEscPress = (event) => {
            if (event.key === "Escape") {
                event.stopPropagation();  // ✅ Prevent event bubbling to parent
                event.preventDefault();  // ✅ Stop default behavior
                setShowLightBox(null);   // ✅ Close only the preview lightbox
            }
        };

        if (showLightBox) {
            document.addEventListener("keydown", handleEscPress, true); // ✅ Use capture phase
        }

        return () => {
            document.removeEventListener("keydown", handleEscPress, true);
        };
    }, [showLightBox]);

    return (
        <CommonModal
            modalStatus={showLightBox != null}
            changeActiveStatus={() => setShowLightBox(null)}
            centered
            lightBox={true}
            onClick={(e) => e.stopPropagation()} // ✅ Stop click events from propagating
        >
            {((!!showLightBox?.elements || !!showLightBox) && arrayOfImageElements?.length < 2) ?
                <Element
                    elements={showLightBox?.elements ?? showLightBox ?? {}}
                    dimensions={{ width: showLightBox?.width, height: showLightBox?.height }}
                /> :
                <ImageSlider arrayOfImageElements={arrayOfImageElements} showIcons={false} />
            }
        </CommonModal>
    );
};

export default ViewLightBox;
