import React, { useContext, useEffect, useState } from "react";
import {
  faArrowsUpDownLeftRight,
  faCircleXmark,
  faCopy,
  faFloppyDisk,
  faPencil,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import { networkService } from "../../../../config/axios";
import CustomTooltip from "../../../../Elements/Tooltip";
import {
  getNormalizedDimensions,
  normalizeDimensions,
} from "../../../../../utis/helper";

const ElementIcons = ({
  element,
  resizableDimensions,
  previousDimensions,
  setResizeDimensions,
  canvasRef,
  zoom,
}) => {
  const {
    setOpenAddElement,
    setEditElement,
    setOpenRemoveModal,
    setToolType,
    setElements,
    elements,
    setShowToast,
    editElement,
    setPageDimensions,
    geo,
    pageDimensions,
  } = useContext(EditorInfoContext);
  const [updated, setUpdated] = useState(false);
  const [hideTooltip, setHideTooltip] = useState(false);

  const handleCancel = (e) => {
    e.stopPropagation();
    setResizeDimensions((prevDimensions) => {
      return {
        ...prevDimensions,
        [element.id]: {
          ...prevDimensions[element.id],
          ...previousDimensions.current,
        },
      };
    });
  };

  const handleMouseDown = () => {
    setHideTooltip(true);
  };

  const handleMouseUp = () => {
    setTimeout(() => setHideTooltip(false), 200);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    const data = {
      id: element.id,
      rotate: element.rotate,
      flip: element.flip,
      doc_id: element.doc_id,
      tags: element.tags || "keep",
      geometry: {
        left: normalizeDimensions(
          resizableDimensions?.left ?? previousDimensions?.current?.left,
          zoom?.value
        ),
        top: normalizeDimensions(
          resizableDimensions?.top ?? previousDimensions?.current?.top,
          zoom?.value
        ),
        width: normalizeDimensions(
          resizableDimensions?.width ?? previousDimensions?.current?.width,
          zoom?.value
        ),
        height: normalizeDimensions(
          resizableDimensions?.height ?? previousDimensions?.current?.height,
          zoom?.value
        ),
        hide_beyond_page_edges: element.geometry.hide_beyond_page_edges,
        keep_proportions: element.geometry.keep_proportions,
        keep_within_page: element.geometry.keep_within_page,
      },
    };

    const url = `/docs/elements/${element?.id}.json`;
    networkService(url, "PATCH", data)
      .then((response) => {
        if (response.status == 200) {
          setElements({ ...elements, [response?.data?.id]: response?.data });
          previousDimensions.current = { ...resizableDimensions };
          setUpdated(false);
          setResizeDimensions((prevDimensions) => {
            return {
              ...prevDimensions,
              [element.id]: {
                ...prevDimensions[element.id],
                ...getNormalizedDimensions(
                  response.data.geometry,
                  zoom,
                  pageDimensions
                ),
              },
            };
          });
          setShowToast({
            showToast: true,
            toastType: "success",
            toastMessage: "Changes saved",
          });
        }
      })
      .catch((error) => {
        setShowToast({
          showToast: true,
          toastType: "danger",
          toastMessage: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    if (previousDimensions.current) {
      const isUpdated =
        (resizableDimensions?.top !== previousDimensions.current?.top ||
          resizableDimensions?.left !== previousDimensions.current?.left ||
          resizableDimensions?.width !== previousDimensions.current?.width ||
          resizableDimensions?.height !== previousDimensions.current?.height) &&
        !localStorage.getItem("is_changed_model");
      setUpdated(isUpdated);
    }
  }, [Object.values(resizableDimensions || {})]);

  useEffect(() => {
    const updateDimensions = (width, height, top, left) => {
      setResizeDimensions((prevDimensions) => {
        return {
          ...prevDimensions,
          [editElement ? editElement?.id : element.id]: {
            ...prevDimensions[element.id],
            width,
            height,
            ...(top && { top }),
            ...(left && { left }),
          },
        };
      });
    };
    updateDimensions(geo?.width, geo?.height, geo?.top, geo?.left);
  }, [geo, element.id]);

  return (
    <>
      <div className="action-icons-top d-flex">
        {updated ? (
          <>
            <CustomTooltip
              title={<p className="p-1 text-light">Apply changes</p>}
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                className="success-green"
                onClick={handleSave}
              />
            </CustomTooltip>
            <CustomTooltip
              title={<p className="p-1 text-light">Cancel changes</p>}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="cancel"
                onClick={handleCancel}
              />
            </CustomTooltip>
          </>
        ) : (
          <>
            <CustomTooltip
              title={<p className="p-1 text-light">Clone Element</p>}
            >
              <FontAwesomeIcon
                icon={faCopy}
                className=""
                onClick={(e) => {
                  e.stopPropagation();
                  setToolType("Clone");
                  setEditElement(element);
                  setOpenAddElement(true);
                }}
              />
            </CustomTooltip>
            <CustomTooltip
              title={<p className="p-1 text-light">Edit Element</p>}
            >
              <FontAwesomeIcon
                className="success-green"
                icon={faPencil}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditElement(element);
                  setOpenAddElement(true);
                  setPageDimensions({
                    height: canvasRef?.height,
                    width: canvasRef?.width,
                  });
                }}
              />
            </CustomTooltip>
            <CustomTooltip
              title={<p className="p-1 text-light">Delete Element</p>}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                className="cancel"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditElement(element);
                  setOpenRemoveModal(true);
                }}
              />
            </CustomTooltip>
          </>
        )}
      </div>
      <div className="action-icons-bottom" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
        {!hideTooltip ? (
          <CustomTooltip
            title={<p className="p-1 text-light">Move Element</p>}
            placement="bottom"
          >
            <FontAwesomeIcon
              icon={faArrowsUpDownLeftRight}
              onClick={(e) => e.stopPropagation()}
            />
          </CustomTooltip>
        ) : (
          <FontAwesomeIcon
            icon={faArrowsUpDownLeftRight}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
    </>
  );
};

export default ElementIcons;
