import { useState, useEffect } from "react";

const usePdfSearch = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
    const [matches, setMatches] = useState([]);
    const [matchCount, setMatchCount] = useState(0);

    const searchInPdf = () => {
        if (!searchTerm.trim()) {
            clearHighlights();
            return;
        }

        clearHighlights();

        const textLayers = document.querySelectorAll(".textLayer");
        if (textLayers.length === 0) {
            console.warn("Text layers not found. Retrying...");
            return;
        }
        let newMatches = [];
        let totalMatches = 0;
        textLayers.forEach((layer, pageIndex) => {
            const spans = layer.querySelectorAll("span");

            spans.forEach((span) => {
                if (!span.hasAttribute("data-original-text")) {
                    span.setAttribute("data-original-text", span.textContent);
                }
                const originalText = span.getAttribute("data-original-text");
                const lowerText = originalText.toLowerCase();
                const lowerSearch = searchTerm.toLowerCase();
                let index = lowerText.indexOf(lowerSearch);

                if (index !== -1) {
                    let updatedHTML = "";
                    let lastIndex = 0;
                    while (index !== -1) {
                        totalMatches++;
                        updatedHTML += originalText.substring(lastIndex, index);
                        updatedHTML += `<mark class="highlight" data-match-index="${totalMatches - 1}" style="background-color: #00F000; opacity: 0.3;">${originalText.substring(index, index + searchTerm.length)}</mark>`;
                        lastIndex = index + searchTerm.length;
                        index = lowerText.indexOf(lowerSearch, lastIndex);
                    }
                    updatedHTML += originalText.substring(lastIndex);
                    span.innerHTML = updatedHTML;
                    newMatches.push({ span, pageIndex });
                }
            });
        });

        setMatches(newMatches);
        setMatchCount(totalMatches);
        setCurrentMatchIndex(totalMatches > 0 ? 0 : -1);

        if (totalMatches > 0) {
            scrollToMatch(0);
        } else {
            console.warn("Search term not found.");
        }
    };

    const clearHighlights = () => {
        document.querySelectorAll(".textLayer span").forEach((span) => {
            if (span.hasAttribute("data-original-text")) {
                span.innerHTML = span.getAttribute("data-original-text");
                span.removeAttribute("data-original-text");
            }
        });
        setMatches([]);
        setCurrentMatchIndex(-1);
    };

    const scrollToMatch = (index) => {
        if (matches.length === 0 || index < 0 || index >= matches.length) return;

        const match = matches[index].span.querySelector(".highlight[data-match-index]");
        if (match) {
            match.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        setCurrentMatchIndex(index);
    };

    const goToNextMatch = () => {
        if (currentMatchIndex < matchCount - 1) {
            scrollToMatch(currentMatchIndex + 1);
        }
    };

    const goToPreviousMatch = () => {
        if (currentMatchIndex > 0) {
            scrollToMatch(currentMatchIndex - 1);
        }
    };

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const textLayers = document.querySelectorAll(".react-pdf__Page__textContent .textLayer");

            if (textLayers.length > 0) {
                observer.disconnect();
                searchInPdf();
            }
        });
        observer.observe(document.body, { childList: true, subtree: true });
        return () => observer.disconnect();
    }, [searchTerm]);

    return {
        searchTerm,
        setSearchTerm,
        searchInPdf,
        clearHighlights,
        goToNextMatch,
        goToPreviousMatch,
        matchCount,
        currentMatchIndex,
        matches
    };
};

export default usePdfSearch;
