import React from "react"
import { Col, Row } from "react-bootstrap"
import styles from '../Viewer/Viewer.module.css'
import CustomTooltip from "../../../Elements/Tooltip"
import { handleErrorImage } from "../../../../utis/helper"

const SessionData = ({ viewDt, thumbs_details }) => {
    return (
        <div className={styles.headerCard}>
            <Row>
                <Col>
                    <div className={styles.stat}>
                        <span className={styles.value}>
                            {viewDt?.engagement?.replace("%", "") || "-"}
                        </span>
                        <div>Score</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.stat}>
                        <span className={styles.value}>
                            {viewDt?.percent_viewed || "-"}
                        </span>
                        <div>Viewed</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.stat}>
                        <CustomTooltip
                            title={
                                <p className="p-1 text-light">
                                    HH:MM:SS
                                    <br />
                                    Total time may vary by a few seconds
                                </p>
                            }
                        >
                            <span className={styles.value}>
                                {viewDt?.min_viewed || "-"}
                            </span>
                        </CustomTooltip>
                        <div>Total Time</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={styles.stat}>
                        <span className={styles.value}>
                            {viewDt?.sessions?.length}
                        </span>
                        <div>Visit</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.stat}>
                        {viewDt?.pages_by_time_spent?.pages?.map((pg, ind) => (
                            <CustomTooltip
                                key={ind}
                                light={true}
                                title={
                                    <div className="bg-whte p-2">
                                        <img
                                            src={`${thumbs_details?.normal}${pg - 1}.${thumbs_details?.extension
                                                }`}
                                            onError={(e) => handleErrorImage(e)}
                                            alt={pg}
                                            width="99px"
                                            height="129px"
                                        />
                                    </div>
                                }
                            >
                                <span className={`${styles.value} cursor-help`}>
                                    {pg}
                                </span>
                            </CustomTooltip>
                        ))}
                        <div>Top Page</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.stat}>
                        {viewDt?.popular_exit_pages?.pages?.length > 0 && viewDt?.popular_exit_pages?.pages?.map((pg, ind) => (
                            <CustomTooltip
                                key={ind}
                                light={true}
                                title={
                                    <div className="bg-whte p-2">
                                        <img
                                            src={`${thumbs_details?.normal}${pg?.page - 1}.${thumbs_details?.extension}`}
                                            onError={(e) => handleErrorImage(e)}
                                            alt={pg}
                                            width="99px"
                                            height="129px"
                                        />
                                    </div>
                                }
                            >
                                <span className={`${styles.value} cursor-help`}>
                                    {pg}
                                </span>
                            </CustomTooltip>
                        ))}
                        {viewDt?.popular_exit_pages?.length > 0 && viewDt?.popular_exit_pages?.map((pg, ind) => (
                            <CustomTooltip
                                key={ind}
                                light={true}
                                title={
                                    <div className="bg-whte p-2">
                                        <img
                                            src={`${thumbs_details?.normal}${pg?.page - 1}.${thumbs_details?.extension}`}
                                            onError={(e) => handleErrorImage(e)}
                                            alt={pg?.page}
                                            width="99px"
                                            height="129px"
                                        />
                                    </div>
                                }
                            >
                                <span className={`${styles.value} cursor-help`}>
                                    {pg?.page}
                                </span>
                            </CustomTooltip>
                        ))}
                        <div>Exit Page</div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default SessionData