import React, { useEffect, useRef, useState } from "react";

const YouTubeIframe = ({
  src,
  style,
  title,
  id,
  setVideoData,
  resizeDimensions,
  dimensions,
  actionCableVideoMontior,
  ...props
}) => {
  const videoId = src?.split("/")[4]?.split("?")[0] || "";
  const iframeRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (!videoId) {
      console.error("Invalid videoId.");
      return;
    }

    let intervalId;

    // Function to handle the YouTube Iframe API loading
    const onYouTubeIframeAPIReady = () => {
      if (window.YT && window.YT.Player) {
        const player = new window.YT.Player(iframeRef.current, {
          videoId,
          events: {
            onStateChange: (event) => handlePlayerStateChange(event, player),
            onError: handlePlayerError,
          },
        });
      }
    };

    const handlePlayerStateChange = (event, player) => {
      let id = event.target.h.id;
      if (id === undefined) {
        id = event.target.g.id;
      }

      switch (event.data) {
        case 1: {
          const videoDuration = player.playerInfo.duration;
          const data = {
            id: id,
          };
          timerRef.current = setInterval(() => {
            if (window?.objInit?.tlb?.disable_settings?.disable_on === "cyoa") {
              const playerElement = document.getElementById(id);
              const iframeRect = playerElement.getBoundingClientRect();
              const isInsideViewport =
                iframeRect.top < window.innerHeight &&
                iframeRect.bottom > 0 &&
                iframeRect.left < window.innerWidth &&
                iframeRect.right > 0;
              if (!isInsideViewport) {
                event.target.pauseVideo();
              }
            }
            const currentTime = parseInt(player.playerInfo.currentTime);
            data.watchedPercent = Math.round(
              (currentTime / videoDuration) * 100
            );
            data.watchedSeconds = currentTime;
            data.duration = videoDuration;
            actionCableVideoMontior(data);
            setIsPlaying(true);
          }, 250);
          break;
        }
        case 0: {
          const data = {
            id: id,
          };
          clearInterval(timerRef.current);
          const videoDuration = player.playerInfo.duration;
          const currentTime = parseInt(player.playerInfo.currentTime);
          data.watchedPercent = 100;
          data.watchedSeconds = currentTime;
          data.duration = videoDuration;
          actionCableVideoMontior(data);
          setIsPlaying(false);
          break;
        }
        default:
          clearInterval(timerRef.current);
          break;
      }
    };

    const handlePlayerError = (event) => {
      console.error("YouTube Player Error: ", event.data);
      switch (event.data) {
        case 2:
          console.error("Invalid parameter value in videoId or API request.");
          break;
        case 5:
          console.error("HTML5 player error. Issue with video playback.");
          break;
        case 100:
          console.error(
            "Video not found. The videoId may be incorrect or removed."
          );
          break;
        case 101:
        case 150:
          console.error("Embedding not allowed for this video.");
          break;
        default:
          console.error("Unknown error.");
          break;
      }
    };

    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    } else {
      onYouTubeIframeAPIReady();
    }

    return () => {
      window.onYouTubeIframeAPIReady = null;
      if (iframeRef.current && iframeRef.current.player) {
        iframeRef.current.player.destroy();
      }
      clearInterval(intervalId);
    };
  }, [videoId, isPlaying]);

  return (
    <iframe
      ref={iframeRef}
      style={style}
      onClick={props.onClick}
      src={src + "&enablejsapi=1"}
      title={title}
      id={id}
      webkitallowfullscreen=""
      mozallowfullscreen=""
      allowFullScreen=""
      {...props}
    ></iframe>
  );
};

export default React.memo(YouTubeIframe);
