import React from "react"
import CustomTooltip from "../../../Elements/Tooltip"
import styles from '../Viewer/Viewer.module.css'

const LaserlinkSessHeading = ({ viewDt, sessionIndex }) => {
    const getLatestRecord = (data) => {
        return data?.reduce((latest, current) => {
            const currentDate = new Date(current.date.replace("IST", "").trim());
            const latestDate = new Date(latest.date.replace("IST", "").trim());
            return currentDate > latestDate ? current : latest;
        }, data[0]);
    };

    return (
        <div id="UNKNOWN-REC" className={styles.headerCard}>
            <CustomTooltip
                title={
                    <p className="p-1 text-light">
                        If possible, consider sending them another KDF
                        Link with an OPT-IN requesting their NAME
                    </p>
                }
            >
                <h5>{viewDt.name || "Recipient Unknown"}</h5>
            </CustomTooltip>
            <hr />
            {viewDt?.sessions?.map((session, idx) =>
                idx == sessionIndex && (
                    <div key={idx}>
                        <div className={styles.locationInfo}>
                            <CustomTooltip
                                title={
                                    <p className="p-1 text-light">
                                        {viewDt.name || "Unknown User"} viewed this
                                        KDF on {session.date} via {session?.ip} in{" "}
                                        {session?.country}
                                    </p>
                                }
                            >
                                <>
                                    <span>
                                        KDF viewed on: {session.date?.split(" ")[0]}
                                    </span>
                                    <div
                                        style={{
                                            width: "22px",
                                        }}
                                        className={`flag-icon flag-icon-${session?.ip_details?.country_code?.toLowerCase()}`}
                                    ></div>
                                </>
                            </CustomTooltip>
                        </div>
                        <div className={styles.locationInfo}>
                            <CustomTooltip
                                title={
                                    <p className="p-1 text-light">
                                        {viewDt.name || "Unknown User"} viewed this
                                        KDF on{" "}
                                        {getLatestRecord(session?.pages)?.date} via{" "}
                                        {session?.ip} in {session?.country}
                                    </p>
                                }
                            >
                                <span>
                                    Last seen:{" "}
                                    {
                                        getLatestRecord(
                                            session?.pages
                                        )?.date?.split(" ")[0]
                                    }
                                    <span
                                        style={{
                                            width: "22px",
                                        }}
                                        className={`flag-icon flag-icon-${session?.ip_details?.country_code?.toLowerCase()}`}
                                    ></span>
                                </span>
                            </CustomTooltip>
                        </div>
                    </div>
                ))}
        </div>
    )
}
export default LaserlinkSessHeading