import React, { useState, useEffect } from 'react';
import { networkService } from '../components/config/axios';

const useRecipientNotification = (editEnabled, pageNumber, tlb, notificationURL = null) => {
  const [isApiCalled, setIsApiCalled] = useState(false);

  useEffect(() => {
    if (editEnabled || isApiCalled) return;
    if (tlb?.send_engagement_notif == true && tlb?.notify_after != '' && !isApiCalled) {
      const timer = setTimeout(() => {
        myApiCall();
      }, tlb.notify_after * 1000);
      return () => clearTimeout(timer);
    } else if (pageNumber === tlb?.notify_for_page && !isApiCalled) {
      const timer = setTimeout(() => {
        myApiCall();
      }, tlb.notify_page_after * 1000);

      return () => clearTimeout(timer);
    }

  }, [pageNumber, tlb, isApiCalled]);

  const myApiCall = async () => {
    if (notificationURL != null) {
      let notification = await networkService(notificationURL);
    }
    setIsApiCalled(true);
  };
};

const usePageNumber = (initialPage = 1) => {
  const [pageNumber, setPageNumber] = useState(initialPage);

  const nextPage = () => setPageNumber(prev => prev + 1);
  const prevPage = () => setPageNumber(prev => Math.max(1, prev - 1));

  return { pageNumber, nextPage, prevPage };
};

export { useRecipientNotification, usePageNumber };
