import React, { useContext, useState } from "react";
import { calculateRectPos, getOffset } from "../../../../../utis/helper";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import useCanvasHook from "../editorHook/useCanvasHook";

const PdfCanvas = ({ canvasRefs, index, editortype }) => {
  const { draw, createDiv } = useCanvasHook();
  const {
    editEnabled,
    setOpenAddElement,
    rectangles,
    geo,
    setRectangles,
    setActiveRectangleIndex,
    setGeo,
    pageDimensions
  } = useContext(EditorInfoContext);

  const [isDrawing, setIsDrawing] = useState(false);
  const [startCoords, setStartCoords] = useState(null);
  const [offset, setOffset] = useState({
    left: 0,
    top: 0
  });

  const handleMouseDown = (event, index) => {
    var offset = getOffset(canvasRefs.current[index]);
    const canvasOffsetLeft = offset.left;
    const canvasOffsetTop = offset.top;

    setOffset({
      left: canvasOffsetLeft,
      top: canvasOffsetTop
    });
    setIsDrawing(true);
    const startCoords = {
      x: event.pageX - canvasOffsetLeft,
      y: event.pageY - canvasOffsetTop,
    };

    setStartCoords(startCoords);

    const newRectangles = [...rectangles];
    newRectangles[index] = [
      ...newRectangles[index],
      { startCoords, currentCoords: startCoords, media: null },
    ];

    setRectangles(newRectangles);
    setActiveRectangleIndex(newRectangles[index].length - 1);
  };

  const handleMouseMove = (event, index) => {
    if (!isDrawing) return;
    const currentX = event.pageX - offset.left;
    const currentY = event.pageY - offset.top;

    const newRectangles = [...rectangles];
    const currentRectangles = newRectangles[index];
    const lastRectangle = currentRectangles[currentRectangles.length - 1];

    lastRectangle.currentCoords = { x: currentX, y: currentY };
    setRectangles(newRectangles);

    draw(index, canvasRefs);
  };

  const handleMouseUp = (e, index) => {
    if (!isDrawing) return;
    setIsDrawing(false);
    setOpenAddElement(true);

    rectangles?.filter(
      (elem, index) =>
        elem?.length > 0 &&
        elem?.map((element, idx) => {
          setGeo({
            ...geo,
            left: element?.startCoords?.x,
            top: element?.startCoords?.y,
            width: element?.currentCoords?.x - element?.startCoords?.x,
            height: element?.currentCoords?.y - element?.startCoords?.y,
            page: index + 1,
          });
        })
    );
    const canvas = canvasRefs?.current[index];

    const currentX = e.pageX - offset.left;
    const currentY = e.pageY - offset.top;
    const position = calculateRectPos(startCoords.x, startCoords.y, currentX, currentY);
    const newRect = { x: position.left, y: position.top, width: position?.width, height: position?.height };
    createDiv(newRect, index, canvasRefs);

    // Clear the canvas
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  return (
    <canvas
      ref={(el) => (canvasRefs.current[index] = el)}
      id={index + 1}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: pageDimensions.height,
        width: pageDimensions.width,
        ...(editortype !== 'stats-viewer' ? {
          height: pageDimensions.height + 7,
          width: pageDimensions.width + 7
        } : { width: '100%', height: '100%' }),
        zIndex: editEnabled ? 999 : 2,
        pointerEvents: "auto",
      }}
      onMouseDown={(e) => {
        editEnabled &&
          (handleMouseDown(e, index),
            setActiveRectangleIndex({
              pageIndex: index,
              rectIndex: rectangles[index].length,
            }));
      }}
      onMouseMove={(e) => editEnabled && handleMouseMove(e, index)}
      onMouseUp={(e) => editEnabled && handleMouseUp(e, index)}
    />
  );
};
export default PdfCanvas;
