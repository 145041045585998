import React from "react"
import CustomTooltip from "../../../../../../Elements/Tooltip"

const LightBoxTrigger = ({ lightBoxPayload, handleChange }) => {
    return (
        <>
            <div className="section-trigger">
                <h3>TRIGGER</h3>
                <label className="align-items-center d-flex">
                    <input
                        type="radio"
                        name="display"
                        value="click"
                        onChange={handleChange}
                        checked={lightBoxPayload?.display === 'click'}
                    />
                    <CustomTooltip title={<p className="p-1 text-light">To trigger this ClickPop, Go to the KDF Editor then Image or Hotspot Settings and select action = 'Reveal ClickPop'</p>}>
                        <p>
                            When a related hotspot or image is clicked
                        </p>
                    </CustomTooltip>
                </label>
                <label className="align-items-center d-flex">
                    <input
                        type="radio"
                        name="display"
                        value='page'
                        checked={lightBoxPayload?.display === 'page'}
                        onChange={handleChange}
                    />
                    <CustomTooltip title={<p className="p-1 text-light">TimedPops can trigger on a specific page or on any page. Page number is optional</p>}>
                        <span className="text-dark">After</span>
                    </CustomTooltip>
                    <input
                        type="number"
                        name="show_after"
                        value={lightBoxPayload?.show_after ? lightBoxPayload?.show_after : ''}
                        onChange={handleChange}
                        className="input-small"
                    />
                    <span className="text-dark">sec on page:</span>
                    <input
                        type="number"
                        name="show_on_page"
                        value={lightBoxPayload?.show_on_page ? lightBoxPayload?.show_on_page : ''}
                        onChange={handleChange}
                        className="input-small"
                    />
                </label>
                <label className="align-items-center d-flex">
                    <input
                        type="radio"
                        name="display"
                        checked={lightBoxPayload?.display === 'doc'}
                        value='doc'
                        onChange={handleChange}
                    />
                    <CustomTooltip title={<p className="p-1 text-light">Create the EnterPop here and remember to select it when you create a LaserLink.</p>}>
                        <p>
                            On entry
                        </p>
                    </CustomTooltip>
                </label>
                <label className="align-items-center d-flex">
                    <input
                        type="radio"
                        name="display"
                        value='onexit'
                        checked={lightBoxPayload?.display === 'onexit'}
                        onChange={handleChange}
                    />
                    <CustomTooltip title={<p className="p-1 text-light">Create the ExitPop here and remember to select it when you create a LaserLink.</p>}>
                        <p>
                            On exit intent
                        </p>
                    </CustomTooltip>
                </label>
                <label className="align-items-center d-flex">
                    <input
                        type="radio"
                        name="display"
                        checked={lightBoxPayload?.display === 'on_hide_reveal'}
                        value='on_hide_reveal'
                        onChange={handleChange}
                    />
                    <CustomTooltip title={<p className="p-1 text-light">Select this trigger to assign this lightbox to the Hide/Reveal Action.</p>}>
                        <p>
                            On Hide/Reveal Action
                        </p>
                    </CustomTooltip>
                </label>
                <label>

                </label>
                <div className="toggle-container mt-5">
                    <label>High Priority Element: </label>
                    <input type="checkbox"
                        id="toggle-set"
                        className="toggle-input"
                        name="priority"
                        value={lightBoxPayload?.priority}
                        checked={lightBoxPayload?.priority}
                        onChange={handleChange}
                    />
                    <label htmlFor="toggle-set" className="toggle-btn"></label>
                </div>
            </div>
        </>
    )
}
export default LightBoxTrigger