import React, { useEffect, useRef } from 'react';
import { Button as BButton } from 'react-bootstrap';
import './button.css'
const Button = ({ classList, icon, text, addonClass, children = null, onClick, type, ...restProps }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && type === "submit") {
                event.preventDefault();
                onClick?.();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClick, type]);

    if (children) return { children };
    return (
        <div className={`${classList?.split(' ').map(item => `custom-button-${item}`).join(' ')} ${addonClass != undefined ? addonClass : ''}`}>
            <BButton onClick={onClick} type={type || "button"} {...restProps}>
              {icon ? icon : ""}  {text}
            </BButton>
        </div>
    )
}
export default Button;