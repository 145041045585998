import React, { useEffect, useState } from "react";
import { dispatchEvent, scrollPage } from "../../../../../utis/helper";
import './PdfSidebar.css';

const PdfSidebar = ({ pdfImages }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const onScroll = (e) => {
        const value = e.target.value;
        setPageNumber(parseInt(value));
    };
    
    useEffect(() => {
        document.getElementById("pageNumber").addEventListener("change", onScroll);
    }, []);

    return (
        <div className="pdf-sidebar text-light">
            {
                pdfImages?.map((url, idx) => {
                    return (
                        <div key={idx} className={`image-side-listing mb-2 mt-2 ${(idx + 1 == pageNumber) && 'active-page-image'}`}
                            onClick={() => {
                                document.getElementById("pageNumber").value = idx + 1;
                                scrollPage(idx + 1, "instant");
                                dispatchEvent("change", "pageNumber");
                            }}
                        >
                            <div className="pdf-side-img">
                                <img src={url} alt="page image" />
                            </div>
                            <p>{idx + 1}</p>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default PdfSidebar;
