import React, { useContext, useEffect, useState } from "react"
import CommonModal from "../CommonModal";
import Button from "../../Elements/Button";
import CustomForm from "../../CommonComponents/CustomForm/CustomForm";
import Select from "../../Elements/Select";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import { networkService } from "../../config/axios";
import { useParams } from "react-router-dom";
import UserInfoContext from "../../../utis/userInfoContext";

const AddDomainModal = ({ openModal, closeModal, domainData, availableDomains }) => {

    const AddDomainBody = () => {
        const { setShowNotifications } = useContext(UserInfoContext)
        const { id } = useParams()
        const [domain, setDomain] = useState({
            available_domain_id: '',
            status: false,
            default: false
        })

        const [error, setError] = useState({
            available_domain_id: ''
        })

        const handleChange = ({ target: { name, value, type, checked } }) => {
            setDomain({
                ...domain,
                [name]: type == 'checkbox' ? checked : value
            });
            setError({
                [name]: ''
            })
        }

        const handlesubmit = async (e) => {
            let url = domainData?.id ? `admin/users/${id}/manage_domains/${domain?.id}` : `admin/users/${id}/manage_domains`
            e.preventDefault()
            if (!domain?.available_domain_id) {
                error.available_domain_id = 'Please add the Domain';
            } else {
                let data = {
                    authenticity_token: csrfToken(),
                    ...(domainData?.id && { id: domainData?.id }),
                    domain: {
                        status: domain?.status ? 1 : 0,
                        default: domain?.default ? 1 : 0,
                        available_domain_id: domain?.available_domain_id
                    },
                }

                let res = await networkService(url,
                    domain?.id ? 'PATCH' : "POST",
                    data
                );
                if (res.status == 200 || res.status == 201) {
                    closeModal(false)
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: `Domains ${domain?.id ? 'updated' : 'added'}`,
                    })
                } else {
                    setShowNotifications({
                        showToast: true,
                        toastType: 'danger',
                        toastMessage: "Something went wrong"
                    })
                }
            }
        }

        useEffect(() => {
            setDomain(domainData)
        }, [domainData])

        return (
            <CustomForm>
                <Select
                    classList=''
                    name='available_domain_id'
                    required={true}
                    onChange={(e) => handleChange(e)}
                    defaultValue={domain.available_domain_id}
                    optionList={
                        availableDomains?.map(type => {
                            return {
                                'value': type.id,
                                'title': type.name
                            }
                        })
                    }
                />
                <label className="mt-4 cursor-pointer">
                    <input type="checkbox"
                        name="status"
                        className="me-2"
                        value={domain?.status}
                        checked={domain?.status}
                        onChange={handleChange}
                    />
                    Status
                </label>
                <label className="mt-4 cursor-pointer">
                    <input type="checkbox"
                        name="default"
                        className="me-2"
                        value={domain?.default}
                        checked={domain?.default}
                        onChange={handleChange}
                    />
                    Default
                </label>
                <div className="d-flex justify-content-end">
                    <Button
                        text={'Save'}
                        classList={'success'}
                        type="submit" 
                        onClick={(e) => handlesubmit(e)}
                    />
                </div>
            </CustomForm>
        )
    }


    return (<>
        <CommonModal
            title={"Add New Domain"}
            modalStatus={openModal}
            changeActiveStatus={(status) => closeModal(false)}
            classList="header-profile-modal-wrapper"
            centered
        >
            <AddDomainBody />
        </CommonModal>
    </>)
}
export default AddDomainModal