import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../../../../assets/stylesheets/editorPage.css";
import "../../../../../assets/stylesheets/homepage.css";
import { scrollPage } from "../../../../utis/helper";
import { useRecipientNotification } from "../../../../utis/useLaserLinkSettingsHook";
import { EditorInfoContext } from "../../../../utis/userInfoContext";
import { utilsGetLocalStorage } from "../../../../utis/utilsUpdateLocalStorage";
import DraggableComponent from "../../../CommonComponents/Draggable/DraggableComponent";
import ToastNotification from "../../../CommonComponents/Toasts/ToastNotification";
import { networkService } from "../../../config/axios";
import Element from "../../../Elements/KDFElements/Element";
import LaserLinkModal from "../../../Elements/LaserLink/LaserLinkModal";
import CommonModal from "../../../Modals/CommonModal";
import AddElementModal from "../../../Modals/EditorModals/AddElementModal";
import CloneElementModal from "../../../Modals/EditorModals/CloneElementModal";
import CookieConsentModal from "../../../Modals/EditorModals/CookieConsentModal";
import DeleteElementModal from "../../../Modals/EditorModals/DeleteElementModal";
import LightBoxModal from "../../../Modals/EditorModals/LightBoxModal";
import EditorHeader from "./editorHeader/EditorHeader";
import SettingsEnableOPTIN from "./editorHeader/viewElements/SettingsEnableOPTIN";
import PdfSidebar from "./editorSideBar/PdfSidebar";
import EditorToolbox from "./editorToolbox/EditorToolbox";
import ToolBoxHeading from "./editorToolbox/toolBox/ToolBoxHeading";
import PasswordProtectedKDF from "./PasswordProtectedKDF";
import Viewer from "./pdfViewer/Viewer";
import { usePageFromHash } from "../../../../hooks/utils";
import useStatHook from "../../Stats/StatsHook/useStatHook";

let timedThrottleTimeout;

const EditKdfPage = ({ editortype, ...props }) => {
  if (props?.type == "password") {
    return <PasswordProtectedKDF alert={props?.alert} />;
  }
  const { pathname, hash } = useLocation();
  const redirectPage = usePageFromHash(hash)
  const { tlb, sess_id } = window?.objInit;
  const { pdfFile, pdfImages, doc, time_zone_list, currentUser } = props;

  const [openSideSection, setOpenSideSection] = useState(false);
  const [zoomIndex, setZoomIndex] = useState({
    index: 'page-height',
    value: "0.75",
  });
  const [docFiles, setDocFiles] = useState({});
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [laserLINKEditStatus, setLaserLINKEditStatus] = useState(null);
  const [isPdfPublished, setIsPdfPublished] = useState(false)

  // New state variables for controlling the background and alert visibility
  const [viewerBgClass, setViewerBgClass] = useState("");
  const [alertTextVisible, setAlertTextVisible] = useState(false);

  // edit page context states
  const [editEnabled, setEditEnabled] = useState(false);
  const [openAddElement, setOpenAddElement] = useState(false);
  const [toolType, setToolType] = useState("");
  const [rectangles, setRectangles] = useState([]);
  const [activeRectangleIndex, setActiveRectangleIndex] = useState(null);
  const [geo, setGeo] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    keep_proportions: true,
    keep_within_page: true,
    page: 1,
  });
  const [editElement, setEditElement] = useState({});
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [elements, setElements] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [lightBoxList, setLightBoxList] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [editLightBox, setEditLightBox] = useState({});
  const [imageElement, setImageElement] = useState({});
  const [selectedPdf, setSelectedPdf] = useState("");
  const [showToast, setShowToast] = useState({
    showToast: false,
    toastType: "success",
    toastMessage: "",
  });
  const [showLightBox, setShowLightBox] = useState(null);
  const [lightBoxListData, setLightBoxListData] = useState({});
  const [tagValue, setTagValue] = useState(true);
  const [pageDimensions, setPageDimensions] = useState({
    height: "",
    width: "",
  });
  const statHook = useStatHook(true) || {};

  const handleBlockerEvent = () => {
    if (tlb?.disable_scroll === true) {
      setViewerBgClass("scroll_disable");
      setAlertTextVisible(true);
      setTimeout(() => {
        setViewerBgClass("");
        setAlertTextVisible(false);
      }, tlb?.disable_settings?.disable_for * 1000);
    }
  };

  const handleLightBoxEvent = async () => {
    let { data } = await networkService(
      `docs/all-user-lightboxes/all.json?tl_id=${tlb?.id}&by_trigger=yes&doc_id=${tlb?.doc?.id}&language=${tlb?.language_flag?.locale}`, 'GET'
    );
    setLightBoxListData(data);
  };
  const [enterDeviceLightBox, setEnterDeviceLightBox] = useState(false);
  const [exitDeviceLightBox, setExitDeviceLightBox] = useState(false);
  const [pageDeviceLightBox, setPageDeviceLightBox] = useState(false);

  useEffect(() => {
    if (Object.keys(lightBoxListData)?.length > 0) {
      const isMobile = window.innerWidth <= 768;
      const displayEnterDevice = tlb?.enter_pop_settings?.display_enter_device;
      if (displayEnterDevice === "On mobile" && isMobile) {
        setEnterDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.doc[tlb?.enter_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      } else if (displayEnterDevice === "On Device" && !isMobile) {
        setEnterDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.doc[tlb?.enter_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      } else if (displayEnterDevice === "On all devices") {
        setEnterDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.doc[tlb?.enter_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      }
    }
  }, [lightBoxListData]);

  const handleMouseMove = (e) => {
    const mouseY = e.clientY;
    if (mouseY <= 10) {
      const isMobile = window.innerWidth <= 768;
      const displayEnterDevice = tlb?.exit_pop_settings?.display_device;

      if (displayEnterDevice === "On mobile" && isMobile) {
        setExitDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      } else if (displayEnterDevice === "On Device" && !isMobile) {
        setExitDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      } else if (displayEnterDevice === "On all devices") {
        setExitDeviceLightBox(true);
        let lightBoxValueToUpdate =
          lightBoxListData?.onexit[tlb?.exit_pop_settings?.lightbox]?.elements;
        setShowLightBox(lightBoxValueToUpdate);
      }
    }
  };

  useEffect(() => {
    if (
      tlb?.exit_pop === true &&
      enterDeviceLightBox === false &&
      exitDeviceLightBox === false &&
      Object.keys(lightBoxListData)?.length > 0
    ) {
      window.addEventListener("mousemove", handleMouseMove);
    }
    return () => {
      window?.removeEventListener("mousemove", handleMouseMove);
    };
  }, [lightBoxListData]);

  useEffect(() => {
    if (timedThrottleTimeout) clearTimeout(timedThrottleTimeout);
    timedThrottleTimeout = setTimeout(() => {
      if (
        tlb?.flash_pop === true &&
        enterDeviceLightBox === false &&
        exitDeviceLightBox === false &&
        pageDeviceLightBox == false &&
        Object.keys(lightBoxListData)?.length > 0
      ) {
        const isMobile = window.innerWidth <= 768;
        const displayEnterDevice =
          tlb?.flash_pop_settings?.display_flash_device;
        const enteryPage = tlb?.flash_pop_settings?.on_page;

        if (displayEnterDevice === "On mobile" && isMobile) {
          setPageDeviceLightBox(true);
          let lightBoxValueToUpdate =
            lightBoxListData?.page[tlb?.flash_pop_settings?.on_page][
              tlb?.flash_pop_settings?.lightbox
            ]?.elements;
          setShowLightBox(lightBoxValueToUpdate);
        } else if (displayEnterDevice === "On Device" && !isMobile) {
          setPageDeviceLightBox(true);
          let lightBoxValueToUpdate =
            lightBoxListData?.page[tlb?.flash_pop_settings?.on_page][
              tlb?.flash_pop_settings?.lightbox
            ]?.elements;
          setShowLightBox(lightBoxValueToUpdate);
        } else if (displayEnterDevice === "On all devices") {
          setPageDeviceLightBox(true);
          let lightBoxValueToUpdate =
            lightBoxListData?.page[tlb?.flash_pop_settings?.on_page][
              tlb?.flash_pop_settings?.lightbox
            ]?.elements;
          setShowLightBox(lightBoxValueToUpdate);
        }
      }
    }, tlb?.flash_pop_settings?.seen_seconds * 1000);
    return () => clearTimeout(timedThrottleTimeout);
  }, [pageNumber]);

  const updateDocHeaderData = () => {
    document.title = doc?.title ?? "Kinetizine";
    document.head.innerHTML += `<title></title><meta name='description' content='${tlb?.seo_description}'/>`;
    let descMeta = document.querySelector("meta[name='description']");
    descMeta.setAttribute("content", tlb?.seo_description);
  };

  const onLoadSuccess = () => {
    if (editortype != "editor" && !pathname?.includes("viewer")) {
      handlePDFChangeAction(tlb?.landing_page || 1);
    }
  };

  useEffect(() => {
    setDocFiles(window?.objInit?.doc_files);
    setSelectedPdf(pdfFile);
    updateDocHeaderData();
    handleBlockerEvent();
    handleLightBoxEvent();
  }, []);

  useRecipientNotification(
    editEnabled,
    pageNumber,
    tlb,
    `notify-engagement/${tlb?.token}/${sess_id}.json`
  );

  const [concentModalStatus, setConcentModalStatus] = useState(
    editEnabled == false &&
    tlb?.privacy_policy_consent == true &&
    utilsGetLocalStorage("cookiesPrivacy") != "acceptAll"
  );

  // Disable Right Click Event
  document.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  });

  // Disable KeyBoard Shortcuts Event
  document.onkeydown = function (e) {
    if (event.keyCode == 123) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
      return false;
    }
  };

  const handlePDFChangeAction = (value) => {
    scrollPage(value, "instant");
    setPageNumber(value);
  };

  useEffect(() => {
    const fetchAllElements = async () => {
      const response = await networkService(
        `docs/all-elements/${selectedPdf?.id}.json`,
        "GET"
      );
      setElements(response?.data);
    };
    if (selectedPdf?.id && editortype !== 'laserlink') {
      fetchAllElements();
    } else {
      setElements(window?.objInit?.elements)
    }
  }, [selectedPdf]);

  useEffect(() => {
    handlePDFChangeAction(redirectPage);
  }, [redirectPage])

  return (
    <EditorInfoContext.Provider
      value={{
        editEnabled: editEnabled,
        openAddElement: openAddElement,
        rectangles: rectangles,
        activeRectangleIndex: activeRectangleIndex,
        geo: geo,
        toolType,
        elements: elements,
        editElement: editElement,
        pageNumber: pageNumber,
        openRemoveModal,
        editLightBox,
        lightBoxList,
        imageElement,
        openLightBox,
        selectedPdf,
        showToast,
        tagValue,
        pageDimensions,
        setPageDimensions,
        setTagValue,
        setShowToast,
        setSelectedPdf,
        setOpenLightBox,
        setImageElement,
        setLightBoxList,
        setEditElement,
        setRectangles,
        setOpenAddElement,
        setEditEnabled,
        setActiveRectangleIndex,
        setGeo,
        setToolType,
        setElements,
        setOpenRemoveModal,
        setPageNumber,
        setEditLightBox,
      }}
    >
      {(editEnabled == false) &
        (concentModalStatus == false) &
        (tlb?.tracking_link_option?.show_on_page != null ||
          tlb?.has_optin == true) ? (
        <SettingsEnableOPTIN
          pageNumber={pageNumber}
          tlb={tlb}
          sess_id={sess_id}
        />
      ) : (
        ""
      )}
      {concentModalStatus ? (
        <CookieConsentModal
          concentModalStatus={concentModalStatus}
          setConcentModalStatus={setConcentModalStatus}
          customizationText={tlb?.customization_text}
          sessId={sess_id}
        />
      ) : (
        <>
          <CommonModal
            modalStatus={showLightBox != null}
            changeActiveStatus={(status) => {
              setShowLightBox(null);
              setExitDeviceLightBox(false);
              setEnterDeviceLightBox(false);
            }}
            lightBox={true}
          >
            {!!showLightBox ? <Element elements={showLightBox ?? {}} /> : ""}
          </CommonModal>
          <ToastNotification
            showToast={showToast}
            setShowToast={setShowToast}
          />

          {openRemoveModal && (
            <DeleteElementModal
              openDeleteModal={openRemoveModal}
              closeModal={setOpenRemoveModal}
              modalType={
                toolType == "LightboxesToolbox" &&
                  !editElement?.id &&
                  editLightBox?.id
                  ? "lightbox"
                  : "elemement"
              }
              elemId={
                toolType == "LightboxesToolbox" &&
                  !editElement?.id &&
                  editLightBox?.id
                  ? editLightBox?.id
                  : editElement?.id
              }
            />
          )}
          {/* addElementModal */}
          {openAddElement && (
            <AddElementModal
              openAddElementModal={openAddElement}
              closeModal={setOpenAddElement}
              docData={selectedPdf}
              docFiles={docFiles}
              totalPages={pdfFile?.num_pages}
              applyDesiredAction={statHook?.applyDesiredAction}
              zoom={zoomIndex}
            />
          )}
          {/* lightBoxSection */}
          {openLightBox && (
            <LightBoxModal
              openLightboxModal={openLightBox}
              docFileId={selectedPdf?.id}
              docId={doc?.id}
              setOpenLightBox={setOpenLightBox}
            />
          )}
          {/* clone elements in other language modal */}
          {openCloneModal && (
            <CloneElementModal
              openCloneModal={openCloneModal}
              closeModal={setOpenCloneModal}
              docFiles={docFiles}
              sourceId={selectedPdf?.id ?? pdfFile?.id}
            />
          )}
          {laserLINKEditStatus && (
            <LaserLinkModal
              laserItem={laserLINKEditStatus}
              time_zone_list={time_zone_list}
              docData={doc}
              triggerCancelProcess={setLaserLINKEditStatus}
              currentUser={currentUser}
              language={pdfFile?.language}
              thumbnail={pdfImages[0]}
            />
          )}
          <div
            className={`viewer-bg ${viewerBgClass} ${tlb?.cyoa_enabled == true ? "scroll_disable" : ""
              }`}
          >
            {/* editor page header */}
            <EditorHeader
              pdfFile={pdfFile}
              setOpenSideSection={setOpenSideSection}
              openSideSection={openSideSection}
              setZoomIndex={setZoomIndex}
              zoomIndex={zoomIndex}
              pageNumber={pageNumber}
              setPageNumber={(value) => handlePDFChangeAction(value)}
              docFiles={docFiles}
              setSelectedPdf={setSelectedPdf}
              title={doc?.title}
              downloadStatus={tlb?.enable_download ?? false}
              selectedPdf={selectedPdf}
              tlb={tlb ?? {}}
              editorStatus={
                editortype == "editor" || pathname?.includes("viewer")
              }
              setLaserLINKEditStatus={setLaserLINKEditStatus}
              showToolbar={tlb?.show_toolbar ?? true}
              showEditBtn={editortype == "preview" || editortype == "publish"}
              currentUserId={currentUser?.id}
              isPdfPublished={isPdfPublished}
              setIsPdfPublished={setIsPdfPublished}
              showThumbnail={tlb?.show_thumbnail ?? true}
              otherLanguageAvailable={docFiles?.length > 1}
            />
            <div>
              <div
                className={`${tlb && tlb?.show_toolbar == false ? "" : "editor-content"
                  } ${window.self != window.top ? "insideIframe" : ""} ${tlb?.enable_shadow_box ? "withShadow" : ""
                  }`}
                style={{ width: '100vw' }}
              >
                {/* sidebar section */}
                {openSideSection && (
                  <PdfSidebar
                    pdfImages={pdfImages}
                    pageNumber={pageNumber}
                  />
                )}
                {/* handle the pdf view component */}
                {pdfFile && (
                  <Viewer
                    zoom={zoomIndex}
                    url={`${window.location.origin.toString()}${selectedPdf
                      ? selectedPdf?.url ?? selectedPdf?.file?.url
                      : pdfFile?.file?.url
                      }`}
                    docFileId={selectedPdf?.id}
                    docId={doc?.id}
                    onLoadSuccess={onLoadSuccess}
                    statHook={statHook}
                    editortype={editortype}
                    docFiles={docFiles}
                    timerOnDoc={
                      editortype != "editor" ? false : doc?.timer_on_doc
                    }
                    showToolbar={tlb?.show_toolbar ?? true}
                    setZoomIndex={setZoomIndex}
                  />
                )}
              </div>
              {/* handled the edit toolbox */}
              {editortype === "editor" && (
                <DraggableComponent header={<ToolBoxHeading />}>
                  <EditorToolbox
                    doc={doc}
                    pdfFile={pdfFile}
                    otherLanguageAvailable={docFiles?.length > 1}
                    setOpenCloneModal={setOpenCloneModal}
                    selectedPdf={selectedPdf}
                    isPdfPublished={isPdfPublished}
                    setIsPdfPublished={setIsPdfPublished}
                  />
                </DraggableComponent>
              )}
            </div>
            {alertTextVisible && (
              <p className="pdf-alert-text">
                {tlb?.disable_settings?.disable_message}{" "}
                {tlb?.disable_settings?.disable_for}
              </p>
            )}
          </div>
        </>
      )}
    </EditorInfoContext.Provider>
  );
};

export default EditKdfPage;
