import React from "react"
import CommonModal from "../CommonModal";
import Button from "../../Elements/Button";
import { utilsUpdateLocalStorage } from "../../../utis/utilsUpdateLocalStorage";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import { networkService } from "../../config/axios";
const CookieConsentModal = ({ customizationText, concentModalStatus, setConcentModalStatus, sessId }) => {

    const handleCookieConsent = async (type) => {
        const payload = {
            authenticity_token: csrfToken(),
            cookie_status: type,
            session_id: sessId
        }
        await networkService(`viewer/cookie_consent`, "POST", payload)
    }
    return (
        <CommonModal
            className="req-concent"
            modalStatus={concentModalStatus}
            title={
                <img
                    src="/images/privacy@0,50x.jpg"
                    style={{ height: "20vh", width: "100%", minWidth: "600px" }}
                />
            }
            classList={"concent-modal"}
        >
            <p>
                {customizationText ? customizationText : 'This website may require cookies to function properly. We request your consent to use optional cookies to display video, audio, forms and other interactive elements'} Privacy Notice.
            </p>
            <div className="d-flex text-center justify-content-center mt-4 Act-btn">
                <Button
                    text={"Accept only essential cookies"}
                    addonClass={"my-2 me-3"}
                    onClick={() => {
                        setConcentModalStatus(false);
                        utilsUpdateLocalStorage("cookiesPrivacy", "essential");
                        handleCookieConsent("essential")
                    }}
                />
                <Button
                    text={"Accept all cookies"}
                    addonClass={"my-2"}
                    onClick={() => {
                        setConcentModalStatus(false);
                        utilsUpdateLocalStorage("cookiesPrivacy", "acceptAll");
                        handleCookieConsent("acceptAll")
                    }}
                />
            </div>
        </CommonModal>
    )
}
export default CookieConsentModal