import React, { useContext, useEffect, useRef, useState } from "react";
import noImage from "../../../../../assets/images/icon_upload.svg";
import Button from "../../../Elements/Button";
import CustomTooltip from "../../../Elements/Tooltip";
import { Col, Row } from "react-bootstrap";
import styles from "./KdfListCard.module.css";
import LaserLinkModal from "../../../Elements/LaserLink/LaserLinkModal";
import { networkService } from "../../../config/axios";
import UserInfoContext from "../../../../utis/userInfoContext";
import CustomTagsInput from "../../../CommonComponents/Tags/CustomTagsInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faRectangleXmark,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";

const KdfListCard = ({
  data,
  triggerUdateRecord,
  triggerUpdateRecordData,
  time_zone_list,
  forKey,
  triggerDeleteKDF,
  triggerCloneKDF,
  triggerMakePrivateEvent,
}) => {
  const [deletePDFActiveState, setDeletePDFActiveState] = useState(null);
  const [privatePDFActiveState, setPrivatePDFActiveState] = useState(null);
  const [tagsEditStatus, setTagsEditStatus] = useState(false);
  const [laserLINKEditStatus, setLaserLINKEditStatus] = useState(null);
  const [laserLINKBatchStatus, setLaserLINKBatchStatus] = useState(false);
  const [editableTitle, setEditableTitle] = useState({
    status: false,
    value: data?.title,
    id: data?.id,
  });
  const { setShowNotifications } = useContext(UserInfoContext);
  const editableRef = useRef(null);

  const handlePDFStatusEventTrigger = async () => {
    let recordActionData;
    if (deletePDFActiveState != null) {
      recordActionData = await customAPIRequest(
        `docs/${data.id}/files/${deletePDFActiveState}.json`,
        "DELETE",
        {}
      );
      if (recordActionData?.data != undefined) {
        triggerUdateRecord();
      }
      setDeletePDFActiveState(null);
    } else if (privatePDFActiveState != null) {
      // handleUpdateDocData({"doc_id": docs.id,"private_flag": docUploadData.docData.private_flag});
      triggerUdateRecord();
    }
  };

  const handleUpdateEditableTitleEvent = (e) => {
    setEditableTitle((prev) => ({ ...prev, value: e.target.value }));
  };

  const handleOutsideOrEnter = async () => {
    try {
      if (editableRef.current != null) {
        const { data, status } = await networkService(
          `kdfs/${editableTitle?.id}.json`,
          "PATCH",
          { doc: { title: editableRef.current.value } }
        );
        if (status == 200) {
          setEditableTitle((prev) => ({ ...prev, status: false }));
          triggerUdateRecord();
          setShowNotifications({
            showToast: true,
            toastType: "success",
            toastMessage: "Changes Saved",
          });
        }
      }
    } catch (error) {
      if (error.response.status == 422) {
        setEditableTitle((prev) => ({
          ...prev,
          status: false,
          value: data?.title,
        }));
        setShowNotifications({
          showToast: true,
          toastType: "danger",
          toastMessage:
            "Sorry, KDF titles must be unique. This title already exists in your KDF library.",
        });
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editableRef.current && !editableRef.current.contains(event.target)) {
        handleOutsideOrEnter();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleOutsideOrEnter();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div key={forKey}>
      <LaserLinkModal
        laserItem={laserLINKEditStatus}
        time_zone_list={time_zone_list}
        docData={data}
        triggerCancelProcess={(val) => {
          setLaserLINKEditStatus(val);
          triggerUdateRecord();
        }}
        batchStatus={laserLINKBatchStatus}
      />
      <Row
        className={`${styles.listWrapper} ${
          !data?.count_live_files > 0 ? styles.unPublished : ""
        } ${
          data?.private_flag == true && data?.user?.id != data?.current_user?.id
            ? styles.privateKDF
            : ""
        } gx-0`}
      >
        <Col className="text-center h-100" md={2}>
          <img
            src={
              data?.private_flag == true &&
              data?.user?.id != data?.current_user?.id
                ? "/assets/user-lock2.png"
                : data.thumb != ""
                ? data.thumb
                : noImage
            }
            alt={data.title}
            className={`${styles.thumbImage} ${
              data.thumb != "" ? "" : styles.noThumbImage
            } w-70`}
          />
          <Button
            icon={ <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
  >
    <mask
      id="mask0_10_75"
      width="21"
      height="20"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill="#D9D9D9" d="M.5 0h20v20H.5z"></path>
    </mask>
    <g mask="url(#mask0_10_75)">
      <path
        fill="#fff"
        d="M4.667 15.833h1.187L14 7.688 12.813 6.5l-8.146 8.146zM3 17.5v-3.542L14 2.98q.25-.229.552-.354t.636-.125.645.125q.314.125.542.375l1.146 1.167q.25.229.364.541a1.8 1.8 0 0 1 0 1.26 1.6 1.6 0 0 1-.364.553L6.54 17.5zM13.396 7.104l-.584-.604L14 7.688z"
      ></path>
    </g>
  </svg>}
            text={"Edit KDF"}
            classList={`${
              data?.count_active_files !== 0 ? "success" : "disabled"
            } full-width`}
            addonClass={"mt-2 edit-kdf-btn-list"}
            onClick={() =>
              data?.private_flag == true &&
              data?.user?.id != data?.current_user?.id
                ? ""
                : window.open(`/editor/${data?.id}`, `editor-${data?.id}`)
            }
            disabled={data?.count_active_files == 0}
          />
          {/* <div className="kdf-a">
            <a target="_blank" href={`/kdfs/${data?.id}`}>
              KDF Settings
            </a>
          </div> */}
        </Col>
        <Col md={10} className={styles.docWrapper}>
          <Row>
            <Col md={8} xs={12}>
              <div className={styles.docTitle}>
                {data?.private_flag == true &&
                data?.user?.id != data?.current_user?.id ? (
                  <CustomTooltip
                    title={
                      <p className="p-1 text-light">This is Private KDF</p>
                    }
                  >
                    <strong>Confidential... PRIVATE KDF</strong>
                  </CustomTooltip>
                ) : editableTitle.status ? (
                  <input
                    type="text"
                    value={editableTitle?.value}
                    ref={editableRef}
                    onChange={(e) => handleUpdateEditableTitleEvent(e)}
                  />
                ) : (
                  <p
                    onClick={() =>
                      setEditableTitle((prev) => ({ ...prev, status: true }))
                    }
                  >
                    {data?.title}
                  </p>
                )}
              </div>
              <p className={styles.docText}>
                <span>
                  Created by:{" "}
                  <span className={styles.createdBy}>
                    {" "}
                    {data.user?.name || " "}
                  </span>{" "}
                </span>
              </p>
              <p className={styles.docText}>
                <span
                  className={`ml-1 ${
                    data.private_permission ? "" : "blur_items"
                  }`}
                >
                  Total Pages:&nbsp;
                  <span className={styles.pagesCount}>
                    {data.num_pages === 1
                      ? `${data.num_pages} Page `
                      : `${data.num_pages ?? 0} Pages `}
                  </span>
                </span>
              </p>

              <p className={styles.docText}>
                <span>
                  Engagement Threshold:{" "}
                  <span className={styles.totalTime}>
                    {data.page_viewed_sec} seconds
                  </span>
                </span>
              </p>
              <p
                className="custom-tag-wrapper  d-flex kdf-tag-n"
                onClick={() =>
                  data?.private_flag == true &&
                  data?.user?.id != data?.current_user?.id
                    ? ""
                    : !tagsEditStatus && setTagsEditStatus(true)
                }
              >
                <span id="kdf-tags" className={styles.docText}>
                  {!tagsEditStatus ? (
                    data?.tags?.length > 0 ? (
                      <>
                        Tags:{" "}
                        <span id="kt-tag">
                          {data?.tags?.map((tag, tagInd) => (
                            <span
                              key={tagInd}
                              className="tag-primary text-white mx-1 rounded"
                            >
                              {tag.name}
                            </span>
                          ))}
                        </span>
                        {!data?.tracking_link?.privacy_policy_consent && (
                          <span className="ms-2">Consent Required</span>
                        )}
                      </>
                    ) : (
                      <>
                        <span
                          className="custom-tag-element mb-0"
                          style={{ textDecoration: "underline" }}
                          onClick={() =>
                            data?.private_flag == true &&
                            data?.user?.id != data?.current_user?.id
                              ? ""
                              : setTagsEditStatus(true)
                          }
                        >
                          {" "}
                          Add KDF Tags here
                        </span>
                        {!data?.tracking_link?.privacy_policy_consent && (
                          <span className="ms-2">Consent Required</span>
                        )}
                      </>
                    )
                  ) : (
                    // <CustomTags
                    //   updateValue={(value) =>
                    //     {
                    //       triggerUpdateRecordData(value, data, "tags")
                    //     }
                    //   }
                    //   tags={data?.tags}
                    //   handleCancelEvent={(status) => setTagsEditStatus(!status)}
                    // />
                    <>
                      {!data?.tracking_link?.privacy_policy_consent && (
                        <span className="ms-2">Consent Required</span>
                      )}
                      <CustomTagsInput
                        tags={data?.tags || []}
                        placeholderText="Add a new tag..."
                        handleAddTags={(value) => {
                          triggerUpdateRecordData(
                            [...(data?.tags || []), value],
                            data,
                            "tags"
                          );
                        }}
                        handleRemoveTags={(value) => {
                          triggerUpdateRecordData(
                            [...data?.tags?.filter((it) => it != value)],
                            data,
                            "tags"
                          );
                        }}
                      />
                      <CustomTooltip
                        title={
                          <p className="p-1 text-light">
                            You can add a tag by pressing enter, tab, delimiting
                            with comma or clicking outside the tags area
                          </p>
                        }
                      >
                        <FontAwesomeIcon
                          icon={faQuestion}
                          className="custom-tag-icon mx-2"
                        />
                      </CustomTooltip>
                      <FontAwesomeIcon
                        icon={faRectangleXmark}
                        onClick={() => setTagsEditStatus(false)}
                        className="cancel mx-2 edit-icon"
                      />
                    </>
                  )}
                </span>
              </p>
              <p className={styles.docText}>
                <span> Languages:&nbsp;</span>
                {data.doc_files_languages?.map((lanDoc, lanInd) => (
                  <span className={styles.lanFlag} key={lanInd}>
                    <span
                      className={lanDoc?.language?.class}
                      style={{
                        width: "22px",
                        borderRadius: "2px",
                        height: "16px",
                        marginRight: "5px",
                      }}
                    ></span>
                  </span>
                ))}
              </p>
            </Col>
            <Col md={4} xs={12}>
              <div className={styles.createlinkContainer}>
                <div className={styles.kdfStatus}>
                  <Button
                    text={"Create Laser LINK"}
                    classList={`${
                      data?.count_live_files == 0 ? "outline" : "success"
                    }`}
                    addonClass={""}
                    onClick={() =>
                      data?.count_live_files > 0
                        ? setLaserLINKEditStatus(true)
                        : setShowNotifications({
                            showToast: true,
                            toastType: "danger",
                            toastMessage:
                              "This KDF is not yet published, Therefore it can not be shared.",
                          })
                    }
                  />
                  {/* <button>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  >
                  <mask
                    id="mask0_14_101"
                    width="24"
                    height="24"
                    x="0"
                    y="0"
                    maskUnits="userSpaceOnUse"
                    style={{ maskType: "alpha" }}
                  >
                    <path fill="#D9D9D9" d="M0 0h24v24H0z"></path>
                  </mask>
                  <g mask="url(#mask0_14_101)">
                    <path fill="#fff" d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z"></path>
                  </g>
                </svg>
              &nbsp;Create LaserLink
              </button> */}
                  <span>
                    {data.count_live_files > 0 && !data.private_flag ? (
                      <CustomTooltip
                        title={
                          <p className="p-1 text-light">
                            {data?.published_status
                              ? "Published"
                              : "Unpublished"}{" "}
                            on {data.doc_date_updated} by {data.username}
                          </p>
                        }
                      >
                        <span className={styles.statusPublished}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <mask
                              id="mask0_14_157"
                              width="20"
                              height="20"
                              x="0"
                              y="0"
                              maskUnits="userSpaceOnUse"
                              style={{ maskType: "alpha" }}
                            >
                              <path fill="#D9D9D9" d="M0 0h20v20H0z"></path>
                            </mask>
                            <g mask="url(#mask0_14_157)">
                              <path
                                fill="#34C759"
                                d="m8.833 13.833 5.875-5.875-1.166-1.166L8.833 11.5 6.458 9.125l-1.166 1.167zm1.167 4.5a8.1 8.1 0 0 1-3.25-.656 8.4 8.4 0 0 1-2.646-1.781 8.4 8.4 0 0 1-1.781-2.646A8.1 8.1 0 0 1 1.667 10q0-1.73.656-3.25a8.4 8.4 0 0 1 1.781-2.646A8.4 8.4 0 0 1 6.75 2.323 8.1 8.1 0 0 1 10 1.667q1.73 0 3.25.656a8.4 8.4 0 0 1 2.646 1.781 8.4 8.4 0 0 1 1.781 2.646 8.1 8.1 0 0 1 .656 3.25 8.1 8.1 0 0 1-.656 3.25 8.4 8.4 0 0 1-1.781 2.646 8.4 8.4 0 0 1-2.646 1.781 8.1 8.1 0 0 1-3.25.656m0-1.666q2.792 0 4.73-1.938 1.936-1.937 1.937-4.729 0-2.791-1.938-4.73Q12.792 3.335 10 3.334q-2.792 0-4.73 1.938Q3.335 7.208 3.334 10q0 2.79 1.938 4.728T10 16.667"
                              ></path>
                            </g>
                          </svg>
                          {data.count_live_files > 0
                            ? "Published"
                            : "Unpublished"}
                        </span>
                      </CustomTooltip>
                    ) : data.count_live_files > 0 ? (
                      "Published"
                    ) : (
                      "Unpublished"
                    )}
                  </span>
                </div>
                <Dropdown className="kdfActions">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="29"
                      fill="none"
                      viewBox="0 0 9 29"
                    >
                      <circle
                        cx="4.5"
                        cy="3.5"
                        r="3.5"
                        fill="#333"
                        transform="rotate(90 4.5 3.5)"
                      ></circle>
                      <circle
                        cx="4.5"
                        cy="13.5"
                        r="3.5"
                        fill="#333"
                        transform="rotate(90 4.5 13.5)"
                      ></circle>
                      <circle
                        cx="4.5"
                        cy="23.5"
                        r="3.5"
                        fill="#333"
                        transform="rotate(90 4.5 23.5)"
                      ></circle>
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={`/kdfs/${data?.id}`}>
                      KDF Settings
                    </Dropdown.Item>
                    
                    <Dropdown.Item>
                      <span
                        className="text-link cursor-pointer d-block"
                        onClick={() =>
                          data?.private_flag == true &&
                          data?.user?.id != data?.current_user?.id
                            ? ""
                            : triggerDeleteKDF(data.id)
                        }
                      >
                        Delete this KDF
                      </span>
                    </Dropdown.Item>
                    
                    <Dropdown.Item>
                      <span
                        className="text-link cursor-pointer d-block"
                        onClick={() =>
                          data?.private_flag == true &&
                          data?.user?.id != data?.current_user?.id
                            ? ""
                            : triggerCloneKDF(data.id)
                        }
                      >
                        Clone this KDF
                      </span>
                    </Dropdown.Item>

   
                      {data?.user?.id == data?.current_user?.id && (
                      <Dropdown.Item>
                        <CustomTooltip
                          title={
                            <p className="p-1 text-light">
                              Prevent other Kinetizine users from viewing and
                              editing this KDF
                            </p>
                          }
                        >
                          <span
                            className="text-link cursor-pointer d-block"
                            onClick={() =>
                              data?.private_flag == true &&
                              data?.user?.id != data?.current_user?.id
                                ? ""
                                : triggerMakePrivateEvent({
                                    id: data.id,
                                    status: data?.private_flag,
                                  })
                            }
                          >
                            Make{" "}
                            {data?.private_flag == true ? "Public" : "Private"}
                          </span>
                        </CustomTooltip>
                        </Dropdown.Item>
                      )}

                    <Dropdown.Item>
                      <CustomTooltip
                        title={
                          <p className="p-1 text-light">
                            Generate Multiple LaserLINX using a spreadsheet
                          </p>
                        }
                      >
                        <a
                          className="link-success"
                          onClick={() =>
                            data?.private_flag == true &&
                            data?.user?.id != data?.current_user?.id
                              ? ""
                              : data?.count_live_files > 0 &&
                                (setLaserLINKEditStatus(true),
                                setLaserLINKBatchStatus(true))
                          }
                        >
                          Gen. MultiLINX
                        </a>
                      </CustomTooltip>
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="pl-0">
              {parseInt(data.stats.viewers) > 0 && (
                <div className="kdf-list-pages">
                  <CustomTooltip
                    title={
                      <div
                        className="p-2"
                        dangerouslySetInnerHTML={{
                          __html: data?.view_stats ?? "",
                        }}
                      />
                    }
                  >
                    <a
                      href={`/kdf-stats/${data?.group_id}#/views`}
                      className={`${styles.statsLink} my-1 me-1 text-center custom-link-success`}
                    >
                      <strong className="kdf-score-l">
                        {data.stats.viewers}
                      </strong>
                      <span>Visits</span>
                    </a>
                  </CustomTooltip>
                  <a
                    href={`/kdf-stats/${data?.group_id}#/at-a-glance`}
                    className={`${styles.statsLink} my-1 mx-2 text-center custom-link-success`}
                  >
                    <CustomTooltip
                      title={
                        <p className="p-1 text-light">
                          {data?.stats?.min_viewed_tooltip}
                        </p>
                      }
                    >
                      <strong className="kdf-score-l">
                        {data.stats.min_viewed}
                      </strong>
                    </CustomTooltip>
                    <CustomTooltip
                      title={
                        <p className="p-1 text-light">
                          Avarage time spent viewing this KDF was{" "}
                          {data?.stats?.min_viewed_secs}
                        </p>
                      }
                    >
                      <span>Duration</span>
                    </CustomTooltip>
                  </a>
                  <a
                    href={`/kdf-stats/${data?.group_id}#/at-a-glance`}
                    className={`${styles.statsLink} my-1 mx-2 text-center custom-link-success`}
                  >
                    <strong className="kdf-score-l">
                      {data.stats.percent_viewed}
                    </strong>
                    <span>Viewed</span>
                  </a>
                  <CustomTooltip
                    title={
                      <p className="p-1 text-light">
                        {data?.opt_in_reject} Bounced
                      </p>
                    }
                  >
                    <a
                      href={`/kdf-stats/${data?.group_id}#/viewers`}
                      className={`${styles.statsLink} my-1 mx-2 text-center custom-link-success`}
                    >
                      <strong className="kdf-score-l">
                        {data.stats.unique_viewers}
                      </strong>
                      <span>Opt-in</span>
                    </a>
                  </CustomTooltip>
                  <a
                    href={`/kdf-stats/${data?.group_id}#/views`}
                    className={`${styles.statsLink} my-1 mx-2 text-center custom-link-success`}
                  >
                    <strong className="kdf-score-l">
                      {data.stats.engagement?.replace("%", "")}
                    </strong>
                    <span>Score</span>
                  </a>
                  <a
                    href={`/kdf-stats/${data?.group_id}#/at-a-glance`}
                    className={`${styles.statsLink} my-1 me-1 text-center custom-link-success`}
                  >
                    <strong className="kdf-score-l">
                      {data.stats.last_viewed}
                    </strong>
                    <span>Last Viewed</span>
                  </a>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        {/* <Col md={2}> */}
        {/* {data.doc_files_languages?.map((lanDoc, lanInd) => (
                <div key={lanInd}>
                  <div className={lanDoc?.language?.class} style={{
                    width: "22px",
                  }}></div>
                </div>
              ))} */}
        {/* </Col> */}

        {/* <Col md={3} className="LASER-CREATE">
          <Button
            text={"Create Laser LINK"}
            classList={`${data?.count_live_files == 0 ? "outline" : "success"}`}
            addonClass={"mb-2"}
            onClick={() =>
              data?.count_live_files > 0 ? setLaserLINKEditStatus(true) : setShowNotifications({
                showToast: true,
                toastType: "danger",
                toastMessage: 'This KDF is not yet published, Therefore it can not be shared.',
              })
            }
          />
          {
            data?.tracking_link_count > 0 &&
            <a href={`/links/doc/${data?.group_id}?filter=0`}>LaserLINK ({data?.tracking_link_count})</a>
          }
          <div className="laser-popups mt-1">

            <span
              className="text-link cursor-pointer d-block"
              onClick={() => data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (triggerDeleteKDF(data.id))}
            >
              Delete this KDF
            </span>
            <CustomTooltip title={<p className="p-1 text-light">Clone KDF</p>}>
              <span
                className="text-link cursor-pointer d-block"
                onClick={() => data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (triggerCloneKDF(data.id))}
              >
                Clone this KDF
              </span>
            </CustomTooltip>
            {
              data?.user?.id == data?.current_user?.id &&
              <CustomTooltip
                title={
                  <p className="p-1 text-light">Prevent other Kinetizine users from viewing and editing this KDF</p>
                }
              >
                <span
                  className="text-link cursor-pointer d-block"
                  onClick={() =>
                    data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (triggerMakePrivateEvent({
                      id: data.id,
                      status: data?.private_flag,
                    }))
                  }
                >
                  Make {data?.private_flag == true ? "Public" : "Private"}
                </span>
              </CustomTooltip>
            }
            <CustomTooltip
              title={
                <p className="p-1 text-light">Generate Multiple LaserLINX using a spreadsheet</p>
              }
            >
              <a
                className="link-success"
                onClick={() =>
                  data?.private_flag == true && data?.user?.id != data?.current_user?.id ? '' : (data?.count_live_files > 0 &&
                    (setLaserLINKEditStatus(true),
                      setLaserLINKBatchStatus(true)))
                }
              >
                Gen. MultiLINX
              </a>
            </CustomTooltip>
          </div>
        </Col> */}
      </Row>
    </div>
  );
};
export default KdfListCard;
