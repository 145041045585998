import React, { useContext, useState } from "react";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import PdfElement from "../pdfEditorElements/PdfElement";
import { getNormalizedDimensions, normalizeDimensions } from "../../../../../utis/helper";

const PdfElementsList = ({ timerOnDoc, setVideoData, actionCableVideoMontior, elemClick, zoom, docFileId, index, canvasRefs, editortype, hoveredId, setJumpPageNum }) => {
    const {
        elements,
        pageNumber,
        setPageNumber,
        pageDimensions
    } = useContext(EditorInfoContext);

    const [resizeDimensions, setResizeDimensions] = useState({});
    const [actionElement, setActionElement] = useState({});

    const arrayOfElements = Object.values(Object.entries(elements).length !== 0 && elements);

    const handleAction = (element) => {
        const { desiredaction, thisElement } = element?.dropdown_data;
        if (element?.dropdown_data && desiredaction && thisElement) {
            setActionElement({
                ...actionElement,
                [thisElement == 'thisElement' ? element?.id : thisElement]: {
                    targetElement: thisElement == 'thisElement' ? element?.id : thisElement,
                    visibility:
                        desiredaction === "hide"
                            ? false
                            : desiredaction === "reveal"
                                ? true
                                : actionElement[thisElement]?.visibility == undefined ? false : !actionElement[thisElement]?.visibility
                },
            });
        }
    };

    const handlePDFChangeAction = (value) => {
        if (value == pageNumber) {
            const allCanvasElements = document.getElementsByClassName('react-pdf__Page');
            const canvasArray = Array.from(allCanvasElements);
            const targetCanvas = canvasArray.find((canvas) => canvas.getAttribute('data-page-number') === `${value}`);
            if (targetCanvas) {
                targetCanvas.scrollIntoView({ behavior: "instant" });
            }
        }
        setPageNumber(value);
    }
    return (
        <>
            {arrayOfElements &&
                arrayOfElements?.map((element) => {
                    if ((docFileId == element?.docs_file_id) &&
                        element?.geometry?.top !== null &&
                        element?.geometry?.left !== null &&
                        element?.geometry?.height !== null &&
                        element?.geometry?.top !== null &&
                        index + 1 === element?.page) {
                        const { geo } = getNormalizedDimensions(element.geometry, zoom, pageDimensions, element);
                        const dimensions = {
                            top: editortype == 'stats-viewer' ? normalizeDimensions(element?.geometry?.top, window.objInit?.zoom_lvl, zoom?.value) : geo?.top,
                            left: editortype == 'stats-viewer' ? normalizeDimensions(element?.geometry?.left, window.objInit?.zoom_lvl, zoom?.value) : geo?.left,
                            height: editortype == 'stats-viewer' ? normalizeDimensions(element?.geometry?.height, window.objInit?.zoom_lvl, zoom?.value) : geo?.height,
                            width: editortype == 'stats-viewer' ? normalizeDimensions(element?.geometry?.width, window.objInit?.zoom_lvl, zoom?.value) : geo?.width,
                        };

                        return (
                            <div
                                id={'div' + element?.id}
                                key={element?.id}
                                style={{
                                    ...geo,
                                    position: "absolute",
                                    userSelect: 'none',
                                    zIndex: Number(99) + Number(element?.order_on_page),
                                    top: `${resizeDimensions[element.id]?.top == undefined ? dimensions?.top : resizeDimensions[element.id]?.top}px`,
                                    left: `${resizeDimensions[element.id]?.left == undefined ? dimensions?.left : resizeDimensions[element.id]?.left}px`,
                                    width: `${resizeDimensions[element.id]?.width == undefined ? dimensions?.width : resizeDimensions[element.id]?.width}px`,
                                    height: `${resizeDimensions[element.id]?.height == undefined ? dimensions?.height : resizeDimensions[element.id]?.height}px`,
                                    ...(Number(element?.id) ===
                                        Number(actionElement[element?.id]?.targetElement) && {
                                        visibility: `${actionElement[element?.id]?.visibility ? "visible" : "hidden"}`,
                                    }),
                                    transform: `rotate(${element?.rotate ?? 0}deg)`,
                                    backgroundColor: hoveredId === element?.id ? "rgba(255, 255, 127, 0.9)" : 'transparent',
                                    transform: hoveredId === element.id ? 'scale(1.1)' : 'scale(1)',
                                    transition: 'transform 0.3s ease, background-color 0.3s ease',
                                }}
                                onClick={() => element?.dropdown_data && handleAction(element)}
                            >
                                <PdfElement
                                    element={element}
                                    canvasRef={canvasRefs?.current[index]}
                                    zoom={zoom}
                                    setVideoData={setVideoData}
                                    actionCableVideoMontior={actionCableVideoMontior}
                                    dimensions={dimensions}
                                    setPageNumber={(value) => handlePDFChangeAction(value)}
                                    editortype={editortype}
                                    setResizeDimensions={setResizeDimensions}
                                    resizeDimensions={resizeDimensions}
                                    timerOnDoc={timerOnDoc}
                                    elemClick={elemClick}
                                    setJumpPageNum={setJumpPageNum}
                                    handleAction={handleAction}
                                />
                            </div>
                        );
                    }
                })}
        </>
    )
}

export default PdfElementsList;