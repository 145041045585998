import React from "react"
import styles from '../Viewer/Viewer.module.css'
import CustomTooltip from "../../../Elements/Tooltip";

const KdfSessHeader = ({ viewDt, sessionIndex }) => {
    const getLatestRecord = (data) => {
        return data?.reduce((latest, current) => {
            const currentDate = new Date(current.date.replace("IST", "").trim());
            const latestDate = new Date(latest.date.replace("IST", "").trim());
            return currentDate > latestDate ? current : latest;
        }, data[0]);
    };

    return (
        <div className={styles.headerCard}>
            <h5>{viewDt?.name || "-"}</h5>
            <hr />
            {viewDt?.sessions?.map(
                (session, idx) =>
                    idx == sessionIndex && (
                        <div key={idx}>
                            <div className={styles.locationInfo}>
                                <CustomTooltip
                                    title={
                                        <p className="p-1 text-light">
                                            {viewDt?.name || "-"} viewed this KDF on{" "}
                                            {session?.date} via {session?.ip} in{" "}
                                            {session?.country}
                                        </p>
                                    }
                                >
                                    <>
                                        <span>
                                            KDF viewed on: {session.date?.split(" ")[0]}
                                        </span>
                                        <div
                                            style={{
                                                width: "22px",
                                            }}
                                            className={`flag-icon flag-icon-${session?.ip_details?.country_code?.toLowerCase()}`}
                                        ></div>
                                    </>
                                </CustomTooltip>
                                <span>
                                    {session?.ip_details?.city},{" "}
                                    {session?.ip_details?.subdivision_code}
                                </span>
                            </div>
                            <div className={styles.locationInfo}>
                                <CustomTooltip
                                    title={
                                        <p className="p-1 text-light">
                                            {viewDt.name || "-"} viewed this KDF on{" "}
                                            {getLatestRecord(session?.pages)?.date} via{" "}
                                            {session?.ip} in {session?.country}
                                        </p>
                                    }
                                >
                                    <>
                                        <span>
                                            Last seen:{" "}
                                            {
                                                getLatestRecord(
                                                    session?.pages
                                                )?.date?.split(" ")[0]
                                            }
                                            <div
                                                style={{
                                                    width: "22px",
                                                }}
                                                className={`flag-icon flag-icon-${session?.ip_details?.country_code?.toLowerCase()}`}
                                            ></div>
                                        </span>
                                    </>
                                </CustomTooltip>
                                <span>
                                    {session?.ip_details?.city},{" "}
                                    {session?.ip_details?.subdivision_code}
                                </span>
                            </div>
                        </div>
                    )
            )}
        </div>
    )
}
export default KdfSessHeader