import React, { useEffect, useState } from "react";
import Paginate from "../../CommonComponents/Pagination/Paginate";
import utilsUploadFormData from "../../../utis/upoadFormData";
import LoadingScreen from "../../Elements/LoadingScreen/LoadingScreen";
import LinkCard from "./LinkCard";
import { Container } from "react-bootstrap";
import LaserLinkModal from "../../Elements/LaserLink/LaserLinkModal";
import { networkService } from "../../config/axios";
import CommonModal from "../../Modals/CommonModal";
import Button from "../../Elements/Button";
import LinksPageFilter from "./LinksPageFilter";
import customAPIRequest from "../../../utis/customAPIRequest";
import { useLocation } from "react-router-dom";

const LinkPage = ({ time_zone_list }) => {
  const [linkListData, setLinkListData] = useState({ loading: true, data: [] });
  const [selectedKDFLink, setSelectedKDFLink] = useState(null);
  const [getDeleteLaserLinkItem, setDeleteLaserLinkItem] = useState(null);
  const itemsPerPage = 10;
  const { pathname } = useLocation();
  const updateLaserLinksData = async (page = 1) => {
    if (page >= 1) {
      setLinkListData((prev) => ({ ...prev, loading: true }));
    }
    let getValue = {
      page: page,
      per_page: itemsPerPage,
      is_filter: true,
    };
    if (pathname?.includes('links/doc')) {
      getValue['doc_name'] = window?.objDoc?.title;
    } else if (pathname.includes('links/tl')) {
      let path = pathname;
      let token = path?.split('/').pop();
      getValue['token'] = token;
    }
    let { data } = await utilsUploadFormData(
      "links/search.json",
      getValue,
      "POST"
    );
    setLinkListData({
      ...data,
      loading: false,
      page: page,
      per_page: itemsPerPage,
    });
  };

  const handleCopyLaserLinkEvent = async (listingID) => {
    let { data } = await networkService(
      `/links/clone/${listingID}.json`,
      "GET"
    );
    if (data.msg == "ok") {
      updateLaserLinksData();
    }
  };

  useEffect(() => {
    updateLaserLinksData();
  }, []);

  const handleDeleteLaserLinkItemEvent = async () => {
    let { status } = await networkService(
      `/links/${getDeleteLaserLinkItem}.json`,
      "DELETE"
    );
    if (status == 204) {
      updateLaserLinksData();
      setDeleteLaserLinkItem(null);
    }
  };

  const handleUpdateLaserLinkData = async (docData, dataPayload) => {
    let { status } = await networkService(
      `/links/${docData.id}.json`,
      "PATCH",
      {
        id: docData?.id,
        name: docData?.name,
        doc_id: docData?.doc.id,
        tl: {},
        tag_ids: [...dataPayload],
      }
    );
    if (status == 200) {
      updateLaserLinksData();
    }
  };

  const handleFiltersApplyEvent = async (payload) => {
    let formPayload = {
      page: linkListData?.page ?? 1,
      per_page: linkListData?.per_page ?? itemsPerPage,
      is_filter: true,
      ...payload,
    };
    let linkListALlData = await customAPIRequest(
      "links/search.json",
      "POST",
      formPayload
    );
    setLinkListData({
      page: linkListData.page,
      per_page: linkListData.per_page,
      ...linkListALlData.data,
    });
  };

  return (
    <>
      <LaserLinkModal
        laserItem={selectedKDFLink}
        time_zone_list={time_zone_list}
        updateSelectedKDFLink={setSelectedKDFLink}
        triggerUpdateList={() => handleFiltersApplyEvent()}
      />
      <CommonModal
        title={"Delete LaserLINK"}
        modalStatus={getDeleteLaserLinkItem != null}
        changeActiveStatus={() => setDeleteLaserLinkItem(null)}
        centered
        classList={"upload-kdf-modal-wrapper alert-modal"}
        footer={
          <div className="d-flex m-auto">
            <Button
              text={"Cancel"}
              classList={"alert delete-btn"}
              addonClass={"my-2 mx-2"}
              onClick={() => setDeleteLaserLinkItem(null)}
            />
            <Button
              text={"Delete"}
              classList={"success delete-btn"}
              addonClass={"my-2 mx-2"}
              onClick={handleDeleteLaserLinkItemEvent}
            />
          </div>
        }
      >
        <p style={{ fontSize: "14px" }}>
          Are you sure you want to delete this LaserLINK? This action cannot be
          undone.
        </p>
      </CommonModal>
      <div className="header-content-main">
        <div className="doc-filter">
          <h4 className="filter-title">Filters</h4>
          <LinksPageFilter triggerFiltersApplyEvent={handleFiltersApplyEvent} kdf={{ 'value': window?.objDoc?.title || '' }} />
        </div>
      </div>
      {linkListData.loading ? (
        <LoadingScreen />
      ) : linkListData?.tracking_links?.length > 0 ? (
        <Container style={{ maxWidth: "800px", margin: "30px auto 5rem" }}>
          <Paginate
            currentPage={linkListData?.page}
            totalPages={Math.ceil(linkListData?.cnt / linkListData?.per_page)}
            onPageChange={updateLaserLinksData}
            totalData={linkListData?.cnt}
            itemTitle={linkListData?.cnt > 1 ? "LaserLINX" : "LaserLINK"} 
          />
          {linkListData?.tracking_links?.map((linkData, linkDataIndex) => (
            <LinkCard
              data={linkData}
              key={linkDataIndex}
              trigerCopyLaserLinkEvent={handleCopyLaserLinkEvent}
              triggerSettingsLaserLinkEvent={setSelectedKDFLink}
              triggerDeleteLaserLinkEvent={setDeleteLaserLinkItem}
              updateLinkData={handleUpdateLaserLinkData}
              triggerUdateRecord={updateLaserLinksData}
            />
          ))}
          <Paginate
            currentPage={linkListData?.page}
            totalPages={Math.ceil(linkListData?.cnt / linkListData?.per_page)}
            onPageChange={updateLaserLinksData}
            totalData={linkListData?.cnt}
            itemTitle={linkListData?.cnt > 1 ? "LaserLINX" : "LaserLINK"} 
          />
        </Container>
      ) : (
        <div className="alert w-100 d-flex p-4">
          Sorry, We tried our best but could NOT find a LaserLINK matching the
          filter you used. Please reset the filter and try again.
        </div>
      )}
    </>
  );
};

export default LinkPage;
