import React, { useEffect, useState } from "react";
import DragDropComponent from "../CommonComponents/DragDropComponent/DragDropComponent";
import "./UploadKdfModal.css";
import { quality_data } from "../../hooks/utils";
import apiGETRequest from "../../utis/apiRequest";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import Button from "../Elements/Button";
import { csrfToken } from "../../utis/csrfTokenUtils";
import CustomDropdown from "../CommonComponents/CustomDropDown/CustomDropDown";
import "../../../assets/stylesheets/flags.css";

const UploadKdfModal = ({
  closeModal,
  userId,
  docId,
  refreshPDFList,
  selectedFile = null,
}) => {
  const [progressCount, setProgressCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState("");
  const [payload, setPayload] = useState({
    language: "",
    quality: "",
  });
  const [languages, setLanguages] = useState([]);

  const UploadKdf = async () => {
    setProgressCount(10);
    setLoading(true)
    const formData = new FormData();
    formData.append("authenticity_token", csrfToken());
    formData.append("docs_file[doc_id]", docId);
    formData.append("docs_file[user_id]", userId);
    formData.append("docs_file[file_cache]", "");
    formData.append(
      "docs_file[language]",
      payload.language != "" ? payload.language : "en-US"
    );
    formData.append("docs_file[quality]", payload.quality);
    formData.append("docs_file[file]", file[0]);
    if (selectedFile != null) {
      formData.append("_method", "patch");
    }

    try {
      setProgressCount(50);
      const response = await fetch(
        selectedFile != null
          ? `/docs/${docId}/files/${selectedFile?.id}.json`
          : `/docs/${docId}/files.json`,
        {
          method: "POST",
          body: formData,
          cookie: {
            "X-CSRF-Token": csrfToken(),
          },
        }
      );
      if (response.ok) {
        setProgressCount(100);
        closeModal(false);
        refreshPDFList(true);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setProgressCount(0);
    }
  };

  const handleOnchangeEvent = (e) => {
    setPayload((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languages = await apiGETRequest(`docs/${docId}/languages.json`);
        const languageArray = Object.values(languages);
        setLanguages(languageArray);
        setPayload({
          language: selectedFile == null ? "en-US" : '',
          quality: quality_data()[0].value ?? "",
        });
      } catch (error) {
        // console.error('Error fetching languages:', error);
      }
    };
    fetchLanguages();
  }, []);

  return (
    <>
      <div className="domain-main">
        {!file ? (
          <DragDropComponent setFile={setFile} />
        ) : (
          <Container className="mb-0">
            <Row>
              <Col md={4}>
                <p>{file[0]?.path.split("/")[1]}</p>
                <p>{(file[0]?.size / 1024)?.toFixed(2)} KB</p>
              </Col>
              <Col md={4}>
                <CustomDropdown
                  options={languages?.map((lan) => {
                    return {
                      value: lan.locale,
                      label: lan.name,
                      class: lan.class,
                    };
                  })}
                  name={"language"}
                  onSelect={(e) =>
                    handleOnchangeEvent({
                      target: { name: "language", value: e },
                    })
                  }
                  selected={payload.language == '' ? selectedFile?.language : payload?.language}
                  placeholder="Select an Language"
                />
              </Col>
              <Col md={4}>
                <CustomDropdown
                  options={quality_data()?.map((quality) => {
                    return {
                      value: quality.value,
                      label: quality.name,
                    };
                  })}
                  name={"quality"}
                  onSelect={(e) =>
                    handleOnchangeEvent({
                      target: { name: "quality", value: e },
                    })
                  }
                  selected={payload.quality}
                  placeholder="Select a Quality"
                />
              </Col>
            </Row>
            <Col className="text-center">
              {progressCount > 0 && (
                <ProgressBar
                  className={`my-4`}
                  now={progressCount}
                  label={`${progressCount}%`}
                  striped
                  variant="success"
                />
              )}
              <Button
                text={"Upload"}
                addonClass="p-5"
                onClick={UploadKdf}
                classList={loading ? "disabled" : "success"}
              />
            </Col>
          </Container>
        )}
      </div>
    </>
  );
};

export default React.memo(UploadKdfModal);
