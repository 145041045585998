import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { csrfToken } from "../../../../../../../utis/csrfTokenUtils";
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext";
import CustomInput from "../../../../../../CommonComponents/CustomForm/CustomInputField";
import { networkService } from "../../../../../../config/axios";
import Button from "../../../../../../Elements/Button";
import useEditorHook from "../../../editorHook/useEditorHook";
import EmbedSection from "../embedSection/EmbedSection";
import TextEditorSection from "../textEditorSection/TextEditorSection";
import VideoSection from "../videoSection/VideoSection";
import ImageSlider from "./ImageSlider";
import LightBoxElement from "./LightBoxElement";
import LightBoxTrigger from "./LightBoxTrigger";

const LightBoxSetting = ({ docFileId, docId, key, setKey, handleClose }) => {
    const { editLightBox, imageElement, setImageElement, setShowToast } = useContext(EditorInfoContext)
    const docData = {
        doc_id: docId,
        id: docFileId
    }
    const { tool, handleElementUpload, uploadElement, setTool, editImageInSlider, handleTextChange } = useEditorHook(null, docData)
    const inputRef = useRef(null);

    const initialState = {
        authenticity_token: csrfToken(),
        display: "",
        doc_id: docId,
        docs_file_id: docFileId,
        height: 300,
        name: '',
        page: 1,
        show_after: '',
        show_on_page: "",
        show_slide_desc: "",
        slide_interval: "",
        slide_show: false,
        width: 300,
        priority: false
    }

    const [lightBoxPayload, setLightBoxPayload] = useState(initialState)
    const [elementName, setElementName] = useState('')
    const [error, setError] = useState({
        fieldError: '',
        elementError: ''
    })
    const [buttonType, setButtonType] = useState('')

    const getElementType = (data) => {
        if (data) {
            const [{ element_type, value }] = Object.values(data);
            return { element_type, value };
        }
    }

    function getId(data) {
        const [id] = Object.keys(data);
        return id;
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setError({
            elementError: '',
            fieldError: ''
        })
        if (name === 'element') {
            setLightBoxPayload((prev) => {
                const { elements, ...rest } = prev;
                return {
                    ...rest,
                    [name]: type === 'checkbox' ? checked : value,
                };
            });
            setElementName(value)
        } else {
            setLightBoxPayload(prev => ({
                ...prev,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    }

    const createLightBox = async (type) => {
        let lightBoxData = await networkService(`docs/lightboxes.json`, 'POST', lightBoxPayload);
        if (lightBoxData?.status === 201) {
            setShowToast({
                showToast: true,
                toastType: 'success',
                toastMessage: "Lightbox saved. Please republish this KDF."
            })
            setTool({
                ...tool,
                doc_id: docId,
                docs_file_id: docFileId,
                docs_lightbox_id: lightBoxData?.data?.id
            })
            if (elementName === 'image') {
                arrayOfImageElements?.forEach(async (item) => {
                    const data = {
                        ...item,
                        docs_lightbox_id: lightBoxData?.data.id
                    };
                    const response = await editImageInSlider(item?.id, data);
                    if (response?.status == 200) {
                        if (type == 'save') {
                            setKey('list');
                        } else if (type == 'close') {
                            handleClose();
                        }
                    }
                });
            }
            setImageElement({})
        } else {
            setShowToast({
                showToast: true,
                toastType: 'danger',
                toastMessage: "Something went wrong"
            })
        }
    };

    const updateLightBox = async (type) => {
        let lightBoxData = await networkService(`docs/lightboxes/${editLightBox?.id}.json`, 'PATCH', lightBoxPayload);
        if (lightBoxData?.status === 200) {
            setTool({
                ...tool,
                doc_id: docId,
                docs_file_id: docFileId,
                docs_lightbox_id: lightBoxData?.data?.id
            })
            setShowToast({
                showToast: true,
                toastType: 'success',
                toastMessage: "Lightbox created"
            })
            let elemId = lightBoxPayload?.elements && getId(lightBoxPayload?.elements)
            if (elementName === 'image' || getElementType(lightBoxPayload?.elements)?.element_type === 4) {
                arrayOfImageElements?.forEach(async (item) => {
                    const data = {
                        ...item,
                        docs_lightbox_id: lightBoxData?.data?.id
                    };
                    const response = await editImageInSlider(item?.id, data);
                    if (response.status == 200) {
                        if (type == 'save') {
                            setKey('list');
                        } else if (type == 'close') {
                            handleClose()
                        }
                    }
                });
            } else {
                uploadElement(elemId, elementName)
                if (type == 'save') {
                    setKey('list');
                } else if (type == 'close') {
                    handleClose()
                }
            }
            setImageElement({})
        }
    }

    const handleLightBox = (type) => {
        if (!lightBoxPayload?.name) {
            setError({ ...error, fieldError: 'Please add the lightbox name' })
        } else if (!elementName ? !getElementType(lightBoxPayload?.elements)?.element_type : false) {
            setError({ ...error, elementError: 'Please select the Element' })
        } else {
            if (editLightBox?.id) {
                updateLightBox(type)
            } else {
                createLightBox(type)
            }
        }
    }

    useEffect(() => {
        if (tool?.docs_lightbox_id && elementName !== 'image' && getElementType(lightBoxPayload?.elements)?.element_type !== 4) {
            uploadElement(null, elementName)
            if (buttonType == 'save') {
                setKey('list');
            } else if (buttonType == 'close') {
                handleClose()
            }
        }
    }, [tool])

    useEffect(() => {
        if (editLightBox?.id) {
            setLightBoxPayload(editLightBox)
            if (getElementType(editLightBox?.elements)?.element_type === 4) {
                setImageElement(editLightBox?.elements)
            }
        } else {
            setImageElement({})
            setLightBoxPayload({ ...initialState })
        }
    }, [editLightBox])

    const arrayOfImageElements = useMemo(() => {
        return Object.values(
            Object.entries(imageElement).length !== 0 ? imageElement : {}
        );
    }, [imageElement]);

    useEffect(() => {
        if (key === "setting") {
            inputRef.current?.focus();
        }
    }, [key]);

    const displayElement = () => {
        switch (true) {
            case elementName === 'video' || getElementType(lightBoxPayload?.elements)?.element_type === 5:
                return <VideoSection
                    text={'Please add the embed code provided by youtube or vimeo. Link must contain http or https.'}
                    handleVideoUrlUpload={handleElementUpload}
                    videoLink={tool?.value?.video_link ?? getElementType(lightBoxPayload?.elements)?.value?.video_link}
                    type={'video'}
                />
            case elementName === 'audio' || getElementType(lightBoxPayload?.elements)?.element_type === 8:
                return <VideoSection
                    text={'Please add the embed code provided by youtube. Link must contain http or https.'}
                    handleVideoUrlUpload={handleElementUpload}
                    videoLink={tool?.value?.video_link ?? getElementType(lightBoxPayload?.elements)?.value?.video_link}
                    type={"audio"}
                />
            case elementName === 'embed' || getElementType(lightBoxPayload?.elements)?.element_type === 6:
                return <EmbedSection
                    handleEmbedUrlUpload={handleElementUpload}
                    siteUrl={tool?.value?.video_link ?? getElementType(lightBoxPayload?.elements)?.value?.video_link}
                    preventScroll={tool?.value?.no_scroll ?? getElementType(lightBoxPayload?.elements)?.value?.no_scroll}
                />
            case elementName === 'text' || getElementType(lightBoxPayload?.elements)?.element_type === 7:
                return <TextEditorSection
                    handleTextChange={handleTextChange}
                    html_text={tool?.value?.html_text ?? getElementType(lightBoxPayload?.elements)?.value?.html_text}
                />
            case elementName === 'html' || getElementType(lightBoxPayload?.elements)?.element_type === 12:
                return <TextEditorSection
                    handleTextChange={handleTextChange}
                    html_text={tool?.value?.html_text ?? getElementType(lightBoxPayload?.elements)?.value?.html_text}
                />
        }
    }

    return (
        <div className="image-popup">
            <div className="popup-content">
                <div className="animation-select-top">
                    <CustomInput
                        ref={inputRef}
                        placeholder={"Please Enter Lightbox Name"}
                        value={lightBoxPayload?.name}
                        name={'name'}
                        onChange={handleChange}
                        required={true}
                        autoFocus={true}
                        type={'text'}
                        error={error?.fieldError}
                    />
                </div>
                <div className="form-layout">
                    <LightBoxTrigger
                        lightBoxPayload={lightBoxPayload}
                        handleChange={handleChange}
                    />
                    <LightBoxElement
                        lightBoxPayload={lightBoxPayload}
                        elementName={elementName}
                        handleChange={handleChange}
                        getElementType={getElementType}
                        arrayOfImageElements={arrayOfImageElements}
                    />
                </div>
                {
                    (elementName === 'image' || getElementType(lightBoxPayload?.elements)?.element_type === 4) &&
                    <div className="d-flex align-items-center justify-content-around animation-select">
                        {
                            arrayOfImageElements?.length > 1 ?
                                <ImageSlider arrayOfImageElements={arrayOfImageElements} showIcons={true} /> :
                                (arrayOfImageElements[0]?.file_url || arrayOfImageElements[0]?.value?.gif_link) && <img
                                    src={arrayOfImageElements[0]?.file_url ?? arrayOfImageElements[0]?.value?.gif_link}
                                    alt="image"
                                    style={{
                                        height: 150,
                                        width: 170,
                                    }}
                                />
                        }
                        <div className="d-flex flex-column">
                            <div className="select-setting-lower mb-2">
                                <label className="lightbox-label" htmlFor="">Width:</label>
                                <input
                                    type="number"
                                    name='width'
                                    onChange={handleChange}
                                    value={lightBoxPayload?.width}
                                />
                            </div>
                            <div className="select-setting-lower">
                                <label className="lightbox-label" htmlFor="">Height:</label>
                                <input
                                    type="number"
                                    name='height'
                                    onChange={handleChange}
                                    value={lightBoxPayload?.height}
                                />
                            </div>
                        </div>
                    </div>
                }
                {displayElement()}
                <div className="lightbox-setting-lower-btn">
                    {<p className="text-danger">{error?.elementError}</p>}
                    <div className="d-flex justify-content-end">
                        <div className="me-2">
                            <Button
                                classList='success'
                                text='Save & Close'
                                onClick={() => {
                                    setButtonType('close')
                                    handleLightBox('close')
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                classList='success'
                                text='Save'
                                onClick={() => {
                                    setButtonType('save')
                                    handleLightBox('save')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LightBoxSetting