import React, { useContext, useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import LightBoxSetting from "./LightBoxSetting";
import LightboxListing from "./LightBoxListing";
import { EditorInfoContext } from "../../../../../../../utis/userInfoContext";

const LightBoxSection = ({ docFileId, docId, handleClose }) => {
  const { setEditLightBox, lightBoxList } = useContext(EditorInfoContext);
  const [key, setKey] = useState("list");
  const [isViewLightBoxOpen, setIsViewLightBoxOpen] = useState(false); // ✅ Track ViewLightBox state

  const resetLightboxState = () => {
    setEditLightBox({});
  };

  const handleSelect = (selectedKey) => {
    if (selectedKey === "setting") {
      resetLightboxState();
    }
    setKey(selectedKey);
  };

  useEffect(() => {
    const handleEscPress = (event) => {
      if (event.key === "Escape" && !isViewLightBoxOpen) {
        event.stopPropagation();
        handleClose();
      }
    };

    document.addEventListener("keydown", handleEscPress);
    return () => document.removeEventListener("keydown", handleEscPress);
  }, [isViewLightBoxOpen]);

  return (
    <>
      <div className={`${key == 'list' ? 'p-2' : 'pt-2'} lightbox-setting`}>
        {lightBoxList.length > 0 && <div className="lightbox-setting-para">
          <p className="con-para">
            Configure the parameters of a lightbox below so that they
            trigger appropriately for your viewers. Lightboxes can trigger
            when viewers click on an image or hotspot. They can also trigger
            after a given period of time spent viewing the KDF or on a
            specific page of your doc.
          </p>
          <p className="rep-light">Please republish this KDF after modifying a lightbox</p>
        </div>}
        <Tabs
          activeKey={key}
          onSelect={handleSelect}
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="list" title={"List"}>
            <LightboxListing
              docFileId={docFileId}
              setKey={setKey}
              resetLightboxState={resetLightboxState}
              setEditLightBox={setEditLightBox}
              key={key}
              setIsViewLightBoxOpen={setIsViewLightBoxOpen} // ✅ Pass to LightboxListing
            />
          </Tab>
          <Tab eventKey="setting" title={"Settings"}>
            <LightBoxSetting docFileId={docFileId} docId={docId} key={key} setKey={setKey} handleClose={handleClose} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
export default LightBoxSection;
