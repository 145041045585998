import React, { useContext, useEffect, useState } from "react";
import Button from "../../Elements/Button";
import CommonModal from "../CommonModal";
import Select from "../../Elements/Select";
import { networkService } from "../../config/axios";
import UserInfoContext from "../../../utis/userInfoContext";
import apiGETRequest from "../../../utis/apiRequest";
import Input from "../../Elements/Input";

const MyProfileModal = ({ profileModalStaus, setProfileModalStaus, setOpenTerms }) => {
    const [timeZoneData, setTimeZoneData] = useState({});
    const [step, setStep] = useState(1);
    const [userModalField, setUserModalField] = useState({});
    const { profileData, userInfoData, setUserData, setUserProfileData, setShowNotifications } = useContext(UserInfoContext);

    const updatingUserProfile = async () => {
        let userData = await apiGETRequest(`edit.json`);
        if (userData) {
            setUserData(userData);
            let userProfileData = await apiGETRequest(
                `profiles/${userData?.id}.json`
            );
            setUserProfileData(userProfileData);
        }
    };

    const updateTimeZone = async () => {
        let getTimeZoneData = await apiGETRequest(`time-zones`);
        setTimeZoneData(getTimeZoneData);
    };

    const handleOnchangeEvent = (element) => {
        let userModalFieldCopy = JSON.parse(JSON.stringify(userModalField));
        let valueToUpdate = userModalFieldCopy[`step${step}`].map((field) => {
            return field.name == element.target.name
                ? {
                    ...field,
                    value: element.target.value,
                    error: (element.target.value.trim() == "") & field.required,
                }
                : { ...field };
        });
        setUserModalField({
            ...userModalFieldCopy,
            [`step${step}`]: valueToUpdate,
        });
    };

    const updateModalFields = () => {
        let valueToUpdate = {
            step1: [
                {
                    type: "text",
                    required: true,
                    label: "First Name",
                    name: "first_name",
                    value: profileData.first_name,
                },
                {
                    type: "text",
                    required: true,
                    label: "Last Name",
                    name: "last_name",
                    value: profileData.last_name,
                },
                {
                    type: "text",
                    required: false,
                    label: "Your Company Privacy Policy",
                    name: "privacy_link",
                    value: profileData.privacy_link,
                },
                {
                    type: "select",
                    required: true,
                    label: "*Time Zone",
                    name: "time_zone",
                    value: profileData.time_zone,
                },
            ],
            step2: [
                {
                    type: "text",
                    required: true,
                    label: "Company Name",
                    name: "company_name",
                    value: profileData.company_name,
                },
                {
                    type: "text",
                    required: true,
                    label: "Country",
                    name: "country",
                    value: profileData.country,
                },
                {
                    type: "text",
                    required: false,
                    label: "Direct Chat Link (Tawk.to)",
                    name: "direct_chat_link",
                    value: profileData.direct_chat_link,
                },
            ],
        };
        setUserModalField(valueToUpdate);
    };

    const handleMoveToNext = async () => {
        let userModalFieldCopy = JSON.parse(JSON.stringify(userModalField));
        const currentStepFields = userModalFieldCopy[`step${step}`];

        // Validate fields, specifically timezone
        const hasErrors = currentStepFields.some((field) => {
            // If field is required and value is empty, mark error
            if (field.required && !field?.value?.trim()) {
                field.error = true;
                return true;
            }
            // Check if time zone is selected for step 1
            if (field.name === "time_zone" && step === 1 && !field.value) {
                field.error = true;
                return true;
            }
            return false;
        });

        if (!hasErrors) {
            if (step < 2) {
                setStep((prevState) => prevState + 1);
            } else {
                // Prepare data to upload
                let dataToUpload = Object.keys(userModalFieldCopy)
                    .map((fieldID) => userModalFieldCopy[fieldID])
                    .flat()
                    .reduce((acc, curr) => {
                        acc[`${curr.name}`] = curr.value;
                        return acc;
                    }, {});
                let { data, status } = await networkService(
                    `profiles/${userInfoData.id}.json`,
                    "PATCH",
                    { profile: { ...dataToUpload }, user_id: userInfoData.id }
                );
                if (status === 200) {
                    setShowNotifications({
                        showToast: true,
                        toastType: "success",
                        toastMessage: "Profile details saved",
                    });
                    setOpenTerms(userInfoData?.privacy_accepted == false)
                }
                updatingUserProfile();
                setProfileModalStaus(false);
            }
        } else {
            setUserModalField(userModalFieldCopy);
        }
    };

    useEffect(() => {
        if (profileModalStaus) {
            updateModalFields();
            setStep(1);
        }
    }, [profileModalStaus]);

    useEffect(() => {
        return () => {
            setStep(1);
        };
    }, []);

    useEffect(() => {
        updatingUserProfile();
        updateTimeZone();
    }, []);

    return (
        <>
            <CommonModal
                title={"My Settings"}
                modalStatus={profileModalStaus}
                changeActiveStatus={setProfileModalStaus}
                classList="header-profile-modal-wrapper"
                closeModalOnEsc={false}
                footer={
                    <>
                        {step == 1 ? (
                            <Button
                                classList={"success"}
                                addonClass="d-inline-flex ml-4"
                                onClick={handleMoveToNext}
                                type="submit" 
                                text={"Continue"}
                            ></Button>
                        ) : (
                            <>
                                <Button
                                    classList={"success"}
                                    addonClass="d-inline-flex ml-4"
                                    onClick={() => setStep((prev) => prev - 1)}
                                    text={"Back"}
                                ></Button>
                                <Button
                                    classList={"success"}
                                    addonClass="d-inline-flex ml-4"
                                    onClick={handleMoveToNext}
                                    type="submit" 
                                    text={"Save Changes"}
                                ></Button>
                            </>
                        )}
                    </>
                }
                centered
            >
                <form name="profileForm">
                    {userModalField[`step${step}`] != undefined &&
                        userModalField[`step${step}`].map((item, index) => (
                            <div key={`step-${step}-${index}`}>
                                {item.type == "select" ? (
                                    <>
                                        <label className="control-label mb-2">{item?.label}</label>
                                        <Select
                                            classList=""
                                            name={item.name}
                                            required={item.required}
                                            label={item.label}
                                            optionList={Object.keys(timeZoneData).map((item) => {
                                                return {
                                                    value: timeZoneData[item].id,
                                                    title: timeZoneData[item].name,
                                                };
                                            })}
                                            onChange={(e) => handleOnchangeEvent(e)}
                                            value={item.value}
                                            errorMessageStatus={
                                                item?.error != undefined ? item.error : false
                                            }
                                        />
                                    </>
                                ) : (
                                    <Input
                                        classList=""
                                        type={item.type}
                                        placeholder={`Enter ${item.label}`}
                                        name={item.name}
                                        required={item.required}
                                        label={item.label}
                                        onChange={(e) => handleOnchangeEvent(e)}
                                        value={item.value}
                                        errorMessageStatus={
                                            item?.error != undefined ? item.error : false
                                        }
                                    />
                                )}
                            </div>
                        ))}
                </form>
            </CommonModal>
        </>
    )
}

export default React.memo(MyProfileModal)