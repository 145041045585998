import React, { useEffect, useRef, useState } from "react";

const VimeoIframe = ({
  src,
  style,
  title,
  id,
  resizeDimensions,
  dimensions,
  setVideoData,
  actionCableVideoMontior,
  ...props
}) => {
  const iframeRef = useRef(null);
  const videoId = src?.split("/")[4]?.split("?")[0] || "";
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!videoId) {
      console.error("Invalid videoId.");
      return;
    }
    let timeOut;
    const onVimeoIframeAPIReady = () => {
      if (
        iframeRef.current &&
        window.Vimeo &&
        window.Vimeo.Player &&
        setVideoData
      ) {
        const player = new window.Vimeo.Player(iframeRef.current);
        try {
          player.on("play", function () {
            setIsPlaying(true);
          });

          player.on("pause", function () {
            setIsPlaying(false);
          });

          player.on("timeupdate", function (event) {
            if (window.objInit?.tl?.disable_settings?.disable_on === "cyoa") {
              var viewportTop =
                window.pageYOffset || document.documentElement.scrollTop;
              var viewportBottom = viewportTop + $window.innerHeight;
              var playerPosition = iframeRef.current.getBoundingClientRect();
              var isIframeInsideViewport =
                playerPosition.bottom >= viewportTop &&
                playerPosition.top <= viewportBottom &&
                playerPosition.right >= 0 &&
                playerPosition.left <= $window.innerWidth;

              if (!isIframeInsideViewport) {
                player.pause();
              }
            }

            actionCableVideoMontior({
              id: id,
              watchedPercent: Math.round(event.percent * 100 * 100) / 100,
              watchedSeconds: parseInt(event.seconds),
              duration: event.duration,
              isPlaying: true,
            });
          });
        } catch (error) {
          console.warn("Initializing Elements id", error);
        }
      }
    };

    onVimeoIframeAPIReady();

    return () => {
      clearTimeout(timeOut);
    };
  }, [videoId, isPlaying]);

  return (
    <iframe
      onClick={props.onClick}
      ref={iframeRef}
      style={style}
      src={src}
      title={title}
      id={id}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      {...props}
    ></iframe>
  );
};

export default VimeoIframe;
