import React, { useContext, useEffect, useState } from "react";
import { simplifyUrl } from "../../../../../hooks/utils";
import { csrfToken } from "../../../../../utis/csrfTokenUtils";
import { getNormalizedDimensions, gifProportion, normalizeDimensions } from "../../../../../utis/helper";
import { EditorInfoContext } from "../../../../../utis/userInfoContext";
import { networkService } from "../../../../config/axios";
import EmbedSection from "../elementModal/elementSections/embedSection/EmbedSection";
import HotspotSection from "../elementModal/elementSections/hotspotSection/HotspotSection";
import ImageSection from "../elementModal/elementSections/imageSection/ImageSection";
import TextEditorSection from "../elementModal/elementSections/textEditorSection/TextEditorSection";
import VideoSection from "../elementModal/elementSections/videoSection/VideoSection";

export const actionKeys = ['page_link', 'url', 'link_title', 'kdf', 'link', 'kdf_title', 'light_box']
const useEditorHook = (closeModal, docData, totalPages, zoom, applyDesiredAction) => {
    const {
        setEditEnabled,
        geo,
        toolType,
        elements,
        editElement,
        imageElement,
        setEditElement,
        setElements,
        setToolType,
        setImageElement,
        setShowToast,
        pageDimensions
    } = useContext(EditorInfoContext)

    // initial state for the element mpdal fields
    const [tool, setTool] = useState({
        // properties going to be hadled dynamically
        authenticity_token: csrfToken(),
        doc_id: docData?.doc_id,
        docs_file_id: docData?.id,
        value: editElement?.value ?? { no_scroll: true, timerField: "ViewPort" },
        page: editElement?.page ?? geo.page,
        geometry: {
            ...(toolType !== 'LightboxesToolbox' && {
                left: editElement?.geometry?.left ?? geo?.width == 0 ? geo?.left - 50 : geo?.left,
                top: editElement?.geometry?.top ?? geo?.height == 0 ? geo?.top - 50 : geo?.top,
                width: editElement?.geometry?.width ?? (geo?.width < 50 && geo?.height < 50) ? 50 : geo?.width,
                height: editElement?.geometry?.height ?? (geo?.width < 50 && geo?.height < 50) ? 50 : geo?.height,
            }),
            keep_proportions: editElement?.geometry?.keep_proportions ?? (toolType == 'TextToolbox' || toolType == 'HotspotToolbox') ? false : true,
            keep_within_page: editElement?.geometry?.keep_within_page ?? true,
            hide_beyond_page_edges: editElement?.geometry?.hide_beyond_page_edges ?? false
        },
        sec_to_delay: editElement?.sec_to_delay ?? 0,
        disappear_after: 0,
        priority: editElement?.priority ?? false,
        jump_to_page: '',
        action: 'none',
        disappear_after: editElement?.disappear_after ?? 0,
        ...(editElement?.gif_type && { gif_type: editElement?.gif_type }),
    })

    // error state 
    const [errorState, setErrorState] = useState({
        videoErr: '',
        embedErr: '',
        textErr: '',
        imgErr: '',
        dimensionError: '',
        tagErr: '',
        pageErr: ''
    })
    const [initialTool, setInitialTool] = useState(tool);
    const [initialEditElement, setInitialEditElement] = useState(editElement);
    const [isChanged, setIsChanged] = useState(false);


        // element modal fields handle change
        const handleSubmitBtnState = () => {
            // Editing Existing Image -> Detect Changes
            const hasChanged =
            JSON.stringify(tool) !== JSON.stringify(initialTool) ||
            JSON.stringify(editElement) !== JSON.stringify(initialEditElement);
                setIsChanged(hasChanged);
        } 

    useEffect(() => {
        if (!Object.values(editElement).length > 0) {
            handleSubmitBtnState();
        }
    }, [tool, editElement]);

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        const actionNames = [
            "jump_to_page", "href", "kdf", "light_box", "link_title", "kdf_title", "action",
            "desiredaction", "thisElement", "frequency_animation", "timerField"
        ];

        if (name === "action") {
            // Uncheck all radio buttons before checking the selected one
            document.querySelectorAll("input[name='action']").forEach((radio) => {
                radio.checked = false;
            });
    
            // Ensure the selected radio is checked
            e.target.checked = true;
        }

        switch (true) {
            case name === 'bg_color': {
                // const r = parseInt(value.slice(1, 3), 16);
                // const g = parseInt(value.slice(3, 5), 16);
                // const b = parseInt(value.slice(5, 7), 16);
                // setTool(prevVal => ({
                //     ...prevVal,
                //     value: {
                //         ...prevVal.value,
                //         [name]: `rgb(${r}, ${g}, ${b})`
                //     },
                // }));
                setTool({
                    ...tool,
                    value: {
                        ...tool.value,
                        [name]: value
                    },
                });
                break;
            }
            case name == 'transparency': {
                setTool({
                    ...tool,
                    [name]: value
                });
                break;
            }
            case name.startsWith('geometry.'): {
                localStorage.setItem('is_changed_model', 'true');
                const geometryKey = name.split('.')[1];
                // if (geometryKey == 'height' && pageDimensions.height < Number(value) + normalizeDimensions(tool?.geometry?.top, 1, zoom?.value)
                //     || tool?.geometry?.keep_within_page != false && geometryKey == 'width' && pageDimensions.width < Number(value) + normalizeDimensions(tool?.geometry?.left, 1, zoom?.value)) {
                //     setErrorState({
                //         ...errorState,
                //         dimensionError: 'Dimensions exceed PDF page.'
                //     })
                // } else if (geometryKey == 'height' && pageDimensions.height > Number(value) + Number(tool?.geometry?.top)
                //     || geometryKey == 'width' && pageDimensions.width > Number(value) + Number(tool?.geometry?.left)) {
                //     if (errorState.dimensionError !== '') {
                //         setErrorState({
                //             ...errorState,
                //             dimensionError: ''
                //         });
                //     }
                // }
                setTool((prevValues) => ({
                    ...prevValues,
                    geometry: {
                        ...prevValues.geometry,
                        ...((geometryKey === 'keep_within_page' || geometryKey === 'hide_beyond_page_edges') && {
                            keep_within_page: prevValues?.geometry?.keep_within_page ?
                                prevValues?.geometry?.hide_beyond_page_edges :
                                false,
                            hide_beyond_page_edges: prevValues?.geometry?.hide_beyond_page_edges ? prevValues?.geometry?.keep_within_page :
                                false,
                        }),
                        [geometryKey]: type === 'checkbox' ? checked : value !== '' ? Number(value) : value,
                    },
                }));
                break;
            }
            case name === 'animation' || name === 'frequency' || name === 'disappear' || name === "timerField" || name == 'disappear_after':
                setTool((prevValues) => ({
                    ...prevValues,
                    ...(name === 'disappear_after' && { disappear_after: Number(value) }),
                    value: {
                        ...prevValues.value,
                        ...(name === 'disappear_after' && value == 0
                            ? { disappear: '' }
                            : { [name]: value }),
                    },
                }));
                break
            case actionNames.includes(name) || value === "desiredaction" || value === "page":
                setErrorState({
                    ...errorState,
                    pageErr: ''
                })
                setTool((prev) => {
                    let updatedValue = { ...(prev?.value || {}) };
                    let actionValue = name;
                    let dropdown_data = {};
                    if (name == 'action' && value == 'none') {
                        updatedValue = { timerField: "ViewPort" };
                        actionValue = 'none'
                        dropdown_data = {}
                    } else if (["desiredaction", "thisElement", "frequency_animation", "timerField"].includes(name) || value === "desiredaction") {
                        actionValue = "desiredaction";
                        if (value === "desiredaction") {
                            dropdown_data = {
                                frequency: "OnEveryClick",
                                thisElement: "thisElement",
                                desiredaction: "hide"
                            };
                            updatedValue = {}
                        } else {
                            dropdown_data = {
                                ...{
                                    frequency: "OnEveryClick",
                                    thisElement: "thisElement",
                                    desiredaction: "hide"
                                },
                                ...prev?.dropdown_data,
                                ...(name === "frequency_animation" ? { frequency: value } : { [name]: value }),
                            };
                            updatedValue = {}
                        }
                    } else if (["jump_to_page", "page"].includes(name)) {
                        updatedValue = { page_link: value };
                        actionValue = "page";
                        dropdown_data = {}
                    } else if (value == 'on' || name == 'light_box') {
                        updatedValue = { light_box: value };
                        actionValue = "on";
                        dropdown_data = {}
                    } else if (name === "href") {
                        updatedValue = {};
                        dropdown_data = {};
                        if (!value || value.trim() === "" || value === "https://" || value === "@") {
                            updatedValue = {};
                            actionValue = "href";
                        } else if (value.includes("@") && /\S+@\S+\.\S+/.test(value)) {
                            updatedValue.mail = value;
                            actionValue = "href";
                        } else if (/^\+?\d+$/.test(value)) {
                            updatedValue.phone = value;
                            actionValue = "href";
                        } else if (!value.includes("@") && value.includes(".") && /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/.test(value)) {
                            updatedValue.url = value.startsWith("https://") ? value : `https://${value}`;
                            actionValue = "href";
                        } else {
                            updatedValue.url = value;
                            actionValue = "href";
                        }
                    } else if (["link_title", "kdf_title"].includes(name)) {
                        updatedValue = {
                            link_title: name === "link_title" ? value : updatedValue.link_title,
                            kdf_title: name === "kdf_title" ? value : updatedValue.kdf_title
                        };
                        dropdown_data = {};
                        actionValue = "kdf";
                    } else {
                        updatedValue[name] = value;
                        dropdown_data = {}
                    }
                    return {
                        ...prev,
                        dropdown_data: dropdown_data,
                        actual_page: geo?.page,
                        action: actionValue,
                        value: {
                            ...updatedValue,
                            bg_color: prev?.value?.bg_color,
                            animation: prev?.value?.animation,
                            frequency: prev?.value?.frequency,
                            timerField: "ViewPort",
                            gif_link: prev?.value?.gif_link
                        }
                    };
                });

            default: {
                setTool((prev) => ({
                    ...prev,
                    [name]: type === 'checkbox'
                        ? checked
                        : type === 'number'
                            ? name === 'page'
                                ? value === '' ? '' : Number(value) || 1
                                : Number(value)
                            : value
                }));
                break;
            }
        }

        handleSubmitBtnState();

    }

    //handle text editor change 
    const handleTextChange = (data) => {
        setTool((prevValues) => ({
            ...prevValues,
            element_type: 7,
            value: {
                ...prevValues.value,
                html_text: data,
                timerField: "ViewPort"
            },
        }));
        setErrorState({
            ...errorState,
            textErr: ''
        })
        handleSubmitBtnState();
    }

    // handle the select the gifs and tenor
    const handleSelectGifs = (type, url, dimensions) => {
        setErrorState({ ...errorState, imgErr: '' })
        let _gifProportion = gifProportion(tool?.geometry?.width, tool?.geometry?.height, dimensions?.width, dimensions?.height);
        setTool({
            ...tool,
            geometry: {
                ...tool?.geometry,
                width: _gifProportion[0],
                height: _gifProportion[1],
            },
            gif_type: type,
            image_text: "",
            image_type: null,
            element_type: '4',
            rotate: 0,
            flip: 1,
            is_tenor: type == 'tenor',
            recurrence: "OncePerVisit",
            value: { ...tool?.value, timerField: "ViewPort", gif_link: url }
        })
        handleSubmitBtnState();
    }

    // validation image
    const isImageFile = (file) => {
        if (!file.type.startsWith('image/')) {
            return false;
        }
        return true;
    };

    // image upload handle change function
    const handleImageUpload = (event, action) => {
        setEditEnabled(false)
        if (action === 'cancel') {
            setTool({ ...tool, file: '' })
        } else if (action === 'upload') {
            const file = event.target.files[0];
            setErrorState({ ...errorState, imgErr: '' })
            if (!isImageFile(file)) {
                setErrorState({
                    ...errorState, imgErr: 'Please upload a valid image file.'
                })
                event.target.value = null;
                return;
            }
            const { file_url, file_details, is_tenor, gif_type, ...toolWithoutFileUrl } = tool;
            const { gif_link, ...updatedValue } = toolWithoutFileUrl?.value || {};
            setTool({
                ...toolWithoutFileUrl,
                file_cache: '',
                left: toolWithoutFileUrl?.geometry.left,
                top: toolWithoutFileUrl?.geometry?.top,
                proportion: "on",
                width: toolWithoutFileUrl?.geometry?.width,
                height: toolWithoutFileUrl?.geometry?.height,
                transparency: toolWithoutFileUrl?.transparency ?? 1,
                element_type: '4',
                gif_type: 'image',
                file: file,
                value: {
                    ...updatedValue,
                    timerField: "ViewPort"
                }
            })
        }
        handleSubmitBtnState();
    };

    // video and embed handle change function
    const handleElementUpload = (event, type) => {
        if (event.target?.name !== 'no_scroll') {
            const embedCode = event.target.value;
            let source = "";
            let videoLink = "";
    
            // Extract URL from iframe or other elements
            if (embedCode.match(/^<iframe(.*?)><\/iframe>/gi)) {
                videoLink = embedCode.split('src="')[1]?.split('"')[0] || "";
            } else {
                if (embedCode.includes('data-url="')) {
                    videoLink = embedCode.split('data-url="')[1]?.split('"')[0] || "";
                } else if (embedCode.includes('href="')) {
                    videoLink = embedCode.split('href="')[1]?.split('"')[0] || "";
                } else if (embedCode.includes('src="')) {
                    videoLink = embedCode.split('src="')[1]?.split('"')[0] || "";
                } else {
                    videoLink = embedCode; // If it's already a URL
                }
            }
    
            // Ensure HTTPS
            videoLink = videoLink.replace(/^http:\/\//, 'https://');
    
            // Special handling for Loom videos
            if (videoLink.includes('loom.com/share/')) {
                videoLink = videoLink.replace('loom.com/share/', 'loom.com/embed/');
            } else if (videoLink.includes('loom.com/embed/')) {
                source = 'loom'; // Mark source as Loom
            }
    
            // If type is 'embed', apply necessary transformations
            if (type === 'embed') {
                if (videoLink.includes('canva')) {
                    const url = simplifyUrl(embedCode);
                    videoLink = url + "?embed";
                }
            }
    
            // Handle Facebook embeds separately
            if (videoLink.includes('facebook.com')) {
                videoLink = "https://www.facebook.com/plugins/post.php?href=" + videoLink;
            }
    
            // Detect Source Type
            if (embedCode.includes('youtube')) {
                source = 'youtube';
            } else if (embedCode.includes('vimeo')) {
                source = 'vimeo';
            } else if (embedCode.includes('soundcloud')) {
                source = 'soundcloud';
            }
    
            // Update State
            setTool({
                ...tool,
                element_type: type === 'embed' ? '6' : type === 'video' ? '5' : '8',
                other_files: [],
                tags: [],
                sec_to_delay: 0,
                disappear_after: 0,
                value: {
                    timerField: "ViewPort",
                    video_link: videoLink,
                    ...(source && { source }),
                    ...(type === 'embed' && { no_scroll: true, no_js: true })
                }
            });
    
            setErrorState({ ...errorState, videoErr: '', embedErr: '' });
        } else {
            setTool({
                ...tool,
                value: {
                    ...tool?.value,
                    ...(type === 'embed' && { no_scroll: event?.target?.checked, no_js: true })
                }
            });
        }
        handleSubmitBtnState();
    };
    

    // element upload function
    const handleSubmitElement = (e, elemId, elementName) => {
        e?.preventDefault();
        e && uploadElement(elemId, elementName);
    };

    // api call to send tags other than formdata
    const handleSave = (payload) => {
        const url = `/docs/elements${payload?.id ? '/' + payload?.id : ''}.json`;
        networkService(url, "PATCH", payload)
            .then((response) => {
                if ((response.status == 200)) {
                    setElements({ ...elements, [response?.data?.id]: response?.data });
                }
            })
            .catch((error) => {
                console.error(error)
            });
    };

    const uploadElement = async (elementId, elementType) => {
        if (handleValidations(tool) || elementType == 'image') {
            const updatedGeometry = {
                ...tool?.geometry,
                width: normalizeDimensions(tool?.geometry?.width, zoom?.value),
                height: normalizeDimensions(tool?.geometry?.height, zoom?.value),
                top: normalizeDimensions(tool?.geometry?.top, zoom?.value),
                left: normalizeDimensions(tool?.geometry?.left, zoom?.value),
            };

            const payload = {
                ...tool,
                geometry: updatedGeometry,
                ...(toolType === 'Clone' && { duplicate: true })
            };
            const formData = new FormData();
            let headers = {}
            for (const [key, value] of Object.entries(payload)) {
                (!['element', 'docs_lightbox_id', 'tags', 'gif_type', 'is_tenor'].includes(key)) &&
                    formData.append(key, key !== 'file' && typeof value === "object" ? JSON.stringify(value) : value);
            }
            editElement?.id && elementId && formData.append('_method', 'patch')
            if (!payload?.file) {
                headers['content-type'] = 'application/json'
            }

            // to handle the api url for edit and add element 
            let url = !editElement?.id && !elementId
                ? `/docs/elements${!payload?.file ? '.json' : ''}`
                : `/docs/elements/${editElement?.id ?? elementId}${payload?.element_type_name !== "Image" ? '.json' : ''}`
            try {
                const response = await fetch(url, {
                    method: !editElement?.id && !elementId ? 'POST' : 'PATCH',
                    body: !payload?.file ?
                        JSON.stringify({
                            ...payload,
                            ...(toolType === 'Clone' && { duplicate: true })
                        }) :
                        formData,
                    cookie: {
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                    ...(!payload?.file && { headers })
                });
                if (response?.ok) {
                    let jsonResponse = await response.json()
                    if (toolType !== 'LightboxesToolbox') {
                        setShowToast({
                            showToast: true,
                            toastType: 'success',
                            toastMessage: editElement?.id ? 'Changes Saved' : 'Element Created'
                        })
                    }
                    if (payload?.element_type == '4' && payload?.file && elementId) {
                        const { file, ...updatedTool } = tool;
                        const data = {
                            ...updatedTool,
                        };
                        handleSave(data);
                    }
                    setElements({ ...elements, [jsonResponse.id]: jsonResponse })
                    if (elementType === 'image') {
                        toolType === 'LightboxesToolbox' && setImageElement({ ...imageElement, [jsonResponse.id]: jsonResponse })
                    }
                    handleCloseModal(false);
                }
            } catch (error) {
                setShowToast({
                    showToast: true,
                    toastType: 'danger',
                    toastMessage: 'Something Went Wrong'
                })
                console.error('Fetch error:', error);
            }
        }
        handleSubmitBtnState();
    }

    const removeDiv = () => {
        const divToRemove = document.getElementById("createdDivId");
        if (divToRemove) {
            divToRemove.remove();
        }
        if (editElement?.id) {
            const editDiv = document.getElementById(`div${editElement?.id}`)
            const editImage = document.getElementById(`image${tool?.id}`)
            if (editImage) {
                editImage.style.transform = `rotate(${editElement?.rotate ?? 0}deg) scaleX(${editElement?.flip ?? 1})`
            }
            if (editDiv) {
                editDiv.style.width = `${normalizeDimensions(editElement?.geometry?.width, 1, zoom?.value)}px`
                editDiv.style.height = `${normalizeDimensions(editElement?.geometry?.width, 1, zoom?.value)}px`;
                // const { top, left, height, width } = getNormalizedDimensions(editElement?.geometry, zoom, pageDimensions);
                // editDiv.style.width = `${width}px`
                // editDiv.style.height = `${height}px`;
                // editDiv.style.top = `${top}px`
                // editDiv.style.left = `${left}px`;
            }
        }
    };

    // handle element modal close
    const handleCloseModal = (status) => {
        setTool({})
        setEditElement({})
        removeDiv()
        setToolType('')
        closeModal && closeModal(status)
        setEditEnabled(status)
        localStorage.removeItem('is_changed_model');
        if (window.tinymce) {
            window.tinymce.remove();
        }
        document.querySelectorAll('.tox-tinymce-aux').forEach(el => el.remove());
        handleSubmitBtnState();
    }

    // dynamically render element section with heading and component
    const ToolSection = () => {
        let result = {
            header: "",
            component: null,
        };
        switch (true) {
            case toolType === 'ImageToolbox' || editElement?.element_type === 4:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Image settings' : "Add Image";
                result.component = (
                    <ImageSection
                        tool={tool}
                        handleImageUpload={handleImageUpload}
                        uploadElement={handleSubmitElement}
                        editElement={editElement}
                        handleSelectGifs={handleSelectGifs}
                        errorState={errorState}
                    />
                );
                break;
            case toolType === 'VideoToolbox' || editElement?.element_type === 5:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Video settings' : "Add Video";
                result.component = (
                    <VideoSection
                        handleVideoUrlUpload={handleElementUpload}
                        videoLink={tool?.value?.video_link}
                        errorState={errorState}
                    />
                );
                break;
            case toolType === 'TextToolbox' || editElement?.element_type === 7:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Text settings' : "Add Text";
                result.component = (
                    <TextEditorSection
                        handleTextChange={handleTextChange}
                        html_text={tool?.value?.html_text}
                        errorState={errorState}
                    />
                );
                break;
            case toolType === 'EmbedToolbox' || editElement?.element_type === 6:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Embed settings' : "Add Embed";
                result.component = (
                    <EmbedSection
                        handleEmbedUrlUpload={handleElementUpload}
                        siteUrl={tool?.value?.video_link}
                        preventScroll={tool?.value?.no_scroll}
                        errorState={errorState}
                    />
                );
                break;
            case toolType === 'HotspotToolbox' || editElement?.element_type === 9:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Hotspot settings' : "Add Hotspot";
                result.component = (
                    <HotspotSection
                        tool={tool}
                        setTool={setTool}
                        handleHotspotUpload={handleElementUpload}
                        uploadElement={handleSubmitElement}
                    />
                );
                break;
            default:
                result.header = toolType === 'Clone' ? 'Clone Element' : Object.values(editElement).length > 0 ? 'Image settings' : "Add Image";
                result.component = (
                    <ImageSection
                        tool={tool}
                        handleImageUpload={handleImageUpload}
                        uploadElement={handleSubmitElement}
                        editElement={editElement}
                        handleSelectGifs={handleSelectGifs}
                        errorState={errorState}
                    />
                );
        }
        return result;
    }

    // api call to send the lightbox id when images are added in the slider.
    const editImageInSlider = async (editId, data) => {
        const response = await networkService(`/docs/elements/${editId}.json`, 'PATCH', data)
        return response
    }

    //handle validations
    const handleValidations = (tool) => {
        if (!tool?.value?.video_link &&
            (toolType === "VideoToolbox" ||
                toolType === "EmbedToolbox" ||
                editElement?.element_type === 6 ||
                editElement?.element_type === 5)
        ) {
            setErrorState({
                ...errorState,
                videoErr: 'Please add the embed code provided by youtube or vimeo. Link must contain http or https.',
                embedErr: 'Please add the URL. Link must contain http or https.'
            })
            return false
        } else if (!tool?.value?.html_text && (toolType === "TextToolbox" ||
            editElement?.element_type === 7)) {
            setErrorState({
                ...errorState,
                textErr: 'Please add the text'
            })
            return false
        } else if (
            (toolType === 'ImageToolbox' || editElement?.element_type === 4) &&
            (!tool?.file && !tool?.file_url && !tool?.value?.gif_link)
        ) {
            setErrorState({
                ...errorState,
                imgErr: 'Please select the Image Element'
            })
            return false
        } else if (!tool?.tags?.length && (
            actionKeys.some((key) => tool?.value?.hasOwnProperty(key)) ||
            Object.keys(tool?.dropdown_data || {}).length > 0
        ) && tool?.action != 'none') {
            setErrorState({
                ...errorState,
                tagErr: 'Please add a tag'
            })
            return false
        }
        else if (
            tool?.action === 'page' &&
            (
                Number(tool?.value?.page_link) === 0 ||
                Number(tool?.value?.page_link) > Number(totalPages) ||
                Number(tool?.value?.page_link) === Number(tool?.page)
            )
        ) {
            setErrorState({
                ...errorState,
                pageErr: Number(tool?.value?.page_link) === Number(tool?.page) ?
                    'Please add a page number other than current page' :
                    tool?.value?.page_link == 0 ?
                        'Please add a valid page number' :
                        'Please add a valid page number'
            })
            return false
        }
        else {
            return true
        }
    }

    useEffect(() => {
        if (editElement.id) {
            const { docs_element_tags, action, geometry, ...rest } = editElement;
            const updatedElement = {
                ...rest,
                geometry: {
                    ...geometry,
                    ...getNormalizedDimensions(geometry, zoom, pageDimensions)
                },
                action: action ?? 'none',
                tags: docs_element_tags || [],
            };
            setTool(updatedElement);
        }
    }, [editElement])

    useEffect(() => {
        const divCreated = document.getElementById("createdDivId");
        if (tool?.id) {
            const editImage = document.getElementById(`image${tool?.id}`)
            if (editImage) {
                editImage.style.transform = `rotate(${tool?.rotate ?? 0}deg) scaleX(${tool?.flip ?? 1})`
            }
        } else if (divCreated) {
            divCreated.style.zIndex = 999;
            divCreated.style.width = `${tool?.geometry?.width}px`;
            divCreated.style.height = `${tool?.geometry?.height}px`;
            divCreated.style.top = `${tool?.geometry?.top}px`;
            divCreated.style.left = `${tool?.geometry?.left}px`;
        }
    }, [tool])

    return {
        tool,
        ToolSection,
        handleCloseModal,
        handleSubmitElement,
        uploadElement,
        handleChange,
        toolType,
        editElement,
        handleElementUpload,
        setTool,
        editImageInSlider,
        handleTextChange,
        isChanged, setIsChanged,
        errorState,
    };
};

export default useEditorHook;
