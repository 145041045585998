import React, { useCallback, useEffect, useRef, useState } from "react";
import { checkPageVisibility } from "../../../../hooks/utils";
import { getCookie, setCookie } from "../../../../utis/helper";
import { usePageVisibility } from "./usePageVisibility";

const useStatHook = (isWebsocketConnection) => {
  const { sess_id } = window?.objInit;
  const secondsRef = useRef(1);
  const [trakingData, setTrackingData] = useState({});
  const wsRef = useRef(null);
  const [videoData, setVideoData] = useState({});
  const [actionCableSessionData, setActionCableSessionData] = useState({});
  const [pageNum, setPageNum] = useState(null)
  const optinCookie = getCookie("optin", {});
  const lastSeenPageCookie = getCookie("lastSeenPage", {});
  const receiverCookie = getCookie("receiver", {});
  const updateIntervalRef = useRef(null);
  const sendDataIntervalRef = useRef(null);
  const actionCableSessionDataRef = useRef({});
  const isFocused = usePageVisibility();
  const isPageVisible = useRef(null);

  useEffect(() => {
    actionCableSessionDataRef.current = actionCableSessionData;
  }, [actionCableSessionData]);

  const WEB_URL = `wss://staging2.seriousinsight.com`
  // const WEB_URL = "ws://localhost:3000";

  const updatePage = useCallback(
    (event) => {
      if (
        actionCableSessionDataRef.current.id &&
        parseInt(event.target.value) !== actionCableSessionDataRef.current?.page
      ) {
        setActionCableSessionData((prevData) => {
          const data = {
            ...prevData,
            page: parseInt(event.target.value),
            seconds_on_page: 1,
            id: null,
          };

          actionCableSendData(data, "save_info");
          return {
            ...prevData,
            page: parseInt(event.target.value),
            seconds_on_page: 1,
            id: null,
          };
        });
      }
    },
    [actionCableSessionData, pageNum]
  );

  // websocket connection
  useEffect(() => {
    setTrackingData(window?.objInit);

    if ((sess_id == undefined && !wsRef.current) || !isWebsocketConnection)
      return;
    const websocket = new WebSocket(`${WEB_URL}/cable`);
    wsRef.current = websocket;
    websocket.onopen = (e) => {
      console.log("WebSocket connection established.");

      // Send the subscription message
      const subscriptionMessage = JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "SessionMonitoringChannel",
          data: { session_id: window?.objInit?.sess_id },
        }),
      });

      websocket.send(subscriptionMessage);
      actionCableSetIntervals();
      initActionCableSessionData(window.objInit?.tlb?.landing_page || 1);
    };

    websocket.onerror = (e) => {
      console.log("Error in websocket: ", e);
    };

    websocket.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data?.message?.id) {
          setActionCableSessionData((prevData) => {
            return {
              ...prevData,
              id: data.message.id,
              session_page_id: data.message.id,
            };
          });
        }
      } catch (error) { }
    };

    return () => {
      window.onload = null;
      websocket.close();
    };
  }, [sess_id]);

  useEffect(() => {
    document
      .getElementById("pageNumber")
      ?.addEventListener("change", updatePage);

    return () => {
      document
        .getElementById("pageNumber")
        ?.removeEventListener("change", updatePage);
    };
  }, [pageNum])

  const actionCableSendData = (objData, strAction) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const data = {
        command: "message",
        identifier: JSON.stringify({
          channel: "SessionMonitoringChannel",
          data: { session_id: window.objInit?.sess_id },
        }),
        ...(objData && {
          data: JSON.stringify({ message: objData, action: strAction }),
        }),
      };
      return wsRef.current.send(JSON.stringify(data), strAction);
    }
  };

  const initActionCableSessionData = (page) => {
    if (undefined === actionCableSessionData.page) {
      initSessionData(page);
    } else if (actionCableSessionData.page != page) {
      if (actionCableWs && actionCableSessionDataRef.current?.id) {
        var actionSentPromise = actionCableSendData(
          actionCableSessionData,
          "save_info"
        );
        if (false !== actionSentPromise) {
          actionSentPromise.then(function () {
            initSessionData(page);
          });
        }
      }
    }
  };

  const initSessionData = (page) => {
    const objInit = window?.objInit;
    lastSeenPageCookie[objInit.tlb?.token] = page;
    setCookie("lastSeenPage", lastSeenPageCookie);
    if (undefined !== objInit?.sess_id) {
      const data = {
        session_id: objInit.sess_id,
        tracking_link_id: objInit.tlb.id,
        doc_id: Number(objInit.doc_id),
        docs_file_id:
          Number(objInit?.active_files && Object.keys(objInit?.active_files)[0]),
        page: page,
        seconds_on_page: 1,
      };
      if (
        receiverCookie[objInit.tlb.token] &&
        receiverCookie[objInit.tlb.token].id
      ) {
        data.receiver_id = receiverCookie[objInit.tlb.token].id;
      }
      setActionCableSessionData({ ...data });
      actionCableSessionDataRef.current = { ...data };
      if (actionCableSessionDataRef.current?.id)
        actionCableSendData(data, "save_info");
    }
  };

  const actionCableOptIn = () => {
    if (actionCableSessionDataRef.current?.id)
      actionCableSendData(actionCableSessionData, "opt_in_info");
  };

  isPageVisible.current =
    isFocused() == undefined ? checkPageVisibility() : isFocused();

  const optinModalOpen =
    optinCookie[trakingData.tlb?.token]?.show === "display";

  const actionCableWinPause = () => {
    var actioncableData = JSON.stringify(actionCableSessionDataRef.current);
    actioncableData = JSON.parse(actioncableData);
    actioncableData.session_page_id = actionCableSessionData.id;
    if (actionCableSessionDataRef.current?.id)
      actionCableSendData(actioncableData, "win_pause_info");
  };

  const actionCableSetIntervals = () => {
    clearInterval(updateIntervalRef.current);
    clearInterval(sendDataIntervalRef.current);
    if (
      undefined !== window?.objInit?.tlb &&
      wsRef.current &&
      wsRef.current.readyState === WebSocket.OPEN
    ) {
      // Update seconds on page every second
      updateIntervalRef.current = setInterval(() => {
        if (isPageVisible.current && false === optinModalOpen) {
          setActionCableSessionData((prevData) => {
            return {
              ...prevData,
              seconds_on_page: prevData.seconds_on_page + 1 || 1,
            };
          });
        }
      }, 1000);

      // Send data every 2 seconds
      sendDataIntervalRef.current = setInterval(() => {
        if (isPageVisible.current) {
          setActionCableSessionData((prevData) => {
            if (prevData.page) {
              actionCableSendData(prevData, "save_info");
            }
            return prevData; // No modification, just sending updated state
          });
        } else if (!isPageVisible.current && false === optinModalOpen) {
          actionCableWinPause();
        }
      }, 2000);
    }
  };

  const actionCableVideoMontior = (data) => {
    var actioncableData = JSON.stringify(actionCableSessionData);
    actioncableData = JSON.parse(actioncableData);
    actioncableData.session_page_id = actionCableSessionData.id;
    actioncableData.docs_element_id = data.id;
    actioncableData.watchedPercent = data.watchedPercent;
    actioncableData.watchedSeconds = data.watchedSeconds;
    actioncableData.duration = data.duration;
    if (actionCableSessionDataRef.current?.id)
      actionCableSendData(actioncableData, "save_video_info");
  };

  // when page changes this is going to be send
  const sendScrollInfo = (page) => {
    setPageNum(page)
    secondsRef.current = 1;
    localStorage.removeItem("page_id");
  };

  const saveDesiredActionClicks = (objData, strAction) => {
    if (
      !wsRef.current &&
      wsRef.current.readyState === WebSocket.OPEN &&
      strAction !== "save_desired_action_frequency"
    ) return;

    const data = {
      command: "message",
      identifier: JSON.stringify({
        channel: "SessionMonitoringChannel",
        data: { session_id: window.objInit?.sess_id },
      }),
      ...(objData && {
        data: JSON.stringify({ message: objData, action: strAction }),
      }),
    };
    if (wsRef.current) {
      return wsRef.current.send(JSON.stringify(data), strAction);
    }
  };

  const applyDesiredAction = (element) => {
    const frequency = element?.dropdown_data
      ? element?.dropdown_data?.frequency
      : null;

    const data = {
      ...actionCableSessionData,
      frequency_type: frequency,
      docs_element_id: Number(element?.id)
    };
    setActionCableSessionData({ ...data });
    if (
      (element.element_type_name === "Image" ||
        element.element_type_name === "Hotspot" ||
        element.element_type_name === "HTML Text" ||
        element.element_type_name === "Video" ||
        element.element_type_name === "Web URL") &&
      element.dropdown_data
    ) {
      const currentElement = document.getElementById(`div${element.id}`);
      if (element?.dropdown_data?.desiredaction) {
        if (currentElement) {
          saveDesiredActionClicks(
            data,
            "save_desired_action_frequency"
          );
        }
      }
    }
  };

  //when element is clicked
  const elemClick = (elemId, evt, element) => {
    if (
      evt == undefined ||
      evt.delegateTarget?.children[0]?.attributes[0]?.value !== "accept"
    ) {
      if (undefined !== evt) {
        evt.stopPropagation();
      }
      if (wsRef.current) {
        if (elemId === "download") {
          var actionCablePageClick = {
            session_page_id: actionCableSessionData.id,
            is_download: true,
            is_lang_changed: false,
            changed_lang: "",
            page: actionCableSessionData.page,
            clicked_after: actionCableSessionData.seconds_on_page,
          };
        } else {
          var actionCablePageClick = {
            session_page_id: actionCableSessionData.id,
            docs_element_id: elemId,
            clicked_after: actionCableSessionData.seconds_on_page,
          };
        }
        if (null == actionCableSessionData.id) {
          var sendClickInterval = setInterval(function () {
            if (undefined != actionCableSessionData.id) {
              actionCablePageClick.session_page_id = actionCableSessionData.id;
              clearInterval(sendClickInterval);
              actionCableSendData(actionCablePageClick, "save_click");
            }
          }, 100);
        } else {
          actionCableSendData(actionCablePageClick, "save_click");
        }
        applyDesiredAction(element);
      }
    }
  };

  return {
    sendScrollInfo,
    elemClick,
    actionCableOptIn,
    setVideoData,
    actionCableSetIntervals,
    actionCableVideoMontior,
    saveDesiredActionClicks,
    applyDesiredAction,
  };
};

export default useStatHook;
