import React, { useEffect, useState } from "react";
import { handleErrorImage, viewsPercentage } from "../../../../utis/helper";
import CustomTooltip from "../../../Elements/Tooltip";
import styles from "../Viewer/Viewer.module.css";

const SessionTable = ({
  session,
  receiver,
  setSelectedPage,
  selectedPage,
  thumbs_details,
  sessions,
  sessionIndex,
  selectedFilter = "all",
}) => {
  const [filteredPages, setFilteredPages] = useState([]);
  const [tagPages, setTagPages] = useState({});
  const [pagesWithoutTags, setPagesWithoutTags] = useState([]);
  const [pageTags, setPageTags] = useState({});
  const elements = window?.objInit?.elements;
  let landingPage = session.landing_page;

  const pdfFile = {
    num_pages: objInit.pdfFile.num_pages,
    file: {
      url: objInit.pdfFile.file.url,
      language: objInit.pdfFile.language,
    },
    page_tags: objInit.pdfFile.page_tags,
    thumbs_details: objInit.pdfFile.thumbs_details,
    pdfStamp: objInit.pdfStamp,
  };

  useEffect(() => {
    Object.keys(pdfFile.page_tags || {}).map(function (key) {
      pageTags[key] = [];
      if (pdfFile.page_tags[key].length === 0) {
        pagesWithoutTags.push(key);
      }
      pdfFile.page_tags[key].map(function (el) {
        if (tagPages[el.name] === undefined) {
          tagPages[el.name] = [];
        }
        pageTags[key].push(el.name);
        tagPages[el.name].push(key);
      });
    });
    for (let key in elements) {
      if (elements?.hasOwnProperty(key)) {
        let element = elements[key];
        let actionType = element?.dropdown_data ? element.dropdown_data.desiredaction : 'none';
        let imp_clk = {
          id: key,
          action_type: actionType || 'undefined',
          tags: []
        };
        if (element.docs_element_tags && element.docs_element_tags.length > 0) {
          imp_clk.tags = element.docs_element_tags.map(tag => tag.name);
        }
        landingPage.elements_imp_clicks.push(imp_clk);
      }
    }
    setPageTags({ ...pageTags });
    setTagPages({ ...tagPages });
    setPagesWithoutTags([...pagesWithoutTags]);
  }, []);

  useEffect(() => {
    if (selectedFilter === "all") {
      setFilteredPages([...session.pages.slice()]);
    } else if (selectedFilter === "not_tagged") {
      filterPages([...pagesWithoutTags.slice()]);
    }
  }, [selectedFilter]);

  const filterPages = (data) => {
    const updatedFilteredPages = session.pages.slice().filter(function (el) {
      return data.indexOf(String(el.page)) !== -1;
    });
    setFilteredPages([...updatedFilteredPages]);
  };

  return (
    <table className="w-100">
      <thead className={styles.statsTableHead}>
        <tr>
          <th></th>
          <th>Page</th>
          <th>Duration</th>
          <th>Page Tags</th>
          <th>Elements Tags</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {landingPage &&
          selectedFilter == "all" &&
          pageTags[landingPage.page] && (
            <>
              <tr
                onClick={() =>
                  setSelectedPage({
                    id: landingPage.page,
                  })
                }
              >
                <td>
                  <img
                    src={`${thumbs_details?.small}${landingPage.page - 1}.${thumbs_details?.extension
                      }`}
                    onError={(e) => handleErrorImage(e)}
                    width="50px"
                    height="75px"
                    alt={landingPage.page}
                    className={
                      selectedPage?.id == landingPage?.page
                        ? styles.selectedImage
                        : ""
                    }
                  />
                </td>
                <td>{landingPage.page}</td>
                <td>
                  <CustomTooltip
                    title={
                      <p className="p-1 text-light">
                        {landingPage.time_tooltip}
                      </p>
                    }
                  >
                    <span>{landingPage.min_viewed}</span>
                  </CustomTooltip>
                </td>
                <td>
                  {pageTags[landingPage.page]?.map((tag, index) => {
                    return (
                      <CustomTooltip title="Page tag">
                        <span
                          key={`${tag}-${index}`}
                          className="tag-primary text-white"
                        >
                          {tag}
                        </span>
                      </CustomTooltip>
                    );
                  })}
                </td>
                <td>
                  {landingPage.element_tags
                    ? landingPage.element_tags.map((tag, index) => {
                      return landingPage.other_clicks[0]?.trigger_time ? (
                        <CustomTooltip
                          key={`${tag}-${index}`}
                          title={`Triggered @ ${landingPage.other_clicks[0]?.trigger_time}`}
                        >
                          <span className="tag-primary text-white">
                            {tag}
                          </span>
                        </CustomTooltip>
                      ) : (
                        <span className="tag-primary text-white">{tag}</span>
                      );
                    })
                    : undefined}
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {/* {landingPage.elements_imp_clicks.map((imp_clk, index) => {
                      return (
                        <CustomTooltip title={imp_clk.kdf_to_kdf}>
                          <span
                            key={index}
                            className="icon-position cursor-pointer icon-touch"
                            target="_blank"
                          ></span>
                        </CustomTooltip>
                      );
                    })} */}
                                        {landingPage.cookie_status == "essential" && (
                      <span
                        style={{
                          // marginTop: "5px",
                        }}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              d="M9 16H9.01M12 11H12.01M7 10H7.01M15 16H15.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C12 5.76142 13.7909 8 16 8C16 10.2091 18.2386 12 21 12Z"
                              stroke="#FF0000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>{" "}
                          </g>
                        </svg>
                      </span>
                    )}
                    {landingPage.cookie_status == "acceptAll" && (
                      <span
                        style={{
                          // marginTop: "4px",
                        }}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              d="M9 16H9.01M12 11H12.01M7 10H7.01M15 16H15.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C12 5.76142 13.7909 8 16 8C16 10.2091 18.2386 12 21 12Z"
                              stroke="#294661"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>{" "}
                          </g>
                        </svg>
                      </span>
                    )}

                    {landingPage.page === landingPage.landing_page ? (
                      <CustomTooltip
                        title={`Recipient began viewing on ${landingPage.date}`}
                      >
                        <span className="icon-position icon-enter cursor-pointer"></span>
                      </CustomTooltip>
                    ) : undefined}
                    {landingPage.cyoa_enabled && (
                      <CustomTooltip title="CYOA enabled">
                        <span className="icon-position icon-chopper cursor-pointer"></span>
                      </CustomTooltip>
                    )}

                    {session.is_optin_session &&
                      sessions[0]?.has_optin &&
                      landingPage.page == sessions[0]?.optin_page &&
                      landingPage.once_optin && (
                        <CustomTooltip title={sessions[0]?.custom_text}>
                          <span className="icon-position cursor-help icon-clipboard"></span>
                        </CustomTooltip>
                      )}
                    {landingPage.page === landingPage.landing_page &&
                      filteredPages[0]?.exit_pop &&
                      filteredPages[0]?.exit_pop?.page === landingPage.page && (
                        <CustomTooltip
                          title={`ExitPop ${filteredPages[0]?.exit_pop_name} was triggered at ${filteredPages[0]?.exit_pop_date}`}
                        >
                          <span className="icon-position icon-lamp4 cursor-pointer"></span>
                        </CustomTooltip>
                      )}
                    {landingPage.page === landingPage.landing_page &&
                      filteredPages[0]?.flash_pop?.page &&
                      landingPage.page == filteredPages[0]?.flash_pop.page && (
                        <CustomTooltip
                          title={`TimedPop ${filteredPages[0]?.flash_pop_name} was triggered at ${filteredPages[0]?.flash_pop_date}`}
                        >
                          <span className="icon-position icon-lamp5 cursor-pointer"></span>
                        </CustomTooltip>
                      )}
                    {landingPage.page === landingPage.landing_page &&
                      filteredPages[0]?.enter_pop && (
                        <CustomTooltip
                          title={`EnterPop ${filteredPages[0]?.enter_pop_name} was triggered at ${filteredPages[0]?.enter_pop_date}`}
                        >
                          <span className="icon-position icon-lamp6 cursor-pointer"></span>
                        </CustomTooltip>
                      )}
                    {landingPage.other_clicks?.map((click) => {
                      return (
                        <>
                          <CustomTooltip title={`${click.clicks.message}`}>
                            <span
                              style={{
                                padding: "5px",
                              }}
                              className={`icon-position cursor-pointer ${click.clicks.icon}`}
                            ></span>
                          </CustomTooltip>
                          {click.is_reactive && (
                            <CustomTooltip title="Scrolling Scrolling">
                              <span
                                style={{
                                  padding: "5px",
                                }}
                                className="icon-position cursor-pointer icon-mouse"
                              ></span>
                            </CustomTooltip>
                          )}
                        </>
                      );
                    })}
                    {sessions[0]?.has_optin &&
                      landingPage.page === sessions[0]?.optin_page &&
                      landingPage.once_optin && (
                        <CustomTooltip
                          title={`${session.optin_mail_confirm &&
                            !session.is_optin_session
                            ? "email confirmed:"
                            : "OPT-IN:"
                            } ${receiver?.name} ${receiver?.email} ${session.optin_mail_confirm
                              ? session.optin_mail_confirm_date || ""
                              : sessions[0]?.optin_date || ""
                            }`}
                        >
                          <span
                            className={`icon-position tooltip-inner-custom ${session.optin_mail_confirm &&
                              !session.is_optin_session
                              ? "icon-user-blue"
                              : "icon-user"
                              } cursor-pointer`}
                          ></span>
                        </CustomTooltip>
                      )}
                    {landingPage.optin_reject && (
                      <CustomTooltip title="exited opt-in request">
                        <span className="icon-position icon-user2 cursor-pointer"></span>
                      </CustomTooltip>
                    )}

                    {landingPage.elements_imp_clicks.map((click) => {
                      return (
                        <>
                          {click.action_type === "hide" && (
                            <CustomTooltip
                              title={`Click action is ${click.action_type}`}
                            >
                              <span
                                style={{
                                  // marginTop: "4px",
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                                </svg>
                              </span>
                            </CustomTooltip>
                          )}
                          {click.action_type === "reveal" && (
                            <CustomTooltip
                              title={`Click action is ${click.action_type}`}
                            >
                              <span
                                style={{
                                  // marginTop: "4px",
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                                  <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" />
                                </svg>
                              </span>
                            </CustomTooltip>
                          )}
                          {click.action_type === "toggleOpacity" && (
                            <CustomTooltip
                              title={`Click action is ${click.action_type}`}
                            >
                              <span
                                style={{
                                  // marginTop: "4px",
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                                  <line
                                    x1="2"
                                    y1="2"
                                    x2="14"
                                    y2="14"
                                    stroke="currentColor"
                                    stroke-width="2"
                                  />
                                </svg>
                              </span>
                            </CustomTooltip>
                          )}
                        </>
                      );
                    })}

                    {session.used_password && sessionIndex === 0 && (
                      <CustomTooltip title="Invitation code used">
                        <span className="icon-position icon-qrcode"></span>
                      </CustomTooltip>
                    )}

                    {(landingPage.exit_page ||
                      filteredPages.length < 1) && (
                        <CustomTooltip
                          title={`recipient stopped viewing on this page at ${landingPage.exit_page_time}`}
                        >
                          <span className="icon-position icon-exit cursor-pointer"></span>
                        </CustomTooltip>
                      )}
                  </div>
                </td>
              </tr>
              <tr
                onClick={() =>
                  setSelectedPage({
                    id: landingPage.page,
                  })
                }
              >
                <td colSpan={7} className={styles.progressTd}>
                  <progress
                    value={viewsPercentage(
                      landingPage.seconds,
                      session.sec_viewed
                    )}
                    max="100"
                  ></progress>
                </td>
              </tr>
            </>
          )}
        {filteredPages.map((sessPage) => {
          return (
            (sessionIndex > 0 ||
              sessPage.page !== (landingPage.page ||
                sessPage.exit_page)) && (
              <>
                <tr
                  onClick={() =>
                    setSelectedPage({
                      id: sessPage.page,
                    })
                  }
                >
                  <td>
                    <img
                      src={`${thumbs_details?.small}${sessPage.page - 1}.${thumbs_details?.extension
                        }`}
                      onError={(e) => handleErrorImage(e)}
                      width="50px"
                      height="75px"
                      alt={sessPage.page}
                      className={
                        selectedPage?.id == sessPage?.page
                          ? styles.selectedImage
                          : ""
                      }
                    />
                  </td>
                  <td>{sessPage.page}</td>
                  <td>
                    <CustomTooltip
                      title={
                        <p className="p-1 text-light">
                          {sessPage.time_tooltip}
                        </p>
                      }
                    >
                      <span>{sessPage.min_viewed}</span>
                    </CustomTooltip>
                  </td>
                  <td>
                    {pageTags[sessPage.page]?.map((tag, index) => {
                      return (
                        <CustomTooltip title="Page tag">
                          <span
                            key={`${tag}-${index}`}
                            className="tag-primary text-white"
                          >
                            {tag}
                          </span>
                        </CustomTooltip>
                      );
                    })}
                  </td>
                  <td>
                    {sessPage.element_tags
                      ? sessPage.element_tags.map((tag, index) => {
                        return sessPage.other_clicks[0]?.trigger_time ? (
                          <CustomTooltip
                            title={`Triggered @ ${sessPage.other_clicks[0]?.trigger_time}`}
                          >
                            <span
                              key={`${tag}-${index}`}
                              className="tag-primary text-white"
                            >
                              {tag}
                            </span>
                          </CustomTooltip>
                        ) : (
                          <span
                            key={`${tag}-${index}`}
                            className="tag-primary text-white"
                          >
                            {tag}
                          </span>
                        );
                      })
                      : undefined}
                  </td>
                  <td>
                    {sessPage.elements_imp_clicks.map((imp_clk, index) => {
                      return (
                        <CustomTooltip title={imp_clk.kdf_to_kdf}>
                          <span
                            key={index}
                            className="icon-position cursor-pointer icon-touch"
                            target="_blank"
                          ></span>
                        </CustomTooltip>
                      );
                    })}
                    {filteredPages[0]?.exit_pop &&
                      filteredPages[0]?.exit_pop?.page === sessPage.page && (
                        <CustomTooltip
                          title={`ExitPop ${filteredPages[0]?.exit_pop_name} was triggered at ${filteredPages[0]?.exit_pop_date}`}
                        >
                          <span className="icon-position icon-lamp4 cursor-pointer"></span>
                        </CustomTooltip>
                      )}
                    {filteredPages[0]?.flash_pop?.page &&
                      sessPage.page == filteredPages[0]?.flash_pop.page && (
                        <CustomTooltip
                          title={`TimedPop ${filteredPages[0]?.flash_pop_name} was triggered at ${filteredPages[0]?.flash_pop_date}`}
                        >
                          <span className="icon-position icon-lamp5 cursor-pointer"></span>
                        </CustomTooltip>
                      )}
                    {sessPage.page === sessPage.download_pag && (
                      <CustomTooltip title="KDF downloaded">
                        <span className="cursor-pointer icon-download"></span>
                      </CustomTooltip>
                    )}
                    {session.is_optin_session &&
                      sessions[0]?.has_optin &&
                      sessPage.page === sessions[0]?.optin_page &&
                      sessPage.once_optin && (
                        <CustomTooltip title={sessions[0]?.custom_text}>
                          <span className="icon-position cursor-help icon-clipboard"></span>
                        </CustomTooltip>
                      )}
                    {sessPage.other_clicks?.map((oc) => {
                      return (
                        <>
                          <CustomTooltip
                            title={
                              <p
                                className="p-1"
                                dangerouslySetInnerHTML={{
                                  __html: oc.clicks.message,
                                }}
                              ></p>
                            }
                          >
                            <span
                              style={{
                                padding: "5px",
                              }}
                              className={`icon-position cursor-pointer ${oc.clicks.icon}`}
                            ></span>
                          </CustomTooltip>
                          {oc.is_reactive ? (
                            <CustomTooltip title="Scrolling enabled">
                              <span
                                style={{
                                  padding: "5px",
                                }}
                                className={`icon-position cursor-pointer ${oc.clicks.icon}`}
                              ></span>
                            </CustomTooltip>
                          ) : undefined}
                        </>
                      );
                    })}
                    {sessions[0]?.has_optin &&
                      sessPage.page === sessions[0]?.optin_page &&
                      sessPage.once_optin && (
                        <CustomTooltip
                          title={`${session.optin_mail_confirm &&
                            !session.is_optin_session
                            ? "email confirmed:"
                            : "OPT-IN:"
                            } ${receiver?.name} ${receiver?.email} ${session.optin_mail_confirm
                              ? session.optin_mail_confirm_date || ""
                              : sessions[0]?.optin_date || ""
                            }`}
                        >
                          <span
                            className={`icon-position tooltip-inner-custom ${session.optin_mail_confirm &&
                              !session.is_optin_session
                              ? "icon-user-blue"
                              : "icon-user"
                              } cursor-pointer`}
                          ></span>
                        </CustomTooltip>
                      )}
                    {landingPage.optin_reject && (
                      <CustomTooltip title="exited opt-in request">
                        <span className="icon-position icon-user2 cursor-pointer"></span>
                      </CustomTooltip>
                    )}
                    {sessPage.session_pause_time &&
                      sessPage.show_pause_time && (
                        <CustomTooltip
                          title={sessPage.session_pause_time_tooltip}
                        >
                          <span className="icon-position icon-hour-glass2 cursor-pointer"></span>
                        </CustomTooltip>
                      )}
                    {sessPage.language_editions.map((language) => {
                      return (
                        <CustomTooltip
                          title={`Changed Language to { pe['page_edition'].name }`}
                        >
                          <span className="{{pe['page_edition'].class}} cursor-pointer icon-position"></span>
                        </CustomTooltip>
                      );
                    })}
                    {session.used_password && sessionIndex === 0 && (
                      <CustomTooltip title="Invitation code used">
                        <span className="icon-position icon-qrcode"></span>
                      </CustomTooltip>
                    )}
                    {sessPage.exit_page && (
                      <CustomTooltip
                        title={`recipient stopped viewing on this page at ${sessPage.exit_page_time}`}
                      >
                        <span className="icon-position icon-exit cursor-pointer"></span>
                      </CustomTooltip>
                    )}
                  </td>
                </tr>
                <tr
                  onClick={() =>
                    setSelectedPage({
                      id: sessPage.page,
                    })
                  }
                >
                  <td colSpan={7} className={styles.progressTd}>
                    <progress
                      value={viewsPercentage(
                        sessPage.seconds,
                        session.sec_viewed
                      )}
                      max="100"
                    ></progress>
                  </td>
                </tr>
              </>
            )
          );
        })}
      </tbody>
    </table>
  );
};
export default SessionTable;
