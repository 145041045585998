import React from "react"
import styles from '../Viewer/Viewer.module.css'

const SessionActiveTab = ({ data, sessionIndex, setSessionIndex }) => {
    return (
        <div className="d-flex gap-2">
            {data?.sessions?.map((session, index) => (
                <span
                    key={index}
                    title="Most recent visits"
                    className={`${styles.statsHeader} ${styles.statsTagSingle
                        } ${sessionIndex == index ? styles.active : ""}`}
                    onClick={() => {
                        setSessionIndex(index);
                    }}
                >
                    {`${session?.heading_date}`}
                </span>
            ))}

        </div>
    )
}
export default SessionActiveTab