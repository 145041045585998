import React, { useCallback, useEffect, useState } from "react";

export const usePageVisibility = () => {
    const [focused, setIsFocused] = useState(document.hasFocus());

    useEffect(() => {
        const handleBlur = () => setIsFocused(false);
        const handleFocus = () => setIsFocused(true);

        window.addEventListener("blur", handleBlur);
        window.addEventListener("focus", handleFocus);

        return () => {
            window.removeEventListener("blur", handleBlur);
            window.removeEventListener("focus", handleFocus);
        };
    }, []);

    const isFocused = useCallback(() => {
        return focused;
    }, [focused]);

    return isFocused;
};